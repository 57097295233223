<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character2">
							<div class="text_guide pt-20">
								<h4> {{ $language.common.system_notice_title }} </h4>
							</div>
							<div class="text_guide_desc pt-20 color-white size-px-14 text-center">
								<div>{{ $language.common.system_notice_txt01 }}</div>
								<div>{{ $language.common.system_notice_txt02 }}</div>
							</div>
						</div>

					</div>
					<div
						v-if="false"
						class="intro_btn_wrap flex-row justify-space-between"
					>
						<div
							class="btn_area flex-1"
						>
							<button
								class="btn_l btn_fill_blue"
							>{{  $language.common.ok }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'system02'
	, props: ['user']
	, data: function(){
		return {

		}
	}
}
</script>

<style>
	.bg-black { background-color: black}
</style>