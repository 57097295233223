

export const codes = {
	metaInfo: {
		title: 'MAFIA'
		,copyright: 'MAFIA'
	}
	, permission_underboss: [
		'undrbos_mangement'
		, 'member_mangement'
		, 'notice_mttrs_reg'
		, 'board_creation'
		, 'manage_grade'
	]
	, permission_manager: [
		'cartl_admin_mangement'
		, 'cartl_member_mangement'
		, 'cartl_notice_mttrs_reg'
		, 'cartl_board_creation'
		, 'cartl_member_grade_mangement'
	]
	, tokens: {
		eth: {
			blockchain_div_code: 'BC00100002'
			, coin_token_code: 'BC00200001'
			, coin_token_name: 'ETH'
		}
		, klay: {
			blockchain_div_code: 'BC00100001'
			, coin_token_code: 'BC00200002'
			, coin_token_name: 'KLAY'
		}
		, maf_klay: {
			blockchain_div_code: 'BC00100001'
			, coin_token_code: 'BC00200003'
			, coin_token_name: 'MAF'
		}
		, maf_eth: {
			blockchain_div_code: 'BC00100002'
			, coin_token_code: 'BC00200004'
			, coin_token_name: 'MAF'
		}
		, reap: {
			blockchain_div_code: 'BC00100002'
			, coin_token_code: 'BC00200005'
			, coin_token_name: 'REAP'
		}
		, polygon: {
			blockchain_div_code: 'BC00100002'
			, coin_token_code: 'BC00200006'
			, coin_token_name: 'MATIC'
		}
	}

	, event_list: {
		live: [
			{ idx: 1, code: '', name: '카르텔 대항전', img: require('@/assets/image/event/mevent1.png'), sDate: '2023-08-31', eDate: '2023-08-31', is_use: true, not_link: true}
			, { idx: 2, code: 'EV379327830550621103', name: '오너스 이벤트', img: require('@/assets/image/event/mevent_onus.png'), sDate: '2023-09-01', eDate: '2023-09-15', is_use: true, event_url: 'https://mafiproject.com/event/onus.html'}
			, { idx: 3, code: '', name: '오너스 이벤트 결과', img: require('@/assets/image/event/mevent_onus_result.png'), sDate: '2023-09-22', eDate: '2023-09-28', is_use: true, event_url: '', not_link: true}
			, { idx: 4, code: 'EV698108617420405542', name: '오너스 2차 이벤트', img: require('@/assets/image/event/mevent_onus2.png'), sDate: '2023-10-27', eDate: '2023-11-10', is_use: true, event_url: 'https://mafiproject.com/event02/onus02.html'}
			, { idx: 5, code: '', name: '오너스 이벤트 결과', img: require('@/assets/image/event/mevent_onus_result2.jpg'), sDate: '2023-11-17', eDate: '2023-11-24', is_use: true, event_url: '', not_link: true}
		]
		, dev: [
			{ idx: 1, code: '', name: '카르텔 대항전', img: require('@/assets/image/event/mevent1.png'), sDate: '2023-08-31', eDate: '2023-08-31', is_use: true, not_link: true}
			, { idx: 2, code: 'EV379327830550621103', name: '오너스 이벤트 dev', img: require('@/assets/image/event/mevent_onus.png'), sDate: '2023-09-01', eDate: '2023-09-15', is_use: true, event_url: 'https://mafiproject.com/event/onus.html'}
			, { idx: 3, code: '', name: '오너스 이벤트 결과', img: require('@/assets/image/event/mevent_onus_result.png'), sDate: '2023-09-20', eDate: '2023-09-28', is_use: true, event_url: '', not_link: true}
			, { idx: 4, code: 'EV915836850539066275', name: '오너스 2차 이벤트', img: require('@/assets/image/event/mevent_onus2.png'), sDate: '2023-10-24', eDate: '2023-10-31', is_use: true, event_url: 'https://mafiproject.com/event02/onus02.html'}
			, { idx: 5, code: '', name: '오너스 이벤트 결과', img: require('@/assets/image/event/mevent_onus_result2.jpg'), sDate: '2023-11-17', eDate: '2023-11-24', is_use: true, event_url: '', not_link: true}
		]
		, stg: [
			{ idx: 1, code: '', name: '카르텔 대항전', img: require('@/assets/image/event/mevent1.png'), sDate: '2023-08-31', eDate: '2023-08-31', is_use: true, not_link: true}
			, { idx: 2, code: 'EV379327830550621103', name: '오너스 이벤트 stg', img: require('@/assets/image/event/mevent_onus.png'), sDate: '2023-09-01', eDate: '2023-09-15', is_use: true, event_url: 'https://mafiproject.com/event/onus.html'}
			, { idx: 3, code: '', name: '오너스 이벤트 결과', img: require('@/assets/image/event/mevent_onus_result.png'), sDate: '2023-09-22', eDate: '2023-09-28', is_use: true, event_url: '', not_link: true}
			, { idx: 4, code: 'EV698108617420405542', name: '오너스 2차 이벤트', img: require('@/assets/image/event/mevent_onus2.png'), sDate: '2023-10-26', eDate: '2023-11-10', is_use: true, event_url: 'https://mafiproject.com/event02/onus02.html'}
			, { idx: 5, code: '', name: '오너스 이벤트 결과', img: require('@/assets/image/event/mevent_onus_result2.jpg'), sDate: '2023-11-17', eDate: '2023-11-24', is_use: true, event_url: '', not_link: true}
		]
	}
	, transform_state_code:[
		{code: 'CA04100001', name: '요청'}
		, {code: 'CA04100002', name: '요청실패'}
		, {code: 'CA04100003', name: '변환중'}
		, {code: 'CA04100004', name: '변환실패'}
		, {code: 'CA04100005', name: '오류'}
		, {code: 'CA04100006', name: '취소'}
		, {code: 'CA04100007', name: '성공'}
	]

	, badge: [
		{ code: 'CM00400001', type: 'music'}
		, { code: 'CM00400002', type: 'art'}
		, { code: 'CM00400003', type: 'fashion'}
		, { code: 'CM00400004', type: 'influence'}
	]
}