<template>
	<div
		ref="bottom"
		class=" "
		:class="[is_shorts ? 'bottom_nav_wh' : 'bottom_nav', lb]"
	>
		<div
			@click="setLb"
			class="ma-auto radius-10" style="background-color: silver; width: 30px; height: 5px; margin: 5px auto 0"
		></div>
		<ul
			class="nav_bar"
		>
			<li :class="{ active: is_cartel}"><a @click="to('cartel')" class="ico_total"><strong>전체글</strong></a></li>
			<li
				v-if="story_info.board_number"
				:class="{ active: is_story}"
			><a @click="to('story')" class="ico_story"><strong>스토리</strong></a></li>
			<li
				v-if="voting_info.board_number"
				:class="{ active: is_voting}"
			><a @click="to('voting')" class="ico_cartel"><strong>투표</strong></a></li>
			<li
				v-if="subscribe_info.board_number"
				:class="{ active: is_subscribe}"
			><a @click="to('subscribe')" class="ico_sub"><strong>구독 </strong></a></li>
			<li
				v-if="$is_on_tv && mafia_info.board_number"
				:class="{ active: is_mafia}"
			><a @click="to('mafia')" class="ico_mafiatv"><strong>마피아TV </strong></a></li>
			<li
				v-if="shorts_info.board_number"
				:class="{ active: is_shorts}"
			><a @click="to('shorts')" class="ico_shorts"><strong>숏플 </strong></a></li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'BottomCartel'
	, props: ['program', 'user']
	, data: function(){
		return {
			lb: 'down'
			, is_top: false
			, items_voting: []
			, items: []
		}
	}
	,computed:{
		is_cartel: function(){
			let t = false

			if(this.$route.path.indexOf('cartel/mafia049') > 0){
				t = true
			}
			return t
		}
		, is_story: function() {
			let t = false
			if (this.$route.params.code == 'CA00700004') {
				t = true
			}
			return t
		}
		, is_voting: function(){
			let t = false

			if(this.$route.path.indexOf('cartel/voting') > 0){
				t = true
			}
			return t
		}
		, is_shorts: function(){
			let t = false

			if(this.$route.path.indexOf('cartel/shorts') > 0){
				t = true
			}
			return t
		}
		, is_subscribe: function(){
			let t = false
			if (this.$route.params.code == 'CA00700005') {
				t = true
			}
			return t
		}
		, is_mafia: function(){
			let t = false
			if (this.$route.params.code == 'CA00700007') {
				t = true
			}
			return t
		}
		, story_info: function(){
			let t = {}
			this.items.filter ( (item) => {
				if(item.board_type_code == 'CA00700004'){
					t = item
				}
			})
			return t
		}
		, subscribe_info: function(){
			let t = {}
			this.items.filter ( (item) => {
				if(item.board_type_code == 'CA00700005'){
					t = item
				}
			})
			return t
		}
		, shorts_info: function(){
			let t = {}
			this.items.filter ( (item) => {
				if(item.board_type_code == 'CA00700006'){
					t = item
				}
			})
			return t
		}
		, voting_info: function(){
			let t = {}
			if(this.items_voting.length > 0){
				t = this.items_voting[0]
			}
			return t
		}
		, mafia_info: function(){
			let t = {}
			this.items.filter ( (item) => {
				if(item.board_type_code == 'CA00700007'){
					t = item
				}
			})
			return t
		}
	}
	, methods: {

		to: function(type){
			if(this.$route.name == type){
				return false

			}else{
				switch (type){

					case 'cartel':
					this.$bus.$emit('to', { name: 'mafia049', params: {idx: this.$route.params.idx}})
						break;
					case 'story':
						this.$bus.$emit('to', { name: 'mafia053', params: {idx: this.$route.params.idx, code: this.story_info.board_type_code, b_id: this.story_info.board_number}, not_query: true, type: 'href'})
						break;
					case 'voting':
						this.$bus.$emit('to', { name: 'CartelVotingList', params: { idx: this.$route.params.idx, v_id: this.voting_info.board_number, type: 'ing', from: this.$route.name }})
						break;
					case 'subscribe':
						if(this.user.member_number){
							this.$bus.$emit('to', { name: 'mafia053', params: {idx: this.$route.params.idx, code: this.subscribe_info.board_type_code, b_id: this.subscribe_info.board_number}, not_query: true, type: 'href'})
						}else{
							this.$bus.$emit('onLogin')
						}
						break;
					case 'shorts':
						this.$bus.$emit('to', { name: 'CartelShorts', params: {idx: this.$route.params.idx, b_id: this.shorts_info.board_number}})
						break;
					case 'mafia':

						// this.$bus.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: this.mafia_info.board_type_code, b_id: this.mafia_info.board_number,  }, not_query: true})

						this.$bus.$emit('to', { name: 'MafiaTvList', params: {idx: this.$route.params.idx, code: this.mafia_info.board_type_code, b_id: this.mafia_info.board_number}})
						break;
				}
			}
		}
		, setLb: function () {
			if (this.lb == 'down') {
				this.lb = 'up'
			} else {
				this.lb = 'down'
			}
		}
		, toTop: function(){
			document.body.scrollTo({ top: 0, behavior: "smooth"})
		}
		, getData: async function(){
			try{
				let url = this.$api_url.api_path.get_open_cartel_menu2
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_menu2
				}
				const result = await this.$Request({
					method: 'post'
					,url: url
					,data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_type_code_list: [
							{
								board_type_code: 'CA00700004' // 스토리
								, inquiry_limit: 1
								, sort_condition: '1'
							}
							, {
								board_type_code: 'CA00700005' // 구독
								, inquiry_limit: 1
								, sort_condition: '1'
							}
							, {
								board_type_code: 'CA00700007' // 마피아 TV
								, inquiry_limit: 1
								, sort_condition: '1'
							}
							, {
								board_type_code: 'CA00700006' // 쇼츠
								, inquiry_limit: 1
								, sort_condition: '1'
							}
						]
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.board_list
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getVoting: async function(){
			try{
				let url = this.$api_url.api_path.get_open_vote_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_voting_board_list
				}

				const result = await this.$Request({
					method: 'post'
					,url: url
					,data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, vote_release_settup_fg: ''
					}
					, type: true
				})

				if(result.success){
					this.items_voting = result.data.cartl_vote_board_list
				}else{
					throw result.message
				}
			}catch(e){
				// this.$bus.$emit('notify',  { type: 'error', message: e})
				// console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.getData()
		this.getVoting()
	}
}
</script>

<style>

.down {
	bottom: 0 !important;
	transition: all 1s
}

.up {
	bottom: -65px !important;
	transition: bottom 1s
}
</style>