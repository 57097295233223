<template>
	<div></div>
</template>

<script>
	export default {
		name: 'CartelPermission'
		, props: ['user']
		, data: function(){
			return {
				item_permission: {}
			}
		}
		, methods: {
			getCartelPermission: async function(){
				let url = this.$api_url.api_path.get_board_config
				try{
					// this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
						}
						, type: true
					})
					if(result.success){
						this.item_permission = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		, watch: {
			$route(){
				if(this.$route.fullPath.indexOf('/cartel/') > -1 && this.$route.params.idx && this.$route.params.b_id){
					// this.getCartelPermission()
				}
			}
		}
	}
</script>