
export const api_url = {
    api_path: {
        // COM-01-001 앱 인증
        post_device_token: 'mafiaa/b/appcertfc'
        // COM-05-001 공통코드조회
        , get_common_code: 'mafiaa/com/commoncodeinquiry'
        // 약관 조회
        , get_agreement: 'mafiaa/b/memberstiplist/::1.2.0'
        // 공통 코드
        // 구글 OTP 키 조회
        , get_google_otp_key: 'mafiaa/mem/gogleotpkeyinquiry'
        // 구글 OTP 변경 키 조회
        , get_google_otp_change_key: 'mafiaa/mem/gogleotpchgkeyinquiry/::1.1.0'
        // 구글 OTP 등록
        , post_google_otp: 'mafiaa/mem/gogleotpcertfc'
        // MEM-21-004 구글 OTP 변경 인증
        , post_google_change_otp: 'mafiaa/mem/gogleotpchgcertfc/::1.1.0'
        // MEM-04-003 핀번호 저장 요청
        , post_pin: 'mafiaa/mem/pinnumbersave/::2.0.0'
        // MEM-04-006	휴대폰SMS 인증번호 요청
        , post_sms_request: 'mafiaa/mem/mphonecertfcnumberrequest/::1.1.0'
        // MEM-04-007	휴대폰SMS 인증번호 확인
        , post_sms_confirm: 'mafiaa/mem/mphonecertfcnumberconfirmation/::1.1.0'
        // MEM-05-002 로그인 핀번호 확인 요청
        , post_pin_login: 'mafiaa/mem/loginpinnumberconfirmation/::1.2.0'
        // MEM-22-003 핀번호 분실 저장
        , post_pin_befor_login: 'mafiaa/mem/pinnumberlostsave/::1.2.0'
        // MEM-22-004	핀번호 분실 휴대폰SMS 인증번호 요청
        , post_pin_lost: 'mafiaa/mem/pinnumberlostmphonecertfcnumberrequest/::1.1.0'
        // MEM-17-001 핀 번호 변경 핀 인증
        , post_pin_check: 'mafiaa/mem/pinnumberchgpincertfc/::1.2.0'
        // MEM-21-002 구글 OTP 변경 핀번호 확인
        , post_otp_pin_check: 'mafiaa/mem/gogleotpchgpinnumberconfirmation/::1.1.0'
        // MEM-17-002 핀번호 변경 저장
        , post_pin_update: 'mafiaa/mem/pinnumberchgsave/::1.2.0'
        // 관심분야 저장
        , post_concernsphere: 'mafiaa/mem/concernspheresave/::2.0.0'
        // 닉네임 저장
        , post_account: 'mafiaa/mem/nicknamesave/::2.0.0'
        // NFT 카드 목록 - 전체
        , API_PATH_NFT_LIST: '/'
        // NFT-05-001 NFT 메인 정보 조회
        , get_main_nft: 'mafiaa/nft/nftmaininfoinquiry'
        // 메인 NFT 업데이트
        , update_main_nft: 'mafiaa/nft/proflenftcardsave'
        // NFT-07-001 Klaytn NFT 거래 요청
        , post_nft_withdrawal: 'mafiaa/nft/nfttransactionrequest'
        // NFT-07-002	카르텔 NFT 거래 요청
        , post_cartel_nft_withdrawal: 'mafiaa/nft/cartlnfttransactionrequest'
        // NFT-10-001 카르텔 NFT 상세 정보 조회
        , get_nft_card_info: 'mafiaa/nft/cartlnftdetilinfoinquiry'
        // 내 친구 목록
        , get_friends_list: 'mafiaa/mem/friendlistinquiry'
        // 통화 목록
        , get_currency: '1'
        // WLT-02-001 회원 지갑 목록 조회
        , get_wallet_list: 'mafiaa/wlt/memberwalletlistinquiry'
        // 지갑 생성 요청
        , post_wallet: 'mafiaa/wlt/walletcreation'
        // 지갑 주소 조회
        , get_wallet_address: 'mafiaa/nft/klaytnaddressinfoinquiry'
        // 출금 수수료 조회
        , get_trans_commis: 'mafiaa/com/transactiontranmsncommisinquiry'
        // 출금 요청
        , post_withdrawal: 'mafiaa/wlt/walletwithdrawalrequest'
        // 입출금 내역
        , get_wallet_history: 'mafiaa/wlt/memberwalletdeptwidallistinquiry'
        // DRP-01-001 마피아 NFT 카드 판매 정보 조회
        , get_drops_config: 'mafiaa/drp/mafinftcardslinfoinquiry'
        // DRP-01-004 마피아 NFT 카드 법정화폐 구매 요청
        , post_drops_regal: 'mafiaa/drp/mafinftcardleglterpurchase'
        // DRP-01-005  마피아 NFT 카드 법정화폐 구매 정보 조회
        , get_drops_regal_list: 'mafiaa/drp/mafinftcardleglterpurchaseinfoinquiry'
        // DRP-02-001 마피아 NFT 카드 구매 요청
        , post_nft_drop: 'mafiaa/drp/mafinftcardpurchase'
        // DRP-03-001 유틸리티 NFT 카드 판매 정보 조회
        , get_drops_config_utility: 'mafiaa/drp/utiltynftcardslinfoinquiry'
        // DRP-04-001 유틸리티 NFT 카드 구매 요청
        , post_utility_drop: 'mafiaa/drp/utiltynftcardpurchase'
        // DRP-05-001	판매 NFT 카드 목록 조회
        , get_sales_card_list: 'mafiaa/drp/slnftcardlistinquiry'
        // DRP-05-002	판매 NFT 카드 정보 조회
        , get_sales_card_info: 'mafiaa/drp/slnftcardinfoinquiry'
        // DRP-06-001	 Klaytn 판매 NFT 카드 구매 요청
        , post_sales_card: 'mafiaa/drp/slnftcardpurchase'
        // 구매 가능한 카드 수량 요청
        , get_purchase_nft: '/'
        // 내 NFT 카드 목록
        , get_my_card: '12'
        // 내 유틸리티 카드 목록
        , get_utility_list: 'mafiaa/nft/memberutiltynftcardlistinquiry'
        // 유틸리티 카드 장착 해제
        , post_utility_flag: 'mafiaa/nft/memberutiltynftcardreg'
        // STK-03-001 스테이킹 메인 정보 조회
        , get_staking_main: 'mafiaa/stk/stakgmaininfoinquiry/::1.1.0'
        // STK-05-001 스테이킹 이력 목록 조회
        , get_staking_history_info: 'mafiaa/stk/stakghistorylistinquiry/::1.1.0'
        // 스테이킹 카드 목록
        , get_staking_nft: 'mafiaa/stk/stakgnftlistinquiry'
        // STK-06-001 스테이킹 보상 인출 정보 조회
        , get_staking_reward_info: 'mafiaa/stk/stakgrewardwthdwlinfoinquiry'
        // STK-06-002 스테이킹 보상 인출 요청
        , post_staking_reward: 'mafiaa/stk/stakgrewardwthdwlrequest'
        // STK-06-003 스테이킹 보상 인출 내역 조회
        , get_staking_reward: 'mafiaa/stk/stakgrewardwthdwlparticularsinquiry'
        // 마이닝 내역
        , get_mining_list: 'mafiaa/stk/stakghistorylistinquiry'
        // 스테이킹 요청
        , post_staking: 'mafiaa/stk/stakgrequest'
        // 스테이킹 해제
        , post_unstaking: 'mafiaa/stk/stakgrelease'
        // MEM-07-002 NFT 카드 목록 조회
        , get_nft: 'mafiaa/mem/nftcardlistinquiry/::1.1.0'
        // NFT-02-001 Klaytn 마피아 NFT 상세 정보 조회 -> 미사용 예정
        , get_nft_detail: 'mafiaa/nft/klaytnmafinftdetilinfoinquiry'
        // 유틸리티 카드 정보
        , get_utility_detail: 'mafiaa/nft/utiltynftinfoinquiry'
        // NFT-06-001 선물 요청
        , post_present: 'mafiaa/nft/giftrequest/::1.1.0'
        // NFT-06-002	카르텔 NFT 선물 요청
        , post_present_nft: 'mafiaa/nft/cartlnftgiftrequest/::1.1.0'
        // NFT-02-002 선물 목록 조회
        , get_present_history: 'mafiaa/nft/giftlistinquiry'
        // NFT-02-003 NFT 거래 목록 조회
        , get_nft_history: 'mafiaa/nft/nfttransactionlistinquiry'
        // 카카오 REST API - 인가 코드 요청
        // 자동 로그인 요청
        , post_auto_login: 'post_auto_login'
        , post_kakao_login: '/'
        // MEM-02-001 소셜 인증 요청
        , post_join_social: 'mafiaa/mem/socialcertfc/::2.0.0'
        // 카카오 REST API - 토큰 요청
        , post_kakao_auth_token: 'https://kauth.kakao.com/oauth/token'
        // 카카오 API - 사용자 정보 요청
        , get_kakao_user_me: 'https://kapi.kakao.com/v2/user/me'
        // MEM-07-003 프로필 정보 저장 요청
        , post_profile: 'mafiaa/mem/profleinfosave/::1.2.0'
        // MEM-07-004	프로필 마피아 카드 설정
        , post_profile_set: 'mafiaa/mem/proflemaficardsettup'
        // MEM-15-001 공지사항 목록 조회
        , get_notice_list: 'mafiaa/mem/noticemttrslistinquiry'
        // MEM-15-002 공지사항 정보 조회
        , get_notice_info: 'mafiaa/mem/noticemttrsinfoinquiry/::1.2.0'
        // MEM-16-001 FAQ 카테고리 목록 조회
        , get_faq_category: 'mafiaa/mem/faqcatgylistinquiry'
        // MEM-16-002 FAQ 카테고리 별 FAQ 목록 조회
        , get_faq_list: 'mafiaa/mem/faqlistinquiry'
        // MEM-16-003 FAQ 정보 조회
        , get_faq_info: 'mafiaa/mem/faqinfoinquiry'
        // CART-03-001 카르텔 메인 정보 조회
        , get_cartel_main_info: 'mafiaa/cart/cartlmaininfoinquiry/::1.2.1'
        // 카르텔 목록
        , get_cartel_list: '/'
        // CART-04-001	카르텔 정보 조회
        , get_cartel_info: 'mafiaa/cart/cartlinfoinquiry/::1.2.2'
        // CART-04-002	게시물 목록 조회
        , get_cartel_bbs_list: 'mafiaa/cart/bulletinarticleslistinquiry/::1.2.0'
        // CART-04-003	카르텔 메뉴 조회
        , get_cartel_menu: 'mafiaa/cart/cartlmenuinquiry/::1.2.1'
        // CART-04-004	카르텔 메뉴 즐겨찾기 등록/해제 요청
        , post_fav: 'mafiaa/cart/cartlmenubookmarkreg/::1.1.0'
        // CART-04-005 게시판 유형별 카르텔 메뉴 조회
        , get_cartel_menu2: 'mafiaa/cart/boardtypeclassfied bycartlmenuinquiry'
        // COM-05-004	첨부 파일 등록 (multipart/form-data)
        , post_file: 'mafiaa/com/appendixfilereg'
        // 카르텔 생성
        , post_cartel: 'mafiaa/cart/cartlregrequest'
        // CART-23-001	 카르텔 수정 요청
        , post_cartel_modify: 'mafiaa/cart/cartlchange/::1.1.0'
        // CART-23-002	 카르텔 가입 승인 확인
        , get_cartel_approval: 'mafiaa/cart/cartlentryapprovalconfirmation/::1.1.0'
        // 카르텔 활동정보 공개여부
        , post_cartel_activity: 'mafiaa/mem/activityinforeleasesettup'
        // 가입된 카르텔 목록
        , get_my_cartel: '3'
        // 추천 카르텔 목록
        , get_cartel_recomm_list: 'mafiaa/cart/recomcartllistinquiry'
        // 공지사항 알람 설정
        , post_notice_alram: 'mafiaa/mem/noticemttrsnotesettup'
        // 스테이킹 알람 설정
        , post_staking_alram: 'mafiaa/mem/stakgparticularsnotesettup'
        // CART-19-001	공지사항 목록 조회
        , get_cartel_notice_list: 'mafiaa/cart/noticemttrslistinquiry/::1.2.0'
        // CART-19-002	 공지사항 정보 조회
        , get_cartel_notice_info: 'mafiaa/cart/noticemttrsinfoinquiry/::1.2.0'
        // CART-20-001	한줄응원 목록 조회
        , get_cartel_cheer_list: 'mafiaa/cart/onelincheerlistinquiry/::1.1.0'
        // CART-20-002	한줄응원 정보 조회
        , get_cartel_cheer_info: 'mafiaa/cart/onelincheerinfoinquiry/::1.1.0'
        // CART-17-008 게시판 게시물 목록 조회
        , get_board_bbs_list: 'mafiaa/cart/boardpostlistinquiry/::1.2.0'
        // CART-07-001	카르텔 탈퇴 요청
        , post_cartel_out: 'mafiaa/cart/cartlwithddt/::1.1.0'
        // CART-08-001	카르텔 폐쇄 요청
        , post_cartel_closure: 'mafiaa/cart/cartlclose/::1.1.0'
        // CART-01-001	카르텔 메인 통합 검색
        , get_cartel_main_search: 'mafiaa/cart/cartlmainunifysearch/::1.2.0'
        // CART-01-003	전체 카르텔 목록 조회
        , get_cartel_total_list: 'mafiaa/cart/allcartllistinquiry/::1.2.0'
        // CART-02-001	카르텔 통합 검색
        , get_cartel_total_search: 'mafiaa/cart/cartlunifysearch/::1.2.0'
        // CART-02-002	카르텔 회원 검색
        , get_member_list: 'mafiaa/cart/cartlmembersearch/::1.1.0'
        // CART-02-002	카르텔 회원 검색
        , get_cartel_member_search_list: 'mafiaa/cart/cartlmembersearch/::1.1.0'
        // CART-02-003	카르텔 게시물 검색
        , get_cartel_bbs_search: 'mafiaa/cart/cartlpostsearch/::1.2.0'
        // CART-11-001	 승인 대기 회원 목록 조회
        , get_cartel_member_wait_list: 'mafiaa/cart/approvalwaitingmemberinquiry/::1.1.0'
        // CART-11-002	 카르텔 회원 목록 조회
        , get_cartel_member_list: 'mafiaa/cart/cartlmemberlistinquiry/::1.1.0'
        // CART-11-003	 카르텔 회원 승인 요청
        , post_cartel_member_confirm: 'mafiaa/cart/cartlmemberapproval/::1.1.0'
        // CART-11-004	 카르텔 회원 승인 삭제 요청
        , post_cartel_member_cancel: 'mafiaa/cart/cartlmemberapprovaldelete/::1.1.0'
        // CART-11-005	 카르텔 회원 정지 요청
        , post_cartel_member_stop: 'mafiaa/cart/cartlmemberstop/::1.1.0'
        // CART-11-006	 카르텔 회원 강제탈퇴 요청
        , post_cartel_member_drop: 'mafiaa/cart/cartlmembercompulsorywithddt/::1.1.0'
        // CART-12-001	 카르텔 정지 회원 목록 조회
        , get_cartel_member_stop_list: 'mafiaa/cart/cartlstopmemberinquiry/::1.1.0'
        // CART-12-002	 카르텔 정지 회원 해제 요청
        , post_cartel_member_stop_cancel: 'mafiaa/cart/stoprelease/::1.1.0'
        // CART-03-003 카르텔 가입 요청
        , post_cartel_join: 'mafiaa/cart/cartlentryrequest/::1.1.0'
        // 카르텔 맴버 상태 변경
        , post_cartel_member_status: '4'
        // CART-10-001	게시판 목록 조회
        , get_board_config_list: 'mafiaa/cart/boardlistinquiry/::1.2.0'
        // CART-10-002	게시판 등록 요청
        , post_board_config: 'mafiaa/cart/boardreg/::1.1.0'
        // CART-10-003	게시판 수정 요청
        , post_board_config_modify: 'mafiaa/cart/boardchange/::1.2.0'
        // CART-10-004	게시판 삭제 요청
        , post_board_config_delete: 'mafiaa/cart/boarddelete/::1.1.0'
        // CART-10-005	게시판 권한 정책 조회
        , get_board_config: 'mafiaa/cart/boardinfoinquiry/::1.1.0'
        // CART-10-006	게시판 목록 순번 수정 요청
        , post_board_sort: 'mafiaa/cart/boardlistindexnumberchangerequest/::1.1.0'
        // CART-10-007  게시판 활성 상태 설정
        , post_board_setting: 'mafiaa/cart/boardactvtnstatesettup'
        //
        , get_board_permission: 'mafiaa/cart/boardauthoritypolicyinquiry/::1.1.0'
        // 카르텔 초대 목록
        , get_cartel_invite: 'mafiaa/cart/cartlinvitationmemberlistinquiry/::1.1.0'
        // CART-05-002	 카르텔 초대 요청
        , post_invite: 'mafiaa/cart/cartlinvitationrequest/::1.1.0'
        //친구 알람
        , post_friend_alarm: 'mafiaa/com/friendnwpostnotesettup'
        // CART-09-001	카르텔 언더보스 대상자 조회
        , get_cartel_under_boss: 'mafiaa/cart/cartlundrbostargeterinquiry'
        // CART-09-002	카르텔 언더보스 등록 요청
        , post_cartel_appoint: 'mafiaa/cart/cartlundrbosreg'
        // CART-09-003	카르텔 언더보스 해임 요청
        , post_cartel_fire: 'mafiaa/cart/cartlundrbosdismsal'
        // CART-09-004	카르텔 언더보스 권한 정책 조회
        , get_cartel_underboss_permission: 'mafiaa/cart/cartlundrbosauthoritypolicyinquiry'
        // CART-09-005	카르텔 언더보스 권한 저장
        , post_cartel_permission: 'mafiaa/cart/cartlundrbosauthoritysave'

        // CART-09-009	카르텔 운영자 대상자 조회
        , get_cartel_manager: 'mafiaa/cart/cartloperatingtargeterinquiry'
        // CART-09-010	카르텔 운영자 등록 요청
        , post_cartel_manager_appoint: 'mafiaa/cart/cartladminreg'
        // CART-09-011	카르텔 운영자 해임 요청
        , post_cartel_manager_fire: 'mafiaa/cart/cartladmindismsal'
        // CART-09-012	카르텔 운영자 권한 정책 조회
        , get_cartel_manager_permission: 'mafiaa/cart/cartladminauthoritypolicyinquiry'
        // CART-09-013	카르텔 운영자 권한 저장
        , post_cartel_manager_permission: 'mafiaa/cart/cartladminauthoritysave'

        // CART-19-003	 공지사항 등록 요청
        , post_notice: 'mafiaa/cart/noticemttrsreg/::1.3.0'
        // CART-19-004	 공지사항 수정 요청
        , post_notice_modify: 'mafiaa/cart/noticemttrschange/::1.3.0'
        // CART-20-003	한줄응원 등록 요청
        , post_cheer: 'mafiaa/cart/onelincheerreg/::1.1.0'
        // CART-20-004	한줄응원 수정 요청
        , post_cheer_modify: 'mafiaa/cart/onelincheerchange/::1.1.0'

        // CART-17-002	게시물 정보 조회
        , get_bbs: 'mafiaa/cart/postinfoinquiry/::1.2.0'

        // CART-20-005	한줄응원 삭제 요청
        , post_delete_cheer: 'mafiaa/cart/onelincheerdelete/::1.1.0'
        // CART-19-005	 공지사항 삭제 요청
        , post_delete_notice: 'mafiaa/cart/noticemttrsdelete/::1.1.0'
        // CART-17-009 게시물 신고 요청
        , post_report_bbs: 'mafiaa/cart/postdeclarationrequest/::1.1.0'

        // MEM-06-001 마이페이지 메인 정보 조회
        , get_mypage_main_info: 'mafiaa/mem/mypagmaininfoinquiry'
        // MEM-07-001 회원 정보 조회
        , get_my_info: 'mafiaa/mem/mypagmemberinfoinquiry'
        // MEM-11-001  공지글 알림 카르텔 목록 조회
        , get_my_cartel_notice_alram: 'mafiaa/mem/noticenotecartllistinquiry/::1.1.0'
        // MEM-12-002	댓글 알림 카르텔 목록 조회
        , get_my_cartel_comment_alram: 'mafiaa/mem/commentnotecartllistinquiry/::1.1.0'
        // MEM-24-001 결제수단 목록 조회
        , get_credit_list: 'mafiaa/mem/paymentmthdlistinquiry'
        // MEM-24-002 신용카드 등록
        , post_credit: 'mafiaa/mem/creditcardreg'
        // MEM-24-003 신용카드 삭제
        , post_credit_delete: 'mafiaa/mem/creditcarddelete'
        // MEM-24-004 대표 결제 수단 설정
        , post_credit_setup: 'mafiaa/mem/representationpaymentmthdsettup'
        // 핀 설정
        , update_pin: '5'
        // COM-02-003 친구 추가
        , post_friend_add: 'mafiaa/com/friendadd'
        // COM-02-004 친구 삭제
        , post_friend_cancel: 'mafiaa/com/frienddelete'
        // 카르텔 통계
        , get_cartel_stat: '6'
        // 카르텔 통계 - 우수멤버
        , get_member_rank: '7'
        // CART-16-001	공감 등록/수정 요청
        , post_emoji: 'mafiaa/cart/postsympatyregchange/::1.1.0'
        // CART-16-002	공감 목록 조회
        , get_emoji_list: 'mafiaa/cart/postsympatyinquiry/::1.1.0'
        // CART-22-001	게시물 투표 요청
        , post_vote: 'mafiaa/cart/postvote'
        // 투표내역
        , get_vote_list: '8'
        // 맴버 정보
        , get_member_info: 'mafiaa/com/memberinfo/::1.1.0'
        // 멤버 차단 요청
        , post_black_add: 'mafiaa/com/memberblok'
        // 멤버 차단 해제 요청
        , post_black_cancel: 'mafiaa/mem/blokmemberreleasesettup'
        // 멤버 차단 목록
        , get_black_list: 'mafiaa/mem/blokmemberlistinquiry'
        // CART-15-001	댓글 목록 조회
        , get_bbs_comment_list: 'mafiaa/cart/postcommentlistinquiry/::1.2.0'
        // CART-15-002	댓글 등록 요청
        , post_comment: 'mafiaa/cart/postcommentreg/::1.1.0'
        // CART-15-003	댓글 수정 요청
        , post_comment_modify: 'mafiaa/cart/postcommentchange/::1.1.0'
        // CART-15-004	댓글 삭제 요청
        , delete_comment: 'mafiaa/cart/postcommentdelete/::1.1.0'
        // CART-15-005	댓글 신고 요청
        , post_report_comment: 'mafiaa/cart/postcommentdeclaration/::1.1.0'
        // CART-15-006	댓글 정보 조회
        , get_bbs_comment: 'mafiaa/cart/postcommentinfoinquiry/::1.2.0'
        // 회원가입
        , post_join: '9'
        // 회원가입 - 구글
        , post_join_with_google: '10'
        // MEM-05-001 로그인 소셜 인증 요청
        , post_login_social: 'mafiaa/mem/loginsocialcertfc/::1.1.0'
        // MEM-21-001 구글 OTP 변경 소셜 인증 확인
        , post_confirm_social: 'mafiaa/mem/gogleotpchgsocialcertfcconfirmation/::1.2.0'
        // 로그아웃
        , post_logout: 'mafiaa/mem/memberlogout'
        // 회원가입 - 메일 확인
        , post_check_email: 'mafiaa/mem/loginsocialcertfc'
        // COM-02-002 회원 가입 카르텔 목록 조회
        , get_member_cartel_list: 'mafiaa/com/memberentrycartllist/::1.1.0'
        // MEM-11-001  공지글 알림 카르텔 목록 조회
        , post_cartel_notice_alram: 'mafiaa/mem/cartlnoticenotesettup/::1.1.0'
        // MEM-12-002	댓글 알림 카르텔 목록 조회
        , post_cartel_comment_alram: 'mafiaa/mem/commentnotesettup/::1.1.0'
        // 외부 NFT 카드 목록
        , get_nft_outside_list: '11'
        // CART-24-001	카르텔 설정 정보 조회
        , get_cartel_permission: 'mafiaa/cart/cartlsettupinfoinquiry/::1.1.0'
        // CART-25-001	카르텔 전체 회원 검색
        , get_cartel_member_search: 'mafiaa/cart/cartlallmembersearch/::1.1.0'
        // CART-25-002	카르텔 회원 등급정보 조회
        , get_cartel_member_grade: 'mafiaa/cart/cartlmembergradeinfoinquiry/::1.1.0'
        // CART-26-001 카르텔 알림 조회
        , get_cartel_alram: 'mafiaa/cart/cartlnoteinquiry/::1.1.0'
        // CART-17-001	등록용 게시판 목록 조회
        , get_board_list: 'mafiaa/cart/regboardinquiry/::1.1.0'
        // CART-17-002	게시물 정보 조회
        , get_cartel_bbs_info: 'mafiaa/cart/postinfoinquiry/::1.2.0'
        // CART-17-003	게시물 등록 요청
        , post_bbs: 'mafiaa/cart/postreg/::1.3.0'
        // CART-17-004	게시물  수정 요청
        , post_bbs_modify: 'mafiaa/cart/postchange/::1.3.0'
        // CART-17-005	게시물 삭제 요청
        , post_delete_bbs: 'mafiaa/cart/postdelete/::1.1.0'
        // CART-17-006	게시물 이동 요청
        , post_board_move: 'mafiaa/cart/postmoving/::1.1.0'
        // COM-05-006 앱버전정보조회
        , get_app_version_info: 'mafiaa/com/appversioninfoinquiry'
        // MEM-20-001	회웥 탈퇴
        , post_member_out: 'mafiaa/mem/memberwithddt/::1.1.0'
        // MEM-22-002 핀 번호 분실 등록 구글 OTP 인증
        , post_otp_for_pin: 'mafiaa/mem/pinnumberlostreggogleotpcertfc'
        // MEM-23-001	회원 알림 목록 조회
        , get_notify_list: 'mafiaa/mem/membernotelistinquiry'
        // COM-02-007	회원 가입 카르텔 게시물 목록 조회 : 개발전
        , get_comm_cartel_board_list: 'mafiaa/com/memberentrycartlpostlistinquiry/::1.2.0'
        // COM-02-008	회원 최근 알림 조회
        , get_comm_notify: 'mafiaa/com/membernrtnoteinquiry'
        // COM-05-009 로그인 메인 팝업 정보 조회
        , get_system_notice: 'mafiaa/com/loginmainpopupinfoinquiry'
        // CART-09-006	카르텔 회원 등급 목록 조회 요청
        , get_cartel_member_grade_list: 'mafiaa/cart/cartlmembergradelistinquiry'
        // CART-09-007	카르텔 회원 등급 저장 요청
        , post_cartel_member_grade: 'mafiaa/cart/cartlmembergradesave'
        // CART-09-008	카르텔 회원 등급 삭제 요청
        , post_cartel_member_grade_delete: 'mafiaa/cart/cartlmembergradedelete'

        // MEM-25-001 카르텔 개설 요청
        , post_cartel_request: 'mafiaa/mem/cartlopenrequest'
        // MEM-25-002 카르텔 개설 요청 취소
        , post_cartel_request_cancel: 'mafiaa/mem/cartlopenrequestccl'
        // MEM-25-003 카르텔 개설 요청 수정
        , post_cartel_request_modify: 'mafiaa/mem/cartlopenrequestchange'
        // MEM-25-004 카르텔 개설 요청 확인 목록 조회
        , get_cartel_request_list: 'mafiaa/mem/cartlopenrequestconfirmationlistinquiry'
        // MEM-25-005 카르텔 개설 요청 확인 상세 조회
        , get_cartel_request: 'mafiaa/mem/cartlopenrequestconfirmationdetilinquiry'

        // 401 테스트
        , get_error_request: 'mafiaa/tsttmp/testhttpstatus/::1.1.0'
        // COM-02-009 본인 인증 요청 번호 발급 요청
        , get_personal_confirm_request: 'mafiaa/com/selfcertfcrequestnumberissuerequest'
        // COM-02-010 본인 인증 정보 등록 요청
        , post_personal_confirm_request: 'mafiaa/com/selfcertfcinforegrequest'

        // CART-28-001 카르텔 채팅 방 개설 요청
        , post_cartel_live_request: 'mafiaa/cart/cartlchttgroomopenrequest'
        // CART-28-002 카르텔 채팅 방 정보 조회 요청
        , get_cartel_live_info: 'mafiaa/cart/cartlchttgroominfoinquiryrequest'
        // CART-28-003 카르텔 채팅 방 정보 수정 요청
        , post_cartel_live_modify: 'mafiaa/cart/cartlchttgroominfochangerequest'
        // CART-28-004 카르텔 채팅 방 목록 조회 요청
        , get_cartel_live_list: 'mafiaa/cart/cartlchttgroomlistinquiryrequest'
        // CART-28-005 카르텔 채팅 방 참여 요청
        , post_cartel_live_in: 'mafiaa/cart/cartlchttgroomparticipationrequest'
        // CART-28-006 스트리밍 권한 변경 요청
        , post_cartel_live_striming: 'mafiaa/cart/stremgauthoritychgrequest'
        // CART-28-007 채팅 사용자 권한 변경 요청
        , post_cartel_live_permission: 'mafiaa/cart/chttguserauthoritychgrequest'
        // CART-28-008 카르텔 채팅 방 초대 요청
        , post_cartel_live_invite: 'mafiaa/cart/cartlchttgroominvitationrequest'
        // CART-28-009 카르텔 채팅 방 종료 요청
        , post_cartel_live_expire: 'mafiaa/cart/cartlchttgroomendrequest'
        // CART-28-010 카르텔 채팅 방 퇴장 요청
        , post_cartel_liver_out: 'mafiaa/cart/cartlchttgroomexitrequest'
        // CART-28-011 스트리밍 시작 요청
        , post_cartel_live_start: 'mafiaa/cart/stremgstartrequest'
        // CART-28-012 스트리밍 종료 요청
        , post_cartel_live_finish: 'mafiaa/cart/stremgendrequest'
        // COM-05-007 이벤트 정보 조회
        , get_event: 'mafiaa/com/eventinfoinquiry'
        // COM-05-008 이벤트 투표 처리 요청
        , post_event_vote: 'mafiaa/com/eventvoteprocessrequest'
        // COM-05-010 외부 이벤트 정보 조회
        , get_out_event: 'mafiaa/com/externaleventinfoinquiry'
        // COM-05-011 외부 이벤트 투표 처리 요청
        , post_out_event_vote: 'mafiaa/com/externaleventvoteprocessrequest'
        // CART-28-013 채팅 참여 회원 강제 퇴장 요청
        , post_cartel_live_compulsory_exit: 'mafiaa/cart/chttgparticipationmembercompulsoryexitrequest'
        // CART-28-014 채팅 참여 회원 목록 조회 요청
        , get_cartel_live_participation_list: 'mafiaa/cart/chttgparticipationmemberlistinquiryrequest'
        // 채팅방 오류 : 이미 접속 중인 채탕방입니다
        , get_initptcn: 'initptcn'

        // SSC-01-001 구독 게시판 등록 요청
        , post_subscribe_request: 'mafiaa/ssc/subscrpboardreg'
        // SSC-01-002 구독 게시판 수정 요청
        , post_subscribe_modify: 'mafiaa/ssc/subscrpboardchange'
        // SSC-01-003 구독 게시판 삭제 요청
        , post_subscribe_delete: 'mafiaa/ssc/subscrpboarddelete'

        // SSC-04-001 카르텔 구독 플랜 목록 조회
        , get_subscribe_plane_list: 'mafiaa/ssc/cartsscrpplanlistinq'
        // SSC-04-002 카르텔 구독 플랜 상세 정보 조회
        , get_subscribe_plane: 'mafiaa/ssc/cartsscrpplandtlsinfoinq'
        // SSC-04-003 카르텔 구독 플랜 생성 기준 정보 조회
        , get_subscribe_plane_base: 'mafiaa/ssc/cartsscrpplancrebascinfoinq'
        // SSC-04-004 카르텔 구독 플랜 저장
        , post_subscribe_plan: 'mafiaa/ssc/cartsscrpplansave'

        // SSC-05-001 구독 플랜 구독 멤버 목록 조회
        , get_subscribe_in_member: 'mafiaa/ssc/subscrpplansubscrpmemberlistinquiry'
        // SSC-05-002 구독 플랜 구독 종료 멤버 목록 조회
        , get_subscribe_out_member: 'mafiaa/ssc/subscrpplansubscrpendmemberlistinquiry'
        // SSC-05-003 구독 플랜 결제 내역 목록 조회
        , get_subscribe_history: 'mafiaa/ssc/subscrpplanpaymentparticularslistinquiry'
        // SSC-05-004 구독 플랜 결제 상세 내역 조회
        , get_subscribe_history_detail: 'mafiaa/ssc/subscrpplanpaymentdetilparticularsinquiry'
        // SSC-05-005 카르텔 구독 통계 조회
        , get_subscribe_statistics: 'mafiaa/ssc/subscrpplansubscrpmemberlistinquiry'

        // SSC-06-001 카르텔 구독 내역 조회
        , get_cartel_subscribe_user_list: 'mafiaa/ssc/cartlsubscrpparticularsinquiry'
        // SSC-06-002 카르텔 구독 플랜 구독 내역 조회
        , get_cartel_subscribe_history: 'mafiaa/ssc/cartlsubscrpplansubscrpparticularsinquiry'
        // SSC-06-003 카르텔 구독 가능 플랜 목록 조회
        , get_cartel_subscribe_list: 'mafiaa/ssc/cartsscrpposbplanlistinq'
        // SSC-06-004 카르텔 구독 플랜 구매 정보 조회
        , get_cartel_subscribe_payment: 'mafiaa/ssc/cartsscrpplanpchinfoinq'
        // SSC-06-005 카르텔 구독 플랜 결제 수단 목록 조회
        , get_cartel_subscribe_payment_list: 'mafiaa/ssc/cartsscrpplanpmtmetdlistinq'
        // SSC-06-006 구독 플랜 코인 토큰 가격 정보 조회
        , get_cartel_subscribe_payment_coin: 'mafiaa/ssc/sscrpplancointknpriceinfoinq'
        // SSC-06-007 구독 플랜 포인트 가격 정보 조회
        , get_cartel_subscribe_payment_point: 'mafiaa/ssc/sscrpplanpintpriceinfoinq'
        // SSC-06-008 카르텔 구독 플랜 법정 화폐 구매
        , post_cartel_subscribe_payment_cash: 'mafiaa/ssc/cartsscrpplansttrmnypch'
        // SSC-06-009 카르텔 구독 플랜 코인 토큰 구매
        , post_cartel_subscribe_payment_token: 'mafiaa/ssc/cartsscrpplancointknpch'
        // SSC-06-010 카르텔 구독 플랜 포인트 구매
        , post_cartel_subscribe_payment_point: 'mafiaa/ssc/cartsscrpplanpintpch'
        // SSC-06-011 카르텔 구독 플랜 업그레이드 정보 조회
        , get_cartel_subscribe_payment_upgrade: 'mafiaa/ssc/cartsscrpplanupgrdinfoinq'
        // SSC-06-012 카르텔 구독 플랜 법정 화폐 업그레이드
        , post_cartel_subscribe_upgrade_cash: 'mafiaa/ssc/cartsscrpplansttrmnyupgrd'
        // SSC-06-013 카르텔 구독 플랜 코인 토큰 업그레이드
        , post_cartel_subscribe_upgrade_token: 'mafiaa/ssc/cartsscrpplancointknupgrd'
        // SSC-06-014 카르텔 구독 플랜 포인트 업그레이드
        , post_cartel_subscribe_upgrade_point: 'mafiaa/ssc/cartsscrpplanpintupgrd'


        // SSC-07-001 구독 통합 구독 내역 조회
        , get_mypage_subscribe_in_list: 'mafiaa/ssc/subscrpunifysubscrpparticularsinquiry'
        // SSC-07-002  구독 통합 구독 종료 내역 조회
        , get_mypage_subscribe_out_list: 'mafiaa/ssc/subscrpunifysubscrpendparticularsinquiry'

        // PINT-01-001 회원 보유 포인트 조회 요청
        , get_user_point: 'mafiaa/pint/memberholdingpointinquiryrequest'
        // PINT-01-002 포인트 통합 이용 내역 조회 요청
        , get_user_point_history: 'mafiaa/pint/pointunifyutilizationparticularsinquiryrequest'
        // PINT-01-003 포인트 결제 내역 목록 조회 요청
        , get_user_payment_list: 'mafiaa/pint/pointpaymentparticularslistinquiryrequest'
        // PINT-01-004 포인트 결제 상세 내역 조회 요청
        , get_user_payment_detail: 'mafiaa/pint/pointpaymentdetilparticularsinquiryrequest'

        // PINT-02-001 포인트 충전 정보 목록 조회 요청
        , get_user_point_list: 'mafiaa/pint/pointcharginfolistinquiryrequest'
        // PINT-02-002 포인트 법정화폐 구매 요청
        , post_user_point: 'mafiaa/pint/pointleglterpurchaserequest'

        // SPO-01-001 후원 요청
        , post_user_support: 'mafiaa/spo/spnshrequest'
        // SPO-01-002 후원 내역 목록 조회 요청
        , get_user_support_history: 'mafiaa/spo/spnshparticularslistinquiryrequest'
        // SPO-01-003 후원 통계 조회 요청
        , get_user_support_statistics: 'mafiaa/spo/spnshstatisticsinquiryrequest'

        // SSC-02-001	구독 게시물 정보 조회
        , get_subscribe_board_info: 'mafiaa/ssc/subscrppostinfoinquiry'
        // SSC-02-002	구독 게시물 등록 요청
        , post_subscribe_board: 'mafiaa/ssc/subscrppostreg/::1.1.0'
        // SSC-02-003	구독 게시물 수정 요청
        , post_subscribe_board_modify: 'mafiaa/ssc/subscrppostchange/::1.1.0'
        // SSC-02-004	구독 게시물 삭제 요청
        , post_subscribe_board_delete: 'mafiaa/ssc/subscrppostdelete'
        // SSC-02-005	구독 게시물 이동 조회
        , post_subscribe_board_move: 'mafiaa/ssc/subscrppostmoving'
        // SSC-02-006	구독 게시물 첨부파일 다운로드 - 파일 스트림 응답
        , get_subscribe_board_files: 'mafiaa/ssc/subscrppostappendixfiledownload'
        // SSC-02-007   구독 게시글 이동 구독 게시판 목록 조회
        , get_subscribe_board_move_list: 'mafiaa/cart/regboardinquiry/::1.1.0'
        // SSC-02-008 구독 게시물 복사 구독 게시판 목록 조회
        , get_subscribe_board_copy_list: 'mafiaa/ssc/subscrppostcopysubscrpboardlistinquiry'
        // MEM-05-004 로그인 핀번호 핀토큰 확인 요청
        , post_pin_token_login: 'mafiaa/mem/lgnpinnopintkncnfm'
        // SSC-02-009	구독 게시물 첨부파일 Url 다운로드 - 파일 스트림
        , get_board_file_stream: 'mafiaa/ssc/subscrppostappendixfileurldownload'
        // SSC-02-010	구독 게시물 첨부파일 Url 다운로드 - 파일 스트림 blob
        , get_board_file_stream_blob: 'mafiaa/ssc/subscrppostappendixfileurldownloadblb'
        // SSC-02-012 구독 게시물 동영상 스트리밍 헤더 요청
        , get_board_file_cookie_header: 'mafiaa/ssc/sscrppostvideostrmheadrreq'

        // PINT-02-003 포인트 IOS 인앱 구매 시작 요청
        , post_inapp_ios_start: 'mafiaa/pint/pointiosinapppurchasestartrequest'
        // PINT-02-004 포인트 IOS 인앱 구매 검증 요청
        , post_inapp_ios_confirm: 'mafiaa/pint/pointiosinapppurchaseverificationrequest/::1.1.0'
        // PINT-02-005 포인트 IOS 인앱 구매 완료 요청
        , post_inapp_ios_complete: 'mafiaa/pint/pointiosinapppurchasecomplrequest'

        // SSC-02-006 구독 게시물 첨부파일 삭제 요청
        , post_file_delete: 'mafiaa/ssc/subscrppostappendixfiledeleterequest'

        // CART-17-010 게시물 첨부파일 삭제 요청
        , post_file_delete_n: 'mafiaa/cart/postappendixfiledeleterequest'

        // NFT-12-001 스왑 가능 NFT 카드 목록 조회 요청
        , get_nft_swap_list: 'mafiaa/nft/swappossiblenftlistinquiryrequest'
        // NFT-12-002 NFT 카드 스왑 정보 조회 요청
        , get_nft_swap: 'mafiaa/nft/nftswapinfoinquiryrequest'
        // NFT-12-003 NFT 카드 스왑 요청
        , post_nft_swap: 'mafiaa/nft/nftswaprequest'

        // SALE-01-001 미 진행 NFT 판매 조회 요청
        , get_not_sale_nft_list: 'mafiaa/sale/nonprogressnftslinquiryrequest'
        // SALE-01-002 카르텔 NFT 판매 목록 조회
        , get_sale_nft_list: 'mafiaa/sale/cartnftsalelistinq'
        // SALE-01-003 카르텔 NFT 구매 상세 정보 조회
        , get_sale_nft_detail: 'mafiaa/sale/cartnftpchdtlsinfoinq'
        // SALE-01-004 카르텔 NFT 구매
        , get_sale_nft_purchase: 'mafiaa/sale/cartnftpch'
        // 카르텔 NFT 구매 - 애플 전용
        , post_sale_nft_apple: 'mafiaa/sale/cartnftiappchvrfc'

        // NFT-13-001 NFT 결제 내역 목록 조회 요청
        , get_nft_payment_history: 'mafiaa/nft/nftpmtptcllistinqreq'
        // NFT-13-002 NFT 결제 상세 내역 조회 요청
        , get_nft_payment_detail: 'mafiaa/nft/nftpmtdtlsptclinqreq'

        // VTG-01-001 카르텔 투표 게시판 목록 조회
        , get_voting_board_list: 'mafiaa/vtg/cartlvoteboardlistinquiry'
        // VTG-01-002 카르텔 투표 게시판 등록
        , post_voting_board: 'mafiaa/vtg/cartlvoteboardreg'
        // VTG-01-003 카르텔 투표 게시판 수정
        , put_voting_board: 'mafiaa/vtg/cartlvoteboardchange'
        // VTG-01-004 카르텔 투표 게시판 삭제
        , delete_voting_board: 'mafiaa/vtg/cartlvoteboarddelete'
        // VTG-01-005 카르텔 투표 게시판 목록 변경
        , put_voting_board_list: 'mafiaa/vtg/cartlvoteboardlistchg'
        // VTG-01-006 카르텔 투표 게시판 상세 조회
        , get_voting_board: 'mafiaa/vtg/cartlvoteboarddetilinquiry'

        // VTG-02-001 카르텔 투표 기본 정보 등록
        , post_voting_bbs_info: 'mafiaa/vtg/cartlvotebaseinforeg'
        // VTG-02-002 카르텔 투표 기본 정보 조회
        , get_voting_bbs_info: 'mafiaa/vtg/cartlvotebaseinfoinquiry'
        // VTG-02-003 임시 예약 카르텔 투표 기본 정보 수정 요청
        , put_voting_bbs_temp: 'mafiaa/vtg/temporaryreservecartlvotebaseinfochange'
        // VTG-02-004 진행 카르텔 투표 정보 수정
        , put_voting_bbs_info: 'mafiaa/vtg/progresscartlvotebaseinfochange'
        // VTG-02-005 카르텔 투표 선택지 등록
        , post_voting_bbs_items_save: 'mafiaa/vtg/cartlvoteoptiopreg'
        // VTG-02-006 카르텔 투표 선택지 목록 조회
        , get_voting_bbs_items_list: 'mafiaa/vtg/cartlvoteoptioplistinquiry'
        // VTG-02-007 카르텔 투표 선택지 정보 조회
        , get_voting_bbs_items: 'mafiaa/vtg/cartlvoteoptiopinfoinquiry'
        // VTG-02-008 임시 예약 카르텔 투표 선택지 수정
        , put_voting_bbs_temp_items: 'mafiaa/vtg/temporaryreservecartlvoteoptiopchange'
        // VTG-02-009 진행 카르텔 투표 선택지 수정
        , put_voting_bbs_items: 'mafiaa/vtg/progresscartlvoteoptiopchange'
        // VTG-02-0010 카르텔 투표 선택지 삭제
        , delete_voting_bbs_item: 'mafiaa/vtg/cartlvoteoptiopdelete'
        // VTG-02-0011 카르텔 투표 임시 저장 등록
        , post_voting_bbs_items_temp: 'mafiaa/vtg/cartlvotetemporarysavereg'
        // VTG-02-0012 카르텔 투표 관리 진행 목록 조회
        , get_voting_bbs_list_ing: 'mafiaa/vtg/cartlvotemangementprogresslistinquiry'
        // VTG-02-013 카르텔 투표 관리 예약 목록 조회
        , get_voting_bbs_list_reserve: 'mafiaa/vtg/cartlvotemangementreservelistinquiry'
        // VTG-02-014 카르텔 투표 관리 종료 목록 조회
        , get_voting_bbs_list_end: 'mafiaa/vtg/cartlvotemangementendlistinquiry'
        // VTG-02-015 카르텔 투표 관리 임시 목록 조회
        , get_voting_bbs_list_temp: 'mafiaa/vtg/cartlvotemangementtemporarylistinquiry'
        // VTG-02-016 카르텔 투표 종료
        , post_voting_finish: 'mafiaa/vtg/cartlvoteend'
        // VTG-02-017 카르텔 투표 삭제
        , delete_voting: 'mafiaa/vtg/cartlvotedelete'
        // VTG-02-018 카르텔 투표 노출 숨김 설정
        , post_voting_hidden: 'mafiaa/vtg/cartlvoteexposurehiddensettup'
        // VTG-02-019 카르텔 투표 선택지 순번 수정
        , post_voting_items_sort: 'mafiaa/vtg/cartlvoteoptiopindexnumberchange'

        // VTG-03-001 카르텔 투표 게시판 진행 목록 조회
        , get_voting_list_ing: 'mafiaa/vtg/cartlvoteboardprogresslistinquiry'
        // VTG-03-002 카르텔 투표 게시판 종료 목록 조회
        , get_voting_list_end: 'mafiaa/vtg/cartlvoteboardendlistinquiry'
        // VTG-03-003 카르텔 투표 정보 조회
        , get_voting_info: 'mafiaa/vtg/cartlvoteinfoinquiry'
        // VTG-03-004 카르텔 투표 선택지 상세 정보 조회
        , get_vote_item: 'mafiaa/vtg/cartlvotecartlvoteoptiopdetilinfoinquiryinfoinquiry'
        // VTG-03-005 회원 카르텔 투표 선택지 선택 등록
        , post_voting: 'mafiaa/vtg/membercartlvoteoptiopchoicereg'
        // VTG-03-006 카르텔 투표 댓글 등록
        , post_voting_comment: 'mafiaa/vtg/cartlvotecommentreg'
        // VTG-03-007 카르텔 투표 댓글 수정
        , put_voting_comment: 'mafiaa/vtg/cartlvotecommentchange'
        // VTG-03-008 카르텔 투표 댓글 삭제
        , delete_voting_comment: 'mafiaa/vtg/cartlvotecommentdelete'
        // VTG-03-009 카르텔 투표 댓글 신고
        , post_voting_comment_report: 'mafiaa/vtg/cartlvotecommentdeclaration'
        // VTG-03-010 카르텔 투표 회원 목록 정렬 메뉴 조회
        , get_voting_member_list: 'mafiaa/vtg/cartlvotememberlistsortmenuinquiry'
        // VTG-03-011 사용자 카르텔 투표 게시판 상세 조회 Copy
        , get_voting_info_user: 'mafiaa/vtg/usrcartlvoteboarddetilinquiry'
        // VTG-03-012 카르텔 투표 댓글 목록 조회
        , get_voting_comment_list: 'mafiaa/vtg/cartlvotecommentlistinquiry'
        // VTG-03-013	카르텔 투표 댓글 정보 조회
        , get_voting_comment_info: 'mafiaa/vtg/cartlvotecommentinfoinquiry'

        // MEM-27-001 신규 및 참여 투표 종료 알림
        , post_voting_alram: 'mafiaa/mem/cartlvotenotesettup'

        // COM-05-012 시스템 이미지 목록 조회
        , get_system_image: 'mafiaa/com/systemimglistinquiry'


        // SHTP-01-001 숏플 게시판 등록 요청
        , post_shorts_board: 'mafiaa/shtp/shrtpleboardreg'

        // SHTP-02-001 숏플 게시물 등록 요청
        , post_shorts: 'mafiaa/shtp/shrtplepostreg/::1.0.1'
        // SHTP-02-002 숏플 게시물 수정 요청
        , put_shorts: 'mafiaa/shtp/shrtplepostchange'
        // SHTP-02-003 숏플 게시물 삭제 요청
        , delete_shorts: 'mafiaa/shtp/shrtplepostdelete'
        // SHTP-02-004 숏플 게시물 정보 조회
        , get_shorts: 'mafiaa/shtp/shrtplepostinfoinquiry'

        // SHTP-03-001 숏플 댓글 목록 조회
        , get_shorts_comment: 'mafiaa/shtp/shrtplepostcommentlistinquiry'

        // CART-03-004 숏플 게시물 목록 조회
        , get_shorts_total_list: 'mafiaa/cart/shrtplebulletinarticleslistinquiry/::1.1.0'

        // CART-19-006 공지사항 첨부파일 삭제 요청
        , post_notice_file_delete: 'mafiaa/cart/noticemttrsappendixfiledeleterequest'


        // MEM-28-001 등록 숏플 카르텔 목록 조회
        , get_my_shorts_cartel: 'mafiaa/mem/regshrtplecartllistinquiry'
        // MEM-28-002 등록 숏플 목록 조회
        , get_my_shorts: 'mafiaa/mem/regshrtplelistinquiry'
        // MEM-28-003 회원 숏플 상세목록 조회
        , get_my_shorts_detail: 'mafiaa/mem/membershrtpledetillistinquiry'

        // SALE-01-002 카르텔 NFT 판매 목록 조회2
        , get_nft_list_temp: 'mafiaa/sale/cartnftsalelistinqtemp'


        // MTV-01-001 동영상 시리즈 정보 목록 조회
        , get_cartel_tv_series_list: 'mafiaa/mtv/videosersinfolistinq'
        // MTV-01-002 동영상 단편 에피소드 목록 조회
        , get_cartel_tv_alone_list: 'mafiaa/mtv/videoshrtepsodlistinq'
        // MTV-01-003 동영상 시리즈 정보 에피소드 목록 조회
        , get_cartel_tv_episode_list: 'mafiaa/mtv/videosersinfoepsodlistinq'
        // MTV-01-004 동영상 에피소드 순서변경
        , post_cartel_tv_episod_sort: 'mafiaa/mtv/videoepsodseqchg/::1.1.0'
        // MTV-01-005 동영상 시리즈 에피소드 단편 목록 조회
        , get_cartel_tv_alone: 'mafiaa/mtv/videosersepsodshrtlistinq'
        // MTV-01-006 동영상 게시물 정보 조회
        , get_cartel_tv: 'mafiaa/mtv/videopostinfoinq'
        // MTV-01-007 동영상 게시물 등록
        , post_cartel_tv: 'mafiaa/mtv/videopostreg'
        // MTV-01-008 동영상 게시물 수정
        , put_cartel_tv: 'mafiaa/mtv/videopostchg'
        // MTV-01-009 동영상 에피소드 시리즈 이동
        , post_cartel_tv_move: 'mafiaa/mtv/videoepsodsersmvg'
        // MTV-01-010 동영상 게시물 삭제
        , delete_cartel_tv: 'mafiaa/mtv/videopostdel'
        // MTV-01-011 동영상 게시물 첨부파일 삭제
        , delete_cartel_tv_file: 'mafiaa/mtv/videopostapndfiledel'
        // MTV-01-012 관리 동영상 목록 조회
        , get_cartel_tv_manage_list: 'mafiaa/mtv/mgntvideolistinq'

        // CART-03-005 메인 동영상 시리즈 정보 목록 조회
        , get_home_series: 'mafiaa/cart/mainvideosersinfolistinq'
        // CART-03-006 메인 동영상 단편 에피소드 목록 조회
        , get_home_episode: 'mafiaa/cart/mainvideoshrtepsodlistinq'
        // CART-03-007 메인 동영상 에피소드 단편 목록 조회
        , get_home_alone: 'mafiaa/cart/mainvideoepsodshrtlistinq'

        // SET-02-001 구독 정산 내역 목록 조회
        , get_calculate_list: 'mafiaa/set/sscrpsttlptcllistinq'
        // SET-02-002 구독 정산 상세 정보 조회
        , get_calculate: 'mafiaa/set/sscrpsttldtlsinfoinq'
        // SET-02-003 구독 정산 신청
        , post_calculate: 'mafiaa/set/sscrpsttlapc'
        // SET-02-004 구독 정산 신청 취소
        , delete_calculate: 'mafiaa/set/sscrpsttlapccan'

        // SET-01-001 정산 금융 정보 조회
        , get_finance: 'mafiaa/set/sttlfininfoinq'
        // SET-01-002 정산 금융사 목록 조회
        , get_finance_list: 'mafiaa/set/sttlfincolistinq'
        // SET-01-003 정산 금융 정보 등록
        , post_finance: 'mafiaa/set/sttlfininforeg'
        // SET-01-004 정산 금융 정보 수정
        , put_finance: 'mafiaa/set/sttlfininfochg'
        // SET-01-005 정산 예상 수익 정보 조회
        , get_finance_expect: 'mafiaa/set/sttlepctrvninfoinq'
        // SET-01-006 정산 수수료 기준 정보 조회
        , get_finance_fee: 'mafiaa/set/sttlcommbascinfoinq'
        // SET-01-007 정산 대상 카르텔 목록 조회
        , get_finance_cartel: 'mafiaa/set/sttltgtcartlistinq'

        // SET-03-001 후원 정산 내역 목록
        , get_calculate_support_list: 'mafiaa/set/spossttlptcllistinq'
        // SET-03-002 후원 정산 상세 정보 조회
        , get_calculate_support: 'mafiaa/set/spossttldtlsinfoinq'
        // SET-03-003 후원 정산 신청
        , post_calculate_support: 'mafiaa/set/spossttlapc'
        // SET-03-004 후원 정산 신청 취소
        , delete_calculate_support: 'mafiaa/set/spossttlapccan'


        /**
         * 오픈 카르텔
         */

        // CART-01-003	전체 카르텔 목록 조회
        , get_open_cartel_list: 'mafiaapub/cart/allcartllistinquiry'
        // CART-01-004 전체 카르텔 게시물 목록 조회
        , get_open_cartel_all_bbs_list: 'mafiaapub/cart/allcartpostlistinq'
        // CART-03-002 추천 카르텔 목록 조회
        , get_open_cartel_recomm_list: 'mafiaapub/cart/recomcartllistinquiry'
        // CART-03-004 숏플 게시물 목록 조회
        , get_open_cartel_shorts_list: 'mafiaapub/cart/shrtplebulletinarticleslistinquiry'
        // SSC-06-003 카르텔 구독 가능 플랜 목록 조회
        , get_open_cartel_subscribe_list: 'mafiaapub/ssc/cartsscrpposbplanlistinq'
        // CART-04-001	카르텔 정보 조회
        , get_open_cartel_info: 'mafiaapub/cart/cartlinfoinquiry'
        // CART-17-002	게시물 정보 조회
        , get_open_cartel_bbs_list: 'mafiaapub/cart/bulletinarticleslistinquiry'

        // CART-21-004 회원 카르텔 개설 요청
        , post_member_cartel: 'mafiaa/cart/mmbcartopen'

        // CART-04-003	카르텔 메뉴 조회
        , get_open_cartel_menu: 'mafiaapub/cart/cartlmenuinquiry'
        // CART-04-005 게시판 유형별 카르텔 메뉴 조회
        , get_open_cartel_menu2: 'mafiaapub/cart/boardtypeclassfied bycartlmenuinquiry'
        // VTG-01-001 카르텔 투표 게시판 목록 조회
        , get_open_vote_list: 'mafiaapub/vtg/cartlvoteboardlistinquiry'
        // CART-03-005 메인 동영상 시리즈 정보 목록 조회
        , get_open_home_tv_series: 'mafiaapub/cart/mainvideosersinfolistinq'
        // CART-03-006 메인 동영상 단편 에피소드 목록 조회
        , get_open_home_tv_episode: 'mafiaapub/cart/mainvideoshrtepsodlistinq'
        // CART-03-007 메인 동영상 에피소드 단편 목록 조회
        , get_open_home_tv_alone: 'mafiaapub/cart/mainvideoepsodshrtlistinq'

        // MTV-01-001 동영상 시리즈 정보 목록 조회
        ,get_open_tv_series: 'mafiaapub/mtv/videosersinfolistinq'
        // MTV-01-002 동영상 단편 에피소드 목록 조회
        ,get_open_tv_alone_list: 'mafiaapub/mtv/videoshrtepsodlistinq'
        // MTV-01-003 동영상 시리즈 정보 에피소드 목록 조회
        ,get_open_tv_series_episode: 'mafiaapub/mtv/videosersinfoepsodlistinq'
        // MTV-01-005 동영상 시리즈 에피소드 단편 목록 조회
        ,get_open_tv_alone: 'mafiaapub/mtv/videosersepsodshrtlistinq'
        // MTV-01-006 동영상 게시물 정보 조회
        ,get_open_tv_series_info: 'mafiaapub/mtv/videopostinfoinq'

        // VTG-03-001 카르텔 투표 게시판 진행 목록 조회
        , get_open_voting_list_ing: 'mafiaapub/vtg/cartlvoteboardprogresslistinquiry'
        // VTG-03-002 카르텔 투표 게시판 종료 목록 조회
        , get_open_voting_list_end: 'mafiaapub/vtg/cartlvoteboardendlistinquiry'
        // VTG-03-003 카르텔 투표 정보 조회
        , get_open_voting_info: 'mafiaapub/vtg/cartlvoteinfoinquiry'

        // VTG-01-006 카르텔 투표 게시판 상세 조회
        , get_open_voting_board: 'mafiaapub/vtg/cartlvoteboarddetilinquiry'

        // CART-10-005 게시판  권한  정책  조회
        , get_open_board_config: 'mafiaapub/cart/boardauthoritypolicyinquiry'
        // CART-17-008 게시판 게시물 목록 조회
        , get_open_board_bbs_list: 'mafiaapub/cart/boardpostlistinquiry'
        // CART-19-001 공지사항  목록  조회
        , get_open_cartel_notice_list: 'mafiaapub/cart/noticemttrslistinquiry'
        // CART-20-001 한줄응원  목록  조회
        , get_open_cartel_cheer_list: 'mafiaapub/cart/onelincheerlistinquiry'

        // CART-09-006	카르텔 회원 등급 목록 조회 요청
        , get_open_cartel_member_grade_list: 'mafiaapub/cart/cartlmembergradelistinquiry'

        //
        , get_open_cartel_notice_info: 'mafiaapub/cart/noticemttrsinfoinquiry'
        //
        , get_open_cartel_bbs_info: 'mafiaapub/cart/postinfoinquiry'
        //
        , get_open_board: 'mafiaapub/cart/boardinfoinquiry'

        // 카카오 소셜 인증
        , post_join_kakao: 'mafiaa/mem/socialcertfc/::3.0.0'
        , post_login_kakao: 'mafiaa/mem/loginsocialcertfc/::2.0.0'
    }
}