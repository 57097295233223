<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="pt-80">
					<div class="intro_logo_title">
						<span class="hide">{{ $language.common.title }}</span>
					</div>
				</div>
				<div
					class="intro_btn_wrap mt-30 pb-30 position-fixed" style="left: 0; right: 0; bottom: 30px"
				>
					<div
					>
						<button @click="setType('agree')" class="btn_l btn_fill_blue width-100">{{ $language.common.start }}</button>

						<a @click="setType('social')" class="btn_signup">{{ $language.common.login }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Start'
	, components: {}
	, props: []
	, data: function(){
		return {
			program: {
				name: this.$language.common.login
				, not_header: true
				, not_footer: true
			}
		}
	}
	, computed: {
	}
	,methods: {

		setType: function(type){
			this.type = type
			this.$emit('click', { step: this.step, type: type})
		}
	}
	, created() {
	}
}
</script>