<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4> {{ title }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p v-html="content"></p>
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap flex-row justify-space-between">
						<div
							v-if="is_update"
							class="btn_area flex-1"
						>
							<button
								@click="doUpdate"
								class="btn_l btn_fill_blue"
							>{{ $language.common.update }}</button>
						</div>
						<div
							v-else-if="is_exit"
							class="btn_area flex-1"
						>
							<button
								@click="toExit"
								class="btn_l btn_fill_gray"
							>{{ $language.common.destroy }}</button>
						</div>
						<div
							v-else
							class="btn_area flex-1"
						>
							<button
								@click="toNext"
								class="btn_l btn_fill_blue"
							>{{ $language.common.ok }}</button>
						</div>
						<div
							v-if="is_cancel"
							class="btn_area flex-1 ml-10"
						>
							<button
								@click="toCancel"
								class="btn_l btn_fill_gray"
							>{{ $language.common.later }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'version'
	, props: ['type', 'message']
	,data: function(){
		return {
			program: {
				name: this.$language.appLanguage.title + this.$language.common.ok
				, not_header: true
				, not_footer: true
			}
		}
	}
	, computed: {
		title: function(){
			let t = ''

			switch (this.type){
				case 'AP00500002':
					t = this.$language.common.service_info_metamafia
					break;
				case 'AP00500003':
					t = this.$language.common.service_info_metamafia
					break;
				case 'AP00500004':
					t = this.$language.common.service_info_metamafia
					break;
				case 'AP00500005':
					t = this.$language.common.service_info_metamafia
					break;
				case 'AP00500006':
					t = this.$language.common.service_info_metamafia
					break;
				default:
					t = this.$language.common.service_info_metamafia
					break;
			}

			return t
		}
		, content: function(){
			let t = ''

			if(this.message){
				t = this.message
			}else{

				switch (this.type){
					case 'AP00500002':
						t = this.$language.common.app_notice_push
						break;
					case 'AP00500003':
						t = this.$language.common.cannot_service_now
						break;
					case 'AP00500004':
						t = this.$language.common.release_new_version
						break;
					case 'AP00500005':
						t = this.$language.common.release_new_version + '<br/><br/>' + this.$language.common.push_new_update_02
						break;
					case 'AP00500006':
						t = this.$language.common.push_new_update_01
						break;
				}
			}

			return t
		}
		, is_cancel: function(){
			let t = false
			switch (this.type){
				case 'AP00500004': case 'AP00500006':
					t = true
					break;
			}
			return t
		}
		, is_exit: function(){
			let t = false
			switch (this.type){
				case 'AP00500003':
					t = true
					break;
			}
			return t
		}
		, is_update: function(){
			let t = false
			switch (this.type){
				case 'AP00500004': case 'AP00500005': case 'AP00500006':
					t = true
					break;
			}
			return t
		}
	}
	,methods: {
		toNext: function(){
			this.$emit('cancel')
		}
		, doUpdate: function(){
			try {
				this.$common.inAppWebviewCommunity('android', 'updateApp')
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_update})
			}
		}
		, toCancel: function(){
			this.$emit('cancel')
		}
		, toExit: function(){
			try {
				console.log('version exitApp')
				this.$common.inAppWebviewCommunity('android', 'exitApp')
			}catch (e){
				console.log(e)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>

<style>
.flex-row { display: flex; flex-direction: row}
.justify-space-between { justify-content: space-between}
.flex-1 { flex: 1}
</style>