<template>
	<div class="bg_dark" style="background-color: #0f1020 !important;">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4>{{  text_title }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p>{{  text_content }}</p>
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button
								@click="toNext"
								class="btn_l btn_fill_blue"
							>{{ text_button }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Error'
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: 'Error'
				, not_header: true
				, not_footer: true
			}
			, type: this.$route.params.type
			, msg: this.$route.params.msg
			, error: sessionStorage.getItem('Error')
		}
	}
	, computed: {
		text_title: function(){
			let t = ''
			switch (this.error){
				case '401':
					t = this.$language.common.expire_token
					break
				case '400':
					t = this.$language.common.impossible_token
					break
				case '500':
					t = this.$language.common.error_token
					break
				default:
					t = this.error
					break
			}
			return t
		}
		, text_content: function(){
			let t = ''
			switch (this.error){
				case '401':
					t = this.$language.common.message_login_require
					break
				case '400':
					t = this.$language.common.message_wrong
					break
				case '500':
					t = this.$language.common.message_error
					break
				default:
					t = this.error
					break
			}
			return t
		}
		, text_button: function(){

			let t = ''
			switch (this.error){
				case '401':
					t = this.$language.common.ok
					break
				case '400':
					t = this.$language.common.ok
					break
				case '500':
					t = this.$language.common.again
          break
				default:
					t = this.error
					break
			}
			return t
		}
	}
	,methods: {
		toNext: async function(){

			let error = sessionStorage.getItem('Error')
			try {
				this.$bus.$emit('on', true)
			}catch (e){
				console.log(e)
			}finally {
				sessionStorage.removeItem('Error')
				switch (error){
					case '401':

						await this.$encodeStorage.removeSessionAt()
						await this.$encodeStorage.removeItem('login_info')
						await this.$encodeStorage.removeSessionCertfc()

						await this.$bus.$emit('to', { name: 'login'})
						break
					case '400':
						document.location.reload()
						break
					default:

						if(!this.user){
							await this.$bus.$emit('to', { name: 'login'})
						}else{
							document.location.reload()
						}
						break
				}

				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>