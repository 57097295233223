<template>
	<div
		:class="wrap"
	>
		<Loading></Loading>
		<!-- 고정해더 -->

		<!-- //고정해더 -->
		<!-- 하단 네비 -->
		<!-- //하단 네비 -->
		<!-- 컨텐츠 내용 -->

		<template
			v-if="!program.not_header"
		>
			<HeaderMypage
				v-if="program.type == 'mypage'"

				:program="program"
				@goBack="goBack"
				@callBack="callBack"
			></HeaderMypage>

			<HeaderPopup
				v-else-if="program.type == 'popup'"

				:program="program"
				@goBack="goBack"
				@callBack="callBack"
			></HeaderPopup>

			<HeaderCartel
				v-else-if="program.type == 'cartel_sub'"

				:program="program"
				@goBack="goBack"
				@callBack="callBack"
			></HeaderCartel>

			<HeaderDark
				v-else-if="is_dark_mode"
				:user="user"

				@to="to"
			></HeaderDark>

			<Header
				v-else
				:user="user"

				@to="to"
			></Header>
		</template>

		<bottomCartel
			v-if="!program.not_footer && program.type && is_cartel && program.type.indexOf('cartel') > -1"
			:user="user"
			:program="program"
		></bottomCartel>
		<Bottom
			v-else-if="!program.not_footer && program.type != 'popup' && !is_cartel"
			:program="program"
			:user="user"
		></Bottom>

		<div
			v-if="false"
			style="position: fixed; left: 0; top: 30%; padding: 10px; border: 1px solid red; z-index: 999999; background-color: white"
		>
			{{ user }}
		</div>

		<router-view
			:Axios="Axios"
			:Notify="Notify"
			:class="bg_router"
			:sample="sample"
			:callBack="returnCallBack"
			:rules="rules"
			:user="user"
			:Base64="Base64"
			:screen_angle="screen_angle"

			@to="to"
			@onLoad="onLoad"
			@pinCheck="is_pin = true"
			@goBack="goBack"
			@onLoading="onLoading"
			@offLoading="offLoading"
		></router-view>

		<PIN
			v-if="is_pin"
			:user="user"
			:options="pin_option"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 99999"

			@click="pinCallBack"
			@cancel="offPin"
			@logout="logout"
		></PIN>

		<OTP
			v-if="is_otp"
			:options="otp_option"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 101; background-color: white"

			@click="otpCallBack"
			@cancel="is_otp = false"
		></OTP>

		<Notify
			:msg="notifyCondition.message"
			:type="notifyCondition.type"
			:config="notifyCondition.config"

			@clearMsg="clearMsg"
		/>

		<Unauthorized
			v-if="is_401"

			:user="user"

			class="layer-popup"
		></Unauthorized>

		<system01
			v-if="is_system01"
			:user="user"
		></system01>

		<Event
			:user="user"
		></Event>

		<cartel-permission
			:user="user"
		></cartel-permission>

		<cartel_member_grade_monitor
			:user="user"
		></cartel_member_grade_monitor>

		<PersonalConfirm
			v-if="is_on_personal_confirm"
			:user="user"
			:type="personal_type"

			@cancel="is_on_personal_confirm = false"
			@click="toNext"
		></PersonalConfirm>


		<PopupLayer
			v-if="is_on_permission"
		>
			<template
				v-slot:body
			>
				<div class="bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<h3 class="pop_tit pop_tit_logo text-center under-line mt-30 pb-30">앱 접근 권한 안내</h3>
						<div
							class=" mt-10 bg-white justify-space-between-in "
						>
							<div class="gap-10">
								<div class="icon-64 radius-100"><img :src="require('@/assets/image/icon_image_permission.svg')" class="width-100" /></div>
								<div class="flex-1 size-px-12">
									<div>사진/미디어/파일</div>
									<div>글 작성, 파일 읽기 또는 저장</div>
								</div>
							</div>
							<div class="gap-10 mt-10">
								<div class="icon-64 radius-100 "><img :src="require('@/assets/image/icon_camera_permission.svg')" class="width-100 object-cover" /></div>
								<div class="flex-1 size-px-12">
									<div>카메라</div>
									<div>사진 또는 동영상 촬영</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="btn_wrap justify-space-between"
					>
						<button
							v-if="false"
							class=" btn_fill_gray"
							@click="is_on_permission = false; goBack()"
						>{{ $language.common.cancel }} <!-- 닫기 --></button>
						<button
							class=" btn_fill_blue"
							@click="postPermission"
						>{{  $language.common.ok }} <!-- 확인 --></button>
					</div>
				</div>
			</template>
		</PopupLayer>


		<Auth
			v-if="is_on_login"

			class="position-fixed-full z-index-layer"
			:to="login_to"
			:is_auto="is_auto"

			@cancel="is_on_login = false"
		></Auth>
	</div>
</template>

<script>
import Header from '@/view/Layout/Header'
import Bottom from "@/view/Layout/Bottom";

import Notify from '@/components/AlertMsg'
import HeaderPopup from "@/view/Layout/HeaderPopup";
import HeaderMypage from "@/view/Layout/HeaderMypage";

import PIN from "@/view/Auth/mafia007"
import Loading from "@/view/Layout/Loading";
import HeaderCartel from "@/view/Layout/HeaderCartel";
import OTP from "@/view/Auth/mafia111-6"

import Unauthorized from "@/view/Error/Unauthorized"
import System01 from "@/view/Auth/system-01";
import CartelPermission from "@/view/Cartel/cartel_permission";
import cartel_member_grade_monitor from "@/view/Cartel/cartel_member_grade_monitor";
import HeaderDark from "@/view/Layout/HeaderDark";
import Event from "@/view/Event/Event";
import PersonalConfirm from "@/view/Layout/PersonalConfirm";
import BottomCartel from "@/view/Layout/BottomCartel";
import PopupLayer from "@/view/Layout/PopupLayer";
import Auth from "@/view/V2/Auth/Auth";

export default {
	name: 'Layout'
	, props: ['Axios', 'Notify', 'sample', 'rules', 'user', 'Base64']
	, components: {
		Auth,
		PopupLayer,
		PersonalConfirm,
		Event,
		HeaderDark,
		cartel_member_grade_monitor,
		CartelPermission,
		System01,
		HeaderCartel, HeaderPopup, Header, Bottom, Notify, HeaderMypage, PIN, Loading, OTP, Unauthorized, BottomCartel
	}
	, data: function () {
		return {
			program: {
				type: ''
			}
			, notifyCondition: {
				message: this.$language.common.message_test
				, type: ''
			}
			, returnCallBack: {}
			, is_pin: false
			, pin_option: {
				pin_type: 'check'
				, is_can_cancel: true
				, to: ''
			}
			, is_otp: false
			, otp_option: {
				otp_type: 'confirm'
			}
			, is_loading: false
			, is_view: false
			, error_status: ''
			, is_401: false
			, is_system01: true
			, expiration_time: 3600
			, login_time: 0
			, time_out: false
			, is_today: false
			, is_on_personal_confirm: false
			, personal_type: ''
			, screen_angle: 0
			, is_on_permission: false
			, last_time: ''
			, is_on_login: false
			, login_to: ''
			, is_auto: false
		}
	}
	, computed: {
		wrap: function () {
			let type = 'wrap'
			if (this.program.wrap_type == 'flex') {
				type = 'wrap-flex'
			}
			return type
		}
		, is_sample: function () {
			let t = false
			if (process.env.VUE_APP_TYPE == 'sample') {
				t = true
			}
			return t
		}
		, bg_router: function () {
			let type = 'bg_dark'
			if (this.program.type == 'mypage') {
				type = ''
			} else if (this.program.type == 'cartel' || this.program.type == 'cartel_sub') {
				type = 'bg_wh'
			}
			return type
		}
		, is_dark_mode: function () {
			let t = false
			if (this.user.dark_mode == 'Y') {
				t = true
			}
			return t
		}
		, is_event: function () {
			let t = false
			let event = localStorage.getItem('event')

			if (event != this.$date.getToday('-')) {
				t = true
			}
			return t
		}
		, is_cartel: function(){
			let t = false
			if(this.$route.params.idx){
				t = true
			}
			return t
		}
	}
	, methods: {

		clearMsg: function () {
			this.notifyCondition.message = ''
		}
		, setQuery: function () {
			let store = localStorage.getItem('query')
			if (store) {
				let query = JSON.parse(store)
				this.query = query
			}
		}
		, onLoad: function (program) {
			// console.log('onload', program)
			this.error_status = sessionStorage.getItem('Error')
			if (this.$route.fullPath.indexOf('/auth/') > -1) {
				switch (this.error_status) {
					case '401':
						this.is_401 = true
						break;
				}
			} else {
				this.is_401 = false
			}
			// console.log(`onload ${program.name}`, this.error_status, program)

			this.program = program
		}
		, goBack: function () {
			// console.log('goBack', this.program.from)

			if (this.program.from) {

				this.$router.push({name: this.program.from, params: this.program.params}).catch((e) => {
					if (this.program.from == 'mafia049') {
						this.$bus.$emit('to', { name: 'main'})
					}
					console.log(e)
				})
			} else {
				this.$storage.clearQuery()
				this.query = {}
				this.$router.back()
			}
		}
		, pinCallBack: function (pin_number) {
			this.is_pin = false
			this.pin_option.is_can_cancel = true
			this.$bus.$emit('pinCallback', this.pin_option.pin_type, pin_number)
		}
		, otpCallBack: function (otp_number) {
			console.log('layout otpCallBack !!')
			this.is_otp = false
			this.$bus.$emit('otpCallBack', this.otp_option.otp_type, otp_number)
		}
		, callBack: function () {
			this.returnCallBack = this.program.callBack
			this.is_pin = false
			this.$bus.$emit('callBack', this.returnCallBack)
		}
		, onLoading: function () {
			this.is_loading = true
		}
		, offLoading: function () {
			setTimeout(() => {
				this.is_loading = false
			}, 300)
		}
		, onPin: function (pin_type, to) {
			if (pin_type == 'set' || pin_type == 'join') {
				this.pin_option.is_can_cancel = false
			} else {
				this.pin_option.is_can_cancel = true
			}
			this.pin_option.pin_type = pin_type
			this.pin_option.to = to
			this.is_otp = false
			this.is_pin = true
		}
		, offPin: function () {
			this.is_pin = false
			this.pin_option.pin_type = 'check'
			this.$bus.$emit('pinCancel')
			this.$bus.$off('pinCallback')
		}
		, logout: function(){
			this.$bus.$emit('logout')
			this.offPin()
		}
		, onOtp: function (otp_type) {
			this.otp_option.otp_type = otp_type
			this.is_pin = false
			this.is_otp = true
		}
		, offOtp: function () {
			this.is_otp = false
		}
		, refresh: function () {

			let t = true
			if(t){
				return false
			}
			try {
				this.$common.inAppWebviewCommunity('android', 'time_out')
			} catch (e) {
				this.time_out = setTimeout(() => {

					if (this.$route.fullPath.indexOf('auth') < 0) {
						if (this.login_time >= this.expiration_time) {
							clearTimeout(this.time_out)
							this.$bus.$emit('logout')
						} else {
							this.login_time += 1
						}

						// console.log('time_out', this.login_time)
					} else {
						this.login_time = 0
					}

					this.refresh()
				}, 1000)
			}
		}
		, clipBoard: function (val) {
			const t = document.createElement("textarea");
			document.body.appendChild(t);
			t.value = val;
			t.select();
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		, copy: function (val) {
			this.clipBoard(val);
			this.$bus.$emit('notify', {type: 'success', message: this.$language.common.copied_member_number})
		}

		, postEventClose: function () {
			if (this.is_today) {
				localStorage.setItem('event', this.$date.getToday('-'))
			} else {
				localStorage.setItem('event', this.$date.getToday('-'))
			}

		}
		, toNext: function () {
			this.is_on_personal_confirm = false
			if(this.personal_type){
				this.$bus.$emit('to', { name: this.personal_type.toLowerCase(), query: {is_new: 1}})
			}else{
				this.$bus.$emit('to', { name: this.personal_type})
			}
		}

		, toLive: function (item) {
			//this.$bus.$emit('to', { name: 'CartelLiveDetail', params: { idx: this.$route.params.idx, live_idx: item.cartl_chttg_room_number}})

			let router = this.$router.resolve(item)

			document.location.href = router.href
		}
		, to: function(params){
			this.$bus.$emit('to', params)
		}

		, postPermission: function(){
			this.is_on_permission = false
			if(this.is_permission){
				return false
			}
			try {
				this.$common.inAppWebviewCommunity('android', 'permission')

				window.click = () => {
					this.$bus.$emit('confirmPermission')
					this.is_on_permission = false
				}
				window.deninePermission = () => {
					this.$bus.$emit('notify', { type: 'error', message: '파일 첨부에 필요한 권한이 거부되어 있습니다. 앱 설정에서 권한승인이 필요합니다.'})
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('confirmPermission')
			}
		}

	}
	, created() {
		// console.log('on layout')

		this.$bus.$on('onLoad', async (program) => {
			this.onLoad(program)
		})
		this.$bus.$on('onPin', (type, to) => {
			this.onPin(type, to)
		})
		this.$bus.$on('offPin', () => {
			this.offPin()
		})
		this.$bus.$on('onOtp', (type) => {
			console.log('layout bus on otp')
			this.onOtp(type)
		})
		this.$bus.$on('offOtp', () => {
			this.offOtp()
		})
		this.$bus.$on('clipboard', (val) => {
			this.copy(val)
		})
		this.$bus.$on('goBack', () => {
			this.goBack()
		})

		this.$bus.$on('offLogin', () => {
			this.is_on_login = false
		})

		this.$bus.$on('onLogin', (type, to) => {
			this.is_on_login = true
			this.login_to = to
			if(type){
				this.is_auto = true
			}else{
				this.is_auto = false
				// this.$bus.$emit('notify',{ type: 'error', message: '로그인 후 이용가능합니다'})
			}
		})

		this.$bus.$on('onPersonalConfirm', (type) => {
			this.is_on_personal_confirm = true
			this.personal_type = type.name
		})

		this.$bus.$on('onErrorImageSample', (e) => {
			e.target.src = require('@/assets/image/image_sample.jpg')
			e.target.src = require('@/assets/image/@noimage.png')
			e.target.style.width = '100%'
			e.target.style.padding = ''
			e.target.parentElement.style.textAlign = 'center'
			e.target.parentElement.style.backgroundColor = '#eee'
		})

		this.$bus.$on('onErrorImage', (e) => {
			e.target.src = require('@/assets/image/@noimage.png')
		})

		this.$bus.$on('onErrorTv', (e) => {
			e.target.style.backgroundColor = '#181A39'
			e.target.src = require('@/assets/image/empt-tv.svg')
			e.target.classList.remove('object-cover')
		})

		this.$bus.$on('onErrorProfileImage', (e) => {
			e.target.src = require('@/assets/image/@nophoto.png')
		})

		this.$bus.$on('onErrorCartelProfileImage', (e) => {
			e.target.src = require('@/assets/image/logo-profile.png')
		})

		this.$bus.$on('onErrorCartelCharacterImage', (e) => {
			e.target.src = require('@/assets/image/@cartel_cover_basic.png')
		})

		this.$bus.$on('toLive', (item) => {
			this.toLive(item)
		})

		this.$bus.$on('onPermission', () => {
			this.is_on_permission = true
		})

		window.onPaused = () => {
			console.log('window.onPause')
			try{
				this.last_time = new Date()
				// console.log('on paused', this.last_time.getTime())
			} catch (e) {
				console.log(e)
			}
		}

		window.onPin = () => {
			console.log('window.onPin')
			try {
				if (this.user.member_number) {
					if(this.last_time){
						let now = new Date()
						// console.log('on pin', now.getTime())
						let t = now.getTime() - this.last_time.getTime()
						if(t > 60000){

							this.pin_option.is_can_cancel = false

							this.pin_option.pin_type = 'check'
							this.is_otp = false
							this.is_pin = true
						}
					}
				}
			} catch (e) {
				console.log(e)
			}finally {
				this.last_time = ''
			}
		}
		window.goBack = () => {
			console.log('window.goBack')
			try {

				let dir = this.$route.name
				if (dir == 'intro' || dir == 'index' || dir == 'main' || dir == 'home' || dir == 'mafia003' || dir == 'login') {
					console.log('layout to exitApp')
					this.$common.inAppWebviewCommunity('android', 'exitApp')
				} else {
					this.goBack()
					/*
					if(self.program.from){
						self.$router.push({ name: self.program.from})
					}else{
						self.$router.back()
					}
					*/
				}
			} catch (e) {
				console.log(e)
			}
		}

		if (!window.Kakao.isInitialized()) {
			window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
		}

		window.addEventListener(
			"orientationchange",
			() => {
				if (window.matchMedia("(orientation: portrait)").matches) {
					// you're in PORTRAIT mode
					this.screen_angle = 90
				}

				if (window.matchMedia("(orientation: landscape)").matches) {
					// you're in LANDSCAPE mode
					this.screen_angle = 0
				}
			}
		);

		// this.screen_angle = screen.orientation.angle
		// window.addEventListener("orientationchange", (event) => {
		//	 this.screen_angle = screen.orientation.angle
		//	 console.log("현재 기기 방향: ", screen.orientation.angle);
		//	 console.log(event)
		// });

		clearTimeout(this.time_out)
		this.login_time = 0
		this.time_out = false
		this.refresh()

		window.getPermission = (e) => {
			console.log('window.getPermission', e)
			if(e){
				// 권한이 있으면 종료
				this.$bus.$emit('confirmPermission')
			}else{
				// 권한이 없으면 안내 팝업
				this.is_on_permission = true
			}
		}

		window.setPermission = (e) => {
			if(e){
				this.is_permission = true
			}else{
				this.postPermission()
			}
		}

		// this.onPin('join')
	}
	, watch: {
		$route() {
			clearTimeout(this.time_out)
			this.login_time = 0
			this.time_out = false
			this.refresh()

			if(!this.user || !this.user.member_number){
				if(this.$route.fullPath.indexOf('/auth/') < 0 && this.$route.fullPath.indexOf('/event/') < 0) {
					// this.logout()
				}
			}
			// console.log(this.$route.fullPath, 'route path')
		}
	}
}

</script>

<style>
.z-index-layer {
	z-index: 11
}

.z-index-chat {
	z-index: 8
}

input:focus {

}

.no-focus {
	border: none
}
</style>