<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner" style="max-height: 480px; overflow: auto">
				<article
					class="full_view"
				>
					<div class="view_cont_wrap">
						<h3 class="pop_tit pop_tit_logo"><slot name="title">{{ title }}</slot></h3>
						<div class="intro_line_lg"><span class="hide">{{ $language.agreement.dividingLine }}</span></div>
						<p class="pop_desc">
							<slot name="main-txt">{{ content }}</slot>
							<span><slot name="sub-txt"></slot></span>
						</p>
						<slot name="content"></slot>
					</div>

					<div
						class="btn_wrap"
					>
						<button
							v-if="!not_cancel"
							@click="$emit('cancel')"
							class="pop_btn btn_fill_gray"
						><slot name="name-cancel">{{ btn_cancel ? btn_cancel : $language.common.cancel }}</slot></button>
						<button
							@click="$emit('click')"
							class="pop_btn btn_fill_blue"
						><slot name="name-confirm">{{ btn_click ? btn_click : $language.common.ok }}</slot></button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'popup_confirm'
	, props: ['type', 'not_cancel', 'btn_cancel', 'btn_click', 'title', 'content']
	, data: function(){
		return {

		}
	}
}
</script>