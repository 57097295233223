<template>
	<div class="wrap">
		<div class="bg_w">
			<div class="container">
				<div class="row">
					<div class="flex_wrap">
						<div class="intro_content maf006">
							<div class="intro_top character2">
								<div class="text_guide pt-20">
									<h4>{{ $language.auth.otp_auth }}</h4>
								</div>
								<div
									@click="$emit('cancel')"
									class="mt-10" style="font-size: 12px; text-align: center; font-weight: normal"
								><v-icon small>mdi mdi-arrow-left</v-icon>{{ $language.common.back }}</div>

							</div>
						</div>
						<div class="intro_btn_wrap">
							<div class="bright_input input_area">
								<label for="tit otpNum">{{ $language.common.otp_auth_number }}</label>
								<div class="input_utill">
									<input v-model="gogle_otp_certfc_number" type="text" id="otpNum" :placeholder="$language.auth.otp_input" maxlength="6">
								</div>
							</div>
							<div class="btn_area pt-0 mt-20">
								<button class="btn_l btn_fill_blue" :disabled="gogle_otp_certfc_number.length < 6" @click="postOtp">{{ $language.common.ok }}</button>
							</div>
							<a
								v-if="is_otp_find"
								class="otp_find mt-20" @click="toOtpChange"
							>{{ $language.auth.otp_forget_01 }}<i class="icon-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia1116'
		, props: ['user', 'options']
		, data: function(){
			return {
				gogle_otp_certfc_number: ''
			}
		}
		, computed: {
			otp_type: function(){
				let type = 'set'

				if(this.options){
					type = this.options.otp_type
				}else if(this.$route.params.otp_type){
					type = this.$route.params.otp_type
				}

				console.log('otp_type', type)
				return type
			}
			, is_otp_find: function(){
				let t = true
				if(this.otp_type == 'pin_confirm'){
					t = false
				}
				return t
			}
		}
		, methods: {
			postOtp: function(){
				if(this.otp_type == 'pin_confirm') {
					this.postPinOtp()
				}else if(this.otp_type == 'check'){
					this.$emit('click', this.gogle_otp_certfc_number)
				}else{
					this.postOtpAdd()
				}
			}
			, postOtpAdd: async function(){
				try {
					if(!this.gogle_otp_certfc_number){
						throw this.$language.auth.otp_input_01
					}
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_google_otp
						, data: {
							gogle_otp_certfc_number: this.gogle_otp_certfc_number
						}
						, type: true
					})

					if(result.success){
						if(result.data.session_token){
							await this.$encodeStorage.setSessionToken(result.data.session_token)
						}
						this.$emit('click')
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, postPinOtp: async function(){
				try {
					if(!this.gogle_otp_certfc_number){
						throw this.$language.auth.otp_input_01
					}
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_otp_for_pin
						, data: {
							gogle_otp_certfc_number: this.gogle_otp_certfc_number
						}
						, type: true
					})

					if(result.success){
						if(result.data.session_token){
							await this.$encodeStorage.setSessionToken(result.data.session_token)
						}
						this.$emit('click')
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, toOtpChange: function(){
				this.$emit('cancel')
				this.$bus.$emit('to', { name: 'mafia1113'})
			}
		}

	}
</script>