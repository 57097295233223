import axios from 'axios'
import { encodeStorage } from "@/resources/storage/localStorage";

let domain = process.env.VUE_APP_DOMAIN
let domain_m = process.env.VUE_APP_DOMAIN_M
let domain_stg = process.env.VUE_APP_DOMAIN_STG
let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
let domain_dev = process.env.VUE_APP_DOMAIN_DEV
let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
let dev = process.env.VUE_APP_DEV
let server = process.env.VUE_APP_SERVER
let stg = process.env.VUE_APP_STG
let local = process.env.VUE_APP_LOCAL
let location = window.location.href

let baseUrl = ''

// 운영계 API 호출
if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
	baseUrl = server

// 검증계 API 호출
}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
	baseUrl = stg

// 개발계 API 호출
}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
	baseUrl = dev

// 로컬 API 호출
}else{
	baseUrl = local
}

export async function Axios({ method, url, data, header, authorize, multipart, TOKEN, blob, isResult, is_data, credent, origin, form_data }){

	const instance = axios.create({
		baseURL: baseUrl
		,timeout: 30000
	})

	const getUrl = function(){

		let d = url.split('/::')
		if(d.length > 1){
			url = d[0]
		}

		return url
	}

	const getParams = function(){
		if(method == 'get'){
			return data
		}
	}

	const getData = function(){

		let formData = ''
		if(multipart || form_data){
			formData = new FormData();
			for(let key in data){
				if(Array.isArray(data[key])){

					for(let i = 0; i < data[key].length; i++) {

						if(Array.isArray(data[key][i])) {
							for (let sub_key in data[key][i]) {
								formData.append(`${key}[` + i + '].' + sub_key, transVal(data[key][i][sub_key]))
							}
						}else{
							formData.append(`${key}`, transVal(data[key][i]))
						}
					}
				}else {
					formData.append(key, transVal(data[key]))
				}
			}

			return formData
		}else if(is_data) {
			return data
		}else{
			return { d: data }
		}
	}

	const transVal = function(value){
		if( value === "" || value === null || value === undefined){
			return ''
		}else{
			return value
		}
	}

	const getHeader = function(){

		let default_header = {
			'Content-Type': 'application/json'
			, 'v': '1.0.0'
			, 'Content-Security-Policy' : 'upgrade-insecure-requests'
		}

		let d = url.split('/::')
		if(d.length > 1){
			default_header.v = d[1]
			url = d[0]
		}

		let session_token = encodeStorage.getSessionToken()
		//console.log('session_token', session_token)

		if(session_token){
			default_header.a = session_token
			//console.log('default_header', default_header)
		}

		if(origin){
			default_header['Access-Control-Allow-Origin'] = origin
		}

		if(!authorize){
			//default_header.Authorization = 'Bearer ' + (TOKEN ? TOKEN : sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT'))

		}

		if(TOKEN){
			// console.log('TOKEN :', TOKEN)
		}
		if(multipart){
			default_header['Content-Type'] = 'multipart/form-data'
		}

		if(blob){
			default_header['responseType'] = 'blob'
		}
console.log(url, header)
		return header ? header : default_header
	}

	// console.log('to instance')
	return await instance({
		method: method
		,headers: getHeader()
		,url: getUrl()
		,params: getParams()
		,data: getData()
		,withCredentials: credent ? credent : false
		, origin: baseUrl

	}).then((result) => {
		let t = {
			success: true
			, result: result
		}
		if(isResult){
			return t
		}
		return t
	}).catch( (error) => {
		console.log('axios error', error)
		let t = {
			success: false
			, error: error.response
		}
		return t
	})
}



