<template>
	<mobile
		v-if="is_mode"
	></mobile>
	<app-language
		v-else-if="is_language"
	></app-language>
	<version
		v-else-if="is_version"
		:type="item_app_info.app_execu_code"
		:msg="item_app_info.msg"

		@cancel="is_version = false"
	></version>
	<system02
		v-else-if="is_system02"
		:user="user"
	></system02>
	<Layout
		v-else-if="is_view"
		:Base64="Base64"
		:metaInfo="metaInfo"
		:TOKEN="TOKEN"
		:user="user"
		:codes="codes"
		:certfc="certfc"
	/>
	<Intro
		v-else
		:user="user"
	></Intro>
</template>

<style lang="css" src="@/assets/css/reset.css"></style>
<style lang="css" src="@/assets/css/icomoon.css"></style>
<style lang="css" src="@/assets/css/font.css"></style>
<style lang="css" src="@/assets/css/style.css"></style>
<style lang="css" src="@/assets/css/main.css"></style>

<script>

import { metaInfo } from '@/resources/config/metainfo'
import { codes } from '@/resources/config/codes'

import { Base64 } from 'js-base64'

import Layout from '@/view/Layout/Layout.vue'

import {sample} from "@/resources/sample/sample";

import Intro from "@/view/Main/Intro"

import { v4 as uuidvr4 } from 'uuid'
import version from "@/view/Auth/version";
import AppLanguage from "@/view/Auth/appLanguage";
import System02 from "@/view/Auth/system-02";
import mobile from "@/view/Auth/mobile";

export default {

	name: 'App'
	,components: {mobile, System02, AppLanguage, version, Layout, Intro }
	,data: function(){
		return {
			metaInfo: metaInfo
			, Base64: Base64
			, codes: codes
			, TOKEN: ''
			, user: {}
			, certfc: {
				session_token: ''
			}
			, sample: sample
			, AT: ''
			, is_notify: false
			, item_app_info: {
				update_code: ''
			}
			, is_version_match: false
			, is_version: false
			, is_view_: false
			, is_system02: process.env.VUE_APP_SYSTEM_NOTICE
			, is_error: true
			, item_error: {}
			, is_view: false
		}
	}
	, computed: {
		is_view2: function(){
			let t = false

			if((this.is_version_match && this.user.member_number) || (this.certfc.session_token && this.$route.fullPath.indexOf('/auth/') > -1) || this.is_view_ || this.$route.name == 'EventDetail' ){
				t = true
			}
			// console.log('is_view', this.$route.name,  this.is_version_match, this.user.member_number, this.certfc.session_token, this.$route.fullPath.indexOf('/auth/'), this.is_view_, `t: ${ t }`)
			t = true
			return t
		}
		, is_language: function(){
			let t = false

			if(!localStorage.getItem('language')){
				t = true
			}

			t = false

			return t
		}
		, is_mode: function(){
			let t = false

			let is_mobile = this.$common.isMobile()
			let location = window.location.href
			let domain = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV_M
			let domain_local = process.env.VUE_APP_DOMAIN_LOCAL_M

			if(is_mobile == 'CM00500002' && (location.indexOf(domain) > -1 || location.indexOf(domain_dev) > -1 || location.indexOf(domain_stg) > -1 || location.indexOf(domain_local) > -1)){
				t = true
			}
			// console.log('is_mode', is_mobile, location, t)
			if(this.$route.name == 'AuthConfirm' || this.$route.name == 'kakaoLogin'){
				t = false
			}

			let mode = localStorage.getItem('mode')
			if(mode == 'mobile'){
				t = false
			}
			return t
		}
	}
	, methods: {
		postAutoLogin: function(){

		}
		, postAutoLogin2: async function(){
			console.log('App postAutoLogin')
			try {

				let login_data = this.$encodeStorage.getItem('login_info')
				let pin_token = this.$encodeStorage.getItem('pin_token')

				if(!login_data || !pin_token){
					await this.$router.push({ name: 'login'})
					return false
				}else {
					await this.$router.push({ name: 'login', params: { auto: 'true'}})
					return false
				}
			} catch (e) {
				console.log('auto login fail !!')
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
				await this.$router.push({ name: 'login'})
			}
		}

		, postDeviceToken: async function(token){

			console.log('postDeviceToken')
			/*
			for(let [k, v] of Object.entries(token)){
				//alert(k + ':' + v)
				console.log(k, v)
			}

			 */
			try {

				const result = await this.$Request({
					method: 'POST'
					, url: this.$api_url.api_path.post_device_token
					, data: token
					, type: true
				})
				if (result.success) {

					this.item_app_info = result.data

					/*
					for(let [k, v] of Object.entries(this.item_app_info)){
						//alert(k + ':' + v)
						console.log(k, v)
					}

					 */
					try{
						this.$common.inAppWebviewCommunity('android', this.item_app_info.app_execu_code)

						if(this.item_app_info.app_execu_code && this.item_app_info.app_execu_code != 'AP00500001'){
							this.is_version = true
						}else{
							throw ''
						}
					}catch (e){
						this.certfc = result.data
						await this.$encodeStorage.setSessionCertfc(this.certfc)
						await this.$encodeStorage.setSessionVersion(token.app_version)
						await this.doLogin()
					}
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.is_view_ = true

				await this.$router.push({ name: 'mafia009', params: { type: 'third', msg: e}})
			}
		}

		, doLogin: async function(){

			let AT = this.$encodeStorage.getSessionAt()
			let is_intro = localStorage.getItem('is_intro')

			if(!AT){
				if(!is_intro) {
					if(document.location.href.indexOf('auth') > -1 || document.location.href.indexOf('sample/reset') > -1){
						// console.log('this.$router.currentRoute.fullPath', this.$router.currentRoute.fullPath)
					}else {
						await this.$router.push({name: 'intro'})
					}
				}else{
					// console.log('app location', document.location.href)

					if(document.location.href.indexOf('auth') > -1 || document.location.href.indexOf('sample/reset') > -1){
						// console.log('this.$router.currentRoute.fullPath', this.$router.currentRoute.fullPath)
					}else{

						await this.postAutoLogin()
					}
				}

			}else{
				if(this.s_notify){
					await this.$router.push({ name: 'mafia118'})
				}
			}
		}

		, getDeviceInfo: async function () {
			console.log('in getDeviceInfo')
			// 디바이스 정보
			try {

				this.$common.inAppWebviewCommunity('android', 'device_info')
				console.log('in app webview')
				window.device_info = async (device_info) => {
					device_info = JSON.parse(device_info)
					// console.log('window.device_info', device_info)

					//this.is_view = true

					await this.$encodeStorage.setSessionCertfc(device_info)
					await this.postDeviceToken(device_info)
					await this.$encodeStorage.setItem('_uuid', device_info.push_id)
				}
			}catch (e){
				console.log('디바이스 정보', e)

				// 디바이스 uuid
				let uuid = this.$encodeStorage.getItem('_uuid')

				if(!uuid){
					uuid = uuidvr4()
					await this.$encodeStorage.setItem('_uuid', uuid)
				}

				let device_info = {
					"conn_div_code": this.$common.isMobile(),
					"uniq_device_id": uuid,
					"mobile_opsys_code": this.$common.getOsCode(),
					"opsys_version": "",
					"push_id": uuid,
					"app_version": "1.0.12",
					"conn_ip": await this.$common.getIp()
				}
				await this.postDeviceToken(device_info)
			}finally {
				console.log('getDeviceInfo finish')
				this.is_view = true
			}
		}

		/*
		* user - 소셜 인증 정보
		* member_number - 소셜 인증 회원 번호
		* session_token - 소셜 인증 토큰
		* */
		, setUser: async function(user){

			await this.getUserInfo(user)

			this.$bus.$emit('offPin')
			// this.$bus.$emit('to', { name: 'main'})

		}

		, updateUser: async function({key, value}){

			this.$set(this.user, key, value)
			await this.$encodeStorage.setSessionAt(this.user)
			// console.log('updateUser', this.users)
		}

		/*
		* 회원 정보 조회
		* */
		, getUserInfo: async function(user){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_my_info
					, data: {
						member_number: user.member_number
					}
					, type: true
				})
				if(result.success){

					let member_number = user.member_number
					let access_token = user.access_token
					let at = result.data
					at.member_number = member_number
					at.access_token = access_token

					this.user = at

					let dark_mode = localStorage.getItem('dark_mode')
					if(!dark_mode){
						localStorage.setItem('dark_mode', 'Y')
					}
					this.$set(this.user, 'dark_mode', localStorage.getItem('dark_mode'))
					await this.$encodeStorage.setSessionAt(at)

					await this.getAppVersionInfo()
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})

				await this.$encodeStorage.removeSessionAt()
				await this.$encodeStorage.removeItem('login_info')
				await this.$encodeStorage.removeSessionCertfc()
				await this.$router.push({ name: 'login'})
			}finally {
				// console.log('setuser finish')
				this.is_view = true
			}
		}

		, getCommonCode: async function(group_id){
			console.log('on bus getCommonCode')
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_common_code
					, data: {
						member_number: this.user.member_number
						, common_code_group_id: group_id
					}
					, type: true
				})
				if(result.success){
					return result.data
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, getAppVersionInfo: async function(){
			try{
				const result = this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_app_version_info
					, data: {
						member_number: this.user.member_number
						, app_version: process.env.VUE_APP_VERSION
					}
					, type: true
				})

				if(result.success){
					this.item_app_info = result.data
					if(this.item_app_info.update_code != 'AP00500001') {
						try{
							switch (this.item_app_info.update_code){
								case 'AP00500002':
									this.$common.inAppWebviewCommunity('android', 'AP00500002')
									break;
								case 'AP00500003':
									this.$common.inAppWebviewCommunity('android', 'AP00500003')
									break;
								case 'AP00500004':
									this.$common.inAppWebviewCommunity('android', 'AP00500004')
									break;
								case 'AP00500005':
									this.$common.inAppWebviewCommunity('android', 'AP00500005')
									break;
								case 'AP00500006':
									this.$common.inAppWebviewCommunity('android', 'AP00500006')
									break;
							}

							this.is_version = true

						}catch (e) {
							throw result.data.update_message
						}
					}
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				this.is_version_match = true
			}
		}

		, setSessionToken: async function(token){
			let t = await this.$encodeStorage.getSessionCertfc()

			if(t){
				t.session_token = token
				await this.$encodeStorage.setSessionToken(token)
				console.log('setSessionToken', t)
				await this.$encodeStorage.setSessionCertfc(t)
			}
		}

		, appPushNotify: function(){
			try {
				console.log('window.notify')
				this.is_notify = true
				let at = this.$encodeStorage.getSessionAt()
				if (at) {
					this.$router.push({name: 'mafia118'})
				} else {
					this.postAutoLogin()
				}
			}catch (e){
				console.log(e)
			}
		}
		, setBoardConfigSort: async function(items_board_config){
			items_board_config.sort(function(a, b){
				let A = a.board_bookmark_fg
				let B = b.board_bookmark_fg

				if(A == 'Y' && B == 'N'){
					return -1
				}
				if(A == 'N' && B == 'Y'){
					return 1
				}

				return 0
			})
			items_board_config.sort(function(a, b){
				let A = a.board_type_code
				let B = b.board_type_code

				if(A < B){
					return -1
				}
				if(A > B){
					return 1
				}

				return 0
			})
		}

		, to: async function ({name, path, params, query = {}, not_query, hash, type}) {
			// console.log('to params', name, this.$route.name)

			query.a = Math.random()

			let router = this.$router.resolve({ name: name, path: path, params: params, query: query, hash: hash ? '#' + hash : ''})
			// console.log('router', router)
			if (!not_query) {
				localStorage.removeItem('query')
				this.query = {}
			} else {
				// this.setQuery()
			}

			if(type == 'href'){
				document.location.href = router.href
			}else{
				await this.$router.push({ path: router.href })
			}

			/*
			if (name == this.$route.name) {

				console.log('same!! to duplicate page ')
				if (params) {
					params.name = name
				} else {
					params = {
						name: name
					}
				}

				await this.$router.push({name: 'DuplicatePage', params: params, query: query})
			} else {
				// console.log(' name != ' + name)
				await this.$router.push({name: name, params: params, query: query}).catch(function (e) {
					console.log(e)
				})
			}

			 */
		}

		, init: async function(){
			try{
				// this.$common.inAppWebviewCommunity('android', 'app_version')

				window.addEventListener('flutterInAppWebViewPlatformReady', () => {
					this.$common.inAppWebviewCommunity('android', 'app_version')
				})

				window.app_version = (version) => {
					console.log('window.app_version')
					this.$encodeStorage.setSessionVersion(version)
				}
			}catch{
				console.log('catch app_version')
			}

			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				// When ready, auto-scroll 1px to hide URL bar
				window.addEventListener("load", function () {

					// Set a timeout...
					setTimeout(function () {
						// Hide the address bar!
						window.scrollTo(0, 1);
					}, 100);
				});
			}
			let at = this.$encodeStorage.getSessionAt()
			let session_token = this.$encodeStorage.getSessionToken()

			// 앱 푸시 노티
			window.notify = () => {
				this.appPushNotify(at)
			}

			if(at && session_token){
				await this.setUser(at)
				//this.is_view = true
			}else{
				let login_info = this.$encodeStorage.getItem('login_info')
				console.log('login_info', login_info)
				if (login_info) {
					this.is_view = true
					setTimeout(() => {
						this.$bus.$emit('onLogin', { type: true})
					}, 100)
				}else{
					await this.getDeviceInfo()
				}
			}

			this.$bus.$on('onVersion', (e) => {
				console.log('onVersion', e)
				this.is_version = true
				this.$set(this.item_app_info, 'app_execu_code', 'AP00500004')
				this.$set(this.item_app_info, 'message', e.message)
			})

			this.$bus.$on('onError', (error) => {
				console.log('onError')
				this.is_error = true
				this.item_error = error
			})

			this.$bus.$on('onUnauthorized', () => {
				console.log('onUnauthorized')
				if(this.user.member_number){
					this.$bus.$emit('onPin', 'pin_login')
				}
			})

			this.$bus.$on('getUserInfo', async (user) => {
				await this.getUserInfo(user)
			})
			this.$bus.$on('getDeviceToken', async () => {
				await this.getDeviceInfo()
			})

			this.$bus.$on('setUser', async (user) => {
				await this.setUser(user)
			})

			this.$bus.$on('updateUser', async ({key, value}) => {
				await this.updateUser({key, value})
			})

			this.$bus.$on('getCommonCode', async (group_id) => {
				return await this.getCommonCode(group_id)
			})

			this.$bus.$on('setBoardConfigSort', (items_board_config) => {
				return this.setBoardConfigSort(items_board_config)
			})

			this.$bus.$on('to', (to) => {
				// console.log('to on bus', this.$route.name)
				this.to(to)
			})

			this.$bus.$on('not_pause', () => {
				console.log('not pause')
				try{
					this.$common.inAppWebviewCommunity('android', 'not_pause');
				}catch (e){
					console.log(e)
				}
			})

			this.$bus.$on('log', ({ e }) => {
				console.log(e)
			})

			this.$bus.$on('logout', async () => {
				console.log('in logout')
				await this.$encodeStorage.removeItem('login_info')
				await this.$encodeStorage.removeSessionCertfc()
				await this.$encodeStorage.removeSessionAt()
				await this.$encodeStorage.removeItem('pin_token')
				this.user = {}
				sessionStorage.clear()

				await this.$bus.$emit('to', { name: 'main'})
			})
		}
	}
	, async created() {

		setTimeout( () => {
			this.init()
		}, 300)
		// develop
	}
};
</script>

<style>
body {
	overscroll-behavior-y: none;
}
.cartel_write { right: 0!important; bottom: 0 !important;}
.cartel_write_btn { width: 6rem !important; height: 6rem !important;}
.layer-popup { position: fixed; left: 0; top: 0; width: 100%; height: 100%; overflow: auto; background-color: white; z-index: 9998 }

.color-white { color: white !important;}
.color-gray { color: gray !important;}
.color-gray01 { color: var(--gray01) !important;}
.color-gray02 { color: var(--gray02) !important;}
.color-gray-dark02 { color: var(--dark-Gray02) !important;}
.color-333 { color: #333 !important;}
.color-blue02 { color: var(--blue02) !important;}

.color-333 { color: #333 !important;}
.color-eee { color: #eee !important;}
.color-bbb { color: #bbb !important;}
.color-ddd { color: #ddd !important;}

.color-red { color: var(--red) !important;}
.color-blue { color: var(--blue01) !important;}
.color-blue-mafia { color: var(--mafia-Blue) !important;}
.color-gray-light { color: rgba(255, 255, 255, 0.2) !important;}

.pa-5 { padding: 5px !important;}
.pa-10 { padding: 10px !important;}
.pa-20 { padding: 20px !important;}
.pa-30 { padding: 30px;}
.pa-40 { padding: 40px;}
.pa-50 { padding: 50px;}

.pa-3-10 { padding: 3px 10px;}
.pa-5-10 { padding: 5px 10px;}
.pa-5-15 { padding: 5px 15px;}
.pa-10-5 { padding: 10px 5px;}
.pa-10-15 { padding: 10px 15px;}
.pa-10-20 { padding: 10px 20px !important;}
.pa-10-30 { padding: 10px 30px;}
.pa-13-10 { padding: 13px 10px;}
.pa-13-20 { padding: 13px 20px;}
.pa-15-10 { padding: 15px 10px;}
.pa-15-20 { padding: 15px 20px;}
.pa-20-5 { padding: 20px 5px;}
.pa-20-10 { padding: 20px 10px;}
.pa-30-10 { padding: 30px 10px;}
.pa-30-20 { padding: 30px 20px;}
.pa-10-50 { padding: 10px 50px}
.pa-shorts { padding: 10px 20px 90px}

.pl-10 { padding-left: 10px}
.pl-20 { padding-left: 20px}
.pl-30 { padding-left: 30px}

.pt-150 {padding-top:  150px !important; }

.pb-10 { padding-bottom: 10px}
.pb-20 { padding-bottom: 20px}
.pb-30 { padding-bottom: 30px}
.pb-40 { padding-bottom: 40px}
.pb-50 { padding-bottom: 50px}
.pb-100 { padding-bottom: 100px}

.ptb-5 { padding-top: 5px; padding-bottom: 5px !important;}
.ptb-10 { padding-top: 10px; padding-bottom: 10px !important;}
.ptb-20 { padding-top: 20px; padding-bottom: 20px !important;}
.ptb-30 { padding-top: 30px; padding-bottom: 30px !important;}
.ptb-40 { padding-top: 40px; padding-bottom: 40px !important;}
.ptb-50 { padding-top: 50px; padding-bottom: 50px !important;}

.prl-5 { padding-right: 5px !important; padding-left: 5px !important;}
.prl-10 { padding-right: 10px !important; padding-left: 10px !important;}
.prl-15 { padding-right: 15px !important; padding-left: 15px !important;}
.prl-20 { padding-right: 20px !important; padding-left: 20px !important;}
.prl-30 { padding: 0 30px !important;}


.ma-auto { margin: 0 auto;}

.mt-5 { margin-top: 5px !important;}
.mt-10 { margin-top: 10px !important;}
.mt-15 { margin-top: 15px !important;}
.mt-20 { margin-top: 20px !important;}
.mt-30 { margin-top: 30px !important;}
.mt-40 { margin-top: 40px !important;}
.mt-50 { margin-top: 50px !important;}
.mt-80 { margin-top: 80px !important;}
.mt-150 { margin-top: 150px !important;}

.mt-auto { margin-top: auto !important;}

.mtb-5 { margin-top: 5px; margin-bottom: 5px;}

.mb-5 { margin-bottom: 5px !important;}
.mb-10 { margin-bottom: 10px !important;}
.mb-20 { margin-bottom: 20px !important;}
.mb-30 { margin-bottom: 30px !important;}
.mb-40 { margin-bottom: 40px !important;}
.mb-50 { margin-bottom: 50px !important;}
.mb-70 { margin-bottom: 70px !important;}
.mb-80 { margin-bottom: 80px !important;}
.mb-150 { margin-bottom: 150px !important;}

.mtb-20 { margin-top: 20px; margin-bottom: 20px;}
.mtb-30 { margin-top: 30px; margin-bottom: 30px;}
.mtb-40 { margin-top: 40px; margin-bottom: 40px;}

.mr-5 { margin-right: 5px}
.mr-10 { margin-right: 10px}
.mr-15 { margin-right: 15px}
.mr-20 { margin-right: 20px}

.ml-5 { margin-left: 5px}
.ml-10 { margin-left: 10px}
.ml-15 { margin-left: 15px}
.ml-20 { margin-left: 20px}
.ml-30 { margin-left: 30px}
.ml-40 { margin-left: 40px}
.ml-50 { margin-left: 50px}

.indent-10 { text-indent: 10px}

/* layout */
.bottom-main { margin-top: auto; position: relative; padding: 10px; background-color: black; opacity: 0.7}

.flex-wrap { flex-wrap: wrap;}
.flex-nowrap { flex-wrap: nowrap;}

.justify-center { display: flex; justify-content: center}
.justify-space-around { display: flex; justify-content: space-around}
.justify-space-between { display: flex !important; justify-content: space-between }
.justify-start { display: flex; justify-content: flex-start}
.justify-end { display: flex; justify-content: flex-end}

.gap-10 { gap: 10px}
.gap-15 { gap: 15px}
.gap-20 { gap: 20px}

.flex-column { display: flex; flex-direction: column;}
.flex-column-wrap { display: flex; flex-direction: column; flex-wrap: wrap;}

.flex-row { display: flex; flex-direction: row}
.flex-row-wrap { display: flex; flex-direction: row; flex-wrap: wrap}

.flex-0 { flex: 0}
.flex-1 { flex: 1}
.flex-2 { flex: 2}
.flex-3 { flex: 3}
.flex-4 { flex: 4}
.flex-5 { flex: 5}


.float-right { float: right;}

.display-none{display:none;}
.display-block{display:block;}

.overflow-y-auto { overflow-y: auto}
.overflow-x-auto { overflow-y: hidden; overflow-x: auto;}
.overflow-hidden { overflow: hidden}

.full-height { height: 100%;}
.full-width { width: 100%}
.height-auto { height: auto !important;}
.height-200 { height: 200px !important;}



.size-px-8 { font-size: 8px !important}
.size-px-9 { font-size: 9px !important}
.size-px-10 { font-size: 10px !important;}
.size-px-11 { font-size: 11px !important;}
.size-px-12 { font-size: 12px !important;}
.size-px-13 { font-size: 13px !important;}
.size-px-14 { font-size: 14px !important;}
.size-px-15 { font-size: 15px !important;}
.size-px-16 { font-size: 16px !important;}
.size-px-17 { font-size: 17px !important;}
.size-px-18 { font-size: 18px !important;}
.size-px-19 { font-size: 19px !important;}
.size-px-20 { font-size: 20px !important;}
.size-px-22 { font-size: 22px !important;}
.size-px-24 { font-size: 24px !important;}
.size-px-28 { font-size: 28px !important;}
.size-px-32 { font-size: 32px !important;}
.size-px-36 { font-size: 36px !important;}
.size-px-48 { font-size: 48px !important;}

.size-em-05 { font-size: 0.5em;}
.size-em-06 { font-size: 0.6em;}
.size-em-07 { font-size: 0.7em;}
.size-em-08 { font-size: 0.8em;}
.size-em-09 { font-size: 0.9em;}
.size-em-10 { font-size: 1.0em;}
.size-em-11 { font-size: 1.1em;}
.size-em-12 { font-size: 1.2em;}
.size-em-13 { font-size: 1.3em;}
.size-em-14 { font-size: 1.4em;}
.size-em-15 { font-size: 1.5em;}
.size-em-16 { font-size: 1.6em;}
.size-em-17 { font-size: 1.7em;}
.size-em-18 { font-size: 1.8em;}
.size-em-19 { font-size: 1.9em;}
.size-em-20 { font-size: 2.0em;}

.btn {

}

.btn-primary-outline {
	border: 1px solid #ddd
}

.btn-primary-outline.on {
	border: 1px solid var(--blue01); color: var(--blue01);
}

.input-box { border: 1px solid #ddd; width: 100%; border-radius: 20px !important; padding: 10px 20px; font-size: 14px}
.box { border: 1px solid #ddd}

.radius-t-20 { border-radius: 20px 20px 0 0;}
.radius-l-20 { border-radius: 20px 0 0 20px}
.radius-r-20 { border-radius: 0 20px 20px 0}

.width-auto { width: auto !important; max-width: 60px; text-align: center}

.text-center { text-align: center !important;}
.text-right { text-align: right;}

.none {}

.items-center { align-items: center}



.label { border-radius: 20px; font-size: 12px; padding: 5px 10px}
.label-primary { background-color: var(--blue01); color: white}

.h6 { font-size: 14px; font-weight: 400; color: #333; letter-spacing: -1px}
label { font-size: 14px}

.bg-none { background-color: unset !important;}
.bg-black { background-color: black !important;}
.bg-black-half { background-color: rgba(0, 0, 0, 0.5) !important;}
.bg-white { background-color: white}
.bg-white-half { background-color: rgba(255, 255, 255, 0.5)}
.bg-gray { background-color: #eee}
.bg-gray-light { background-color: var(--bg-Gray01)}
.bg-red { background-color: var(--red)}
.bg-red-light { background-color: var(--orange)}
.bg-dark-gray { background-color: var(--dark-Gray01)}
.bg-gray02 { background-color: var(--bg-Gray02)}
.bg-blue02 { background-color: var(--bg-Blue02)}

.box { border: 1px solid #bbb;}
.radius-5 { border-radius: 5px; overflow: hidden}
.radius-10 { border-radius: 10px; overflow: hidden}
.radius-100 { border-radius: 100%; overflow: hidden}
.radius-15 { border-radius: 15px !important; overflow: hidden}
.radius-20 { border-radius: 20px !important; overflow: hidden}
.input-box { width: 100%; padding: 10px; border: 1px solid #ddd; border-radius: 10px}
.input-box-inline { display: inline-block; padding: 10px; border: 1px solid #ddd; vertical-align: middle}

.width-100 { width: 100%}

.inline-block { display: inline-block}


.color-red { color: var(--red) !important;}
.color-blue { color: var(--blue01) !important;}
.color-blue-mafia { color: var(--mafia-Blue) !important;}
.color-gray-light { color: rgba(255, 255, 255, 0.2) !important;}

.font-weight-bold { font-weight: bold}
.font-weight-300 { font-weight: 300}
.font-weight-400 { font-weight: 400}
.font-weight-500 { font-weight: 500}
.font-weight-600 { font-weight: 600}
.font-weight-700 { font-weight: 700}


.top-line { border-top: 1px solid #ddd}
.top-line-tv { border-top: 1px solid rgba(255, 255, 255, 0.1)}
.under-line { border-bottom: 1px solid #ddd;}
.under-line-not-last:not(:last-child) { border-bottom: 1px solid #ddd }

.under-line.blue { border-color: var(--blue01);  border-width: 2px; color: var(--blue01)}

.text-under-line { text-decoration: underline}

.bg-blue01 { background-color: var(--bg-Blue01); }
.bg-blue { background-color: var(--blue01); }
.bg-red { background-color: var(--red)}
.bg-blue-mafia { background-color: var(--blue02); }
.bg-blue-02 { background-color: var(--bg-Blue02, #181A39); }
.bg-heart-only { background-image: url('~@/assets/image/icon_heart.svg'); background-position: left center; background-size: 25px}
.bg-won { background-image: url('~@/assets/image/icon_won.svg'); background-position: left center; background-size: 15px }
.bg-heart { background-color: rgba(220, 80, 92, 0.05); background-image: url('~@/assets/image/icon_heart.svg'); background-position: 20px center;}
.bg-heart-pink { background-color: rgba(220, 80, 92, 0.05);}

.label { display: inline-block !important; padding: 3px 10px; border-radius: 20px !important; font-size: 12px !important; letter-spacing: 1px !important; }
.label-default { background-color: #bbb !important; color: white !important;}
.label-progress { background-color: var(--blue01); color: white}
.label-success { background-color: #1EA76E; color: white;}
.label-warning { background-color: #f44336; color: white;}
.label-danger { background-color: #FC4545; color: white !important;}
.label-cancel { background-color: #f44336; color: white !important;}
.label-notice { background-color: #DC505C; color: white}
.label-primary { background-color: var(--blue01) !important; color: white !important;}

.btn_left { border-radius: 5rem 0 0 5rem}
.btn_right { border-radius: 0 5rem 5rem 0}

.text-shadow { text-shadow: 1px 1px 1px black;}

.line-height-160 { line-height: 200%}


.img-box-100 { display: inline-block; width: 100px; height: 100px}
.img-box-64 { display: inline-block; width: 64px; height: 64px;}
.img-box-30 { display: inline-block; width: 30px; height: 30px;}
.object-cover { object-fit: cover; width: 100%; height: 100%}
.box-input { border: 1px solid #ddd; padding: 10px 20px; }
input { outline: none}

.blur {
	filter: blur(10px);
	-webkit-filter: blur(10px);
	background-position: center center;
	background-size: 25px;
}

.word-break {
	word-break: break-all;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	overflow: hidden;
	whiter-space: nowrap;
	word-wrap: normal;
}

.word-break-all {
	word-break: break-all;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
}



.width-10 { width: 10% !important;}
.width-15 { width: 15% }
.width-20 { width: 20% !important;}
.width-25 { width: 25% }
.width-30 { width: 30% }
.width-35 { width: 35% }
.width-40 { width: 40% }
.width-45 { width: 45% }
.width-50 { width: 50% !important;}
.width-55 { width: 55% }
.width-60 { width: 60% }
.width-65 { width: 65% }
.width-70 { width: 70% }
.width-75 { width: 75% }
.width-80 { width: 80% }
.width-85 { width: 85% }
.width-90 { width: 90% }
.width-95 { width: 95% }
.width-100 { width: 100% }

.icon-40 { width: 40px;}
.icon-64 { width: 64px;}
.icon-100 { width: 100px; height: 100px;}

.square-260 { width: 260px; height: 260px;}
.square-100 { width: 100px; height: 100px;}
.square-82 { width: 82px; height: 82px;}
.square-80 { width: 80px; height: 80px;}
.square-64 { width: 64px !important; height: 64px; flex-basis: 64px}
.square-48 { width: 48px; height: 48px;}
.square-38 { width: 38px; height: 38px;}


input:focus
, select:focus { }

.justify-space-between-in > div {
	display: flex !important; justify-content: space-between; align-items: center;
	gap: 10px;
}

.not-align-center {
	align-items: unset !important;
}

.slide-up {
	transform: translateY(0%) !important;
	transition: all .5s .1s;
}

.slide-down {
	transform: translateY(100%) !important;
	transition: all .5s .1s;
}



.position-relative { position: relative }
.position-relative-full {left: 0; top: 0; width: 100%; height: 100%;}
.position-fixed { position: fixed !important; left: unset; top: unset; height: unset !important;}
.position-fixed-full { position: fixed; left: 0; top: 0; width: 100%; height: 100%;}
.position-absolute { position: absolute !important;}
.position-absolute-full { position: absolute !important; left: 0; top: 0; width: 100%; height: 100%;}
.position-absolute.icon-menu-down { right: 10px; top: 12px}
.position-badge-30 { position: absolute !important; top: 30px; left: 30px}


.bg-layer { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.3}


.hashtag { font-weight: normal !important;}
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }

.bright_radio input[type="checkbox"] { overflow: hidden; position: absolute; margin: 0; padding: 0; clip: rect(0, 0, 0, 0); width: 1px; height: 1px; border: 0; }
.bright_radio input[type="checkbox"] + label { padding-left: 34px; line-height: 24px; font-size: 14px; }
.bright_radio input[type="checkbox"] + label:before { content: ''; position: absolute; top: 0; left: 0; width: 24px; height: 24px; border: 1px solid var(--light-Gray02); box-sizing: border-box;}
.bright_radio input[type="checkbox"]:checked  + label:before { border: 1px solid var(--blue02);}
.bright_radio input[type="checkbox"]:checked  + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--blue02); }
.bright_radio input[type="checkbox"]:disabled + label:before { border: 1px solid var(--light-Gray02); }
.bright_radio input[type="checkbox"]:disabled + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--light-Gray02); border-radius: 12px; }
.bg-gray { background-color: #eee}
.line-white-03 { border-color: var(--line-white03) !important;}


.item_wrap.on,
.comment_view.on { background-color: #eee}


.popup > .half_layer_wrap_inner {
	transform: translateY(100%) ;
}

box-shadow-top { box-shadow: 0px 3px 6px black}
.box-shadow-inset { box-shadow: 0px 0px 2px 1px #bbb inset}
.box-shadow { box-shadow: 1px 1px 3px 1px #bbb}

.white-space-nowrap {
	white-space: nowrap;
}

.ellipsis {text-overflow: ellipsis}

.nowrap {
	white-space: nowrap;
}

html {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}


.bg-tv {
	background-color: black !important;
}

.bg-tv strong {
	color: white !important;
}

.bg-tv .text_area {
	color: white !important;
}

.bg-tv .comment_count{
	color: var(--gray02)
}

.bg-tv .post_conunt_view
, .bg-tv .post_comment_main_view {
	border-top-color: rgba(255, 255, 255, 0.1) !important;
}
.bg-tv .post_comment_main_view p{
	color: white;
}

.bg-tv .write_main {
	background-color: black;
	border-top: 1px solid rgba(255, 255, 255, 0.1)
}

.bg-tv .bg-tv-line {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.bg-tv .top.top_mafi {
	background-color: black !important;
}

.bg-tv .more_viewface{
	background-color: black;
}

.bg-tv .more_viewface .v-icon {
	color: white !important;
}

.bg-tv textarea {
	color: white !important;
}

.bg-tv .comment_view.on{
	background-color: #888 !important;
}
.bg-tv .comment_view.on .time{
	color: #bbb !important;
}

.bg-tv .comment_layout {
	border-top-color: rgba(255, 255, 255, 0.1) !important;
}

.bg-tv .hashtag {
	background-color: black; padding-right: 1px;
}

.bg-tv .under-line {
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.video_none {
	background-image: url('~@/assets/image/@main_none.png') no-repeat top center / 15rem 12rem;
}

.text-right { text-align: right !important; }
.text-center { text-align: center; }
.text-left { text-align: left !important; }
</style>