

export const date = {

	getToday: function(point){
		let date = new Date()
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')

		return fullDate
	}
	, getNow: function(point){
		let date = new Date()
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()
		let h = date.getHours()
		let m = date.getMinutes()
		let s = date.getSeconds()

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')
		let fullTime = h + ':' + m + ':' + s

		return fullDate + ' ' + fullTime
	}
	,init: function(date, point){

		if(!date) {
			date = new Date()
		}else{
			date = new Date(date)
		}

		let year = date.getFullYear()
		let month = ('0' + (date.getMonth() + 1)).slice('-2')
		let day = ('0' + date.getDate()).slice('-2')

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let fullDate = year + cPoint+ month + cPoint + day

		let startDate = new Date(date.getFullYear(), 0, 1);
		let days = Math.floor((date - startDate) /
			(24 * 60 * 60 * 1000));

		let weeks = Math.ceil(days / 7);
		if(weeks == 0){
			weeks = 1
		}
		let fullWeeks = year + cPoint + weeks

		let fullMonth = year + cPoint + month

		return { year: year, month: month, weeks: weeks, day: day, fullDate: fullDate, fullWeeks: fullWeeks, fullMonth:fullMonth  }
	}
	,getSearchBaseDate: function(point){

		let date = new Date()

		let year = date.getFullYear()
		let month = date.getMonth() - 3
		let day = date.getDate() + 1

		date = new Date(year, month, day)

		let cPoint = ''
		if(point){
			cPoint = point
		}

		year = date.getFullYear()
		month = ('0' + (date.getMonth() + 1)).slice('-2')
		day = ('0' + date.getDate()).slice('-2')

		let fullDate = year + cPoint + month + cPoint + day

		return fullDate
	}
	,getWeeklyDate: function(dates, point){
		let date = new Date()

		if(dates) {
			dates = dates.replaceAll('-', '')
			date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))
		}
		let year = date.getFullYear()
		let month = date.getMonth()
		let day = date.getDate()
		let thisDay = date.getDay()

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let start = new Date(year, month, day - thisDay)
		let start_year = start.getFullYear()
		let start_month = start.getMonth() + 1
		let start_day = start.getDate()
		let start_fullDate = start_year + cPoint + ('0' + start_month).slice('-2') + cPoint + ('0' + start_day).slice('-2')

		let end = new Date(year, month, day + (6 - thisDay))
		let end_year = end.getFullYear()
		let end_month = end.getMonth() + 1
		let end_day = end.getDate()
		let end_fullDate = end_year + cPoint + ('0' + end_month).slice('-2') + cPoint + ('0' + end_day).slice('-2')

		let weeks = this.getWeek(date);
		let week = weeks[1]
		let fullWeeks = weeks[0] + '-' + week

		return { start: start_fullDate, end: end_fullDate, weeks: fullWeeks, fullWeeks: weeks}
	}

	,getMonthlyDate2: function(input, point){

		let date = new Date(input)
		let year = date.getFullYear()
		let month = date.getMonth()

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let start = new Date(year, month, 1)
		let start_year = start.getFullYear()
		let start_month = start.getMonth() + 1
		let start_day = start.getDate()
		let start_fullDate = start_year + cPoint + ('0' + start_month).slice('-2') + cPoint + ('0' + start_day).slice('-2')

		let end = new Date(year, month)
		let end_year = end.getFullYear()
		let end_month = end.getMonth() + 1
		let end_day = end.getDate()

		end = new Date(end_year, end_month, end_day - 1)
		end_year = end.getFullYear()
		end_month = end.getMonth() + 1
		end_day = end.getDate()
		let end_fullDate = end_year + cPoint + ('0' + end_month).slice('-2') + cPoint + ('0' + end_day).slice('-2')

		return { start: start_fullDate, end: end_fullDate}
	}

	,getMonthlyDate: function(dates, point){

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let date = new Date()
		if(dates){
			dates = dates.replaceAll('-', '')
			date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))
		}
		let year = date.getFullYear()
		let month = date.getMonth()

		let start = new Date(year, month, 1)
		let start_year = start.getFullYear()
		let start_month = start.getMonth() + 1
		let start_day = start.getDate()
		let start_fullDate = start_year + cPoint + ('0' + start_month).slice('-2') + cPoint +('0' + start_day).slice('-2')

		let end = new Date(year, month + 1)
		let end_year = end.getFullYear()
		let end_month = end.getMonth()
		let end_day = end.getDate()

		end = new Date(end_year, end_month, end_day - 1)
		end_year = end.getFullYear()
		end_month = end.getMonth() + 1
		end_day = end.getDate()
		let end_fullDate = end_year + cPoint +('0' + end_month).slice('-2') + cPoint +('0' + end_day).slice('-2')

		return { start: start_fullDate, end: end_fullDate}
	}
	,getPrevMonth: function(dates, prev, point){

		let then = 2
		if(prev){
			then += prev
		}

		let cPoint = ''
		if(point){
			cPoint = point
		}

		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - then, dates.substring(6, 8))

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()
		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')

		let fullMonth = year + cPoint + ('0' + month).slice('-2')

		return { year: year, month: month, day: day, fullDate: fullDate, fullMonth: fullMonth }
	}
	,getNextMonth: function(dates, next, point){

		let then = 0
		if(next){
			then += next
		}

		let cPoint = ''
		if(point){
			cPoint = point
		}

		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), Number(dates.substring(4, 6)) + then, dates.substring(6, 8))

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')

		let fullMonth = year + cPoint + ('0' + month).slice('-2')

		return { year: year, month: month, day: day, fullDate: fullDate, fullMonth: fullMonth }
	}
	,getPrevWeelkyDate: function(dates, point){

		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8) - 1)

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		return this.getWeeklyDate(year + ('0' + month).slice('-2') + ('0' + day).slice('-2'), point)
	}

	,getNextWeelkyDate: function(dates, point){

		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, Number(dates.substring(6, 8)) + 1)

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		return this.getWeeklyDate(year + ('0' + month).slice('-2') + ('0' + day).slice('-2'), point)
	}
	,getYearlyDate: function(dates){
		let date = new Date()

		if(dates) {
			dates = dates.replaceAll('-', '')
			date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))
		}

		let year = date.getFullYear()
		let month = date.getMonth()
		let day = date.getDate()

		let start = new Date(year, month, day)
		let start_year = start.getFullYear()
		let start_month = 1
		let start_day = 1
		let start_fullDate = start_year + ('0' + start_month).slice('-2') + ('0' + start_day).slice('-2')

		let end = new Date(year, month, day)
		let end_year = end.getFullYear()
		let end_month = 12
		let end_day = 31
		let end_fullDate = end_year + ('0' + end_month).slice('-2') + ('0' + end_day).slice('-2')

		return { start: start_fullDate, end: end_fullDate}
	}
	,getPrevYearlyDate: function(dates){
		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4) - 1, dates.substring(4, 6) - 1, dates.substring(6, 8))

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		return this.getYearlyDate(year + ('0' + month).slice('-2') + ('0' + day).slice('-2'))
	}
	,getNextYearlyDate: function(dates){

		dates = dates.replaceAll('-', '')
		let date = new Date(Number(dates.substring(0, 4)) + 1, dates.substring(4, 6) - 1, Number(dates.substring(6, 8)) + 1)

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		return this.getYearlyDate(year + ('0' + month).slice('-2') + ('0' + day).slice('-2'))
	}
	// 하루 전날짜 가져오기
	,getPrevDay: function(dates, point, add = 1){
		if(!dates){
			return ''
		}
		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))
		date.setDate(date.getDate() - (add !== undefined ? Number(add) : 1));
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')

		return fullDate
	}
	// 하루 다음날짜 가져오기
	,getNextDay: function(dates, point, add = 1){

		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))
		date.setDate(date.getDate() + (add !== undefined ? Number(add) : 1));
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')

		return fullDate
	}
	// 20210705 포멧을 2021년 07월 05일 포멧으로 변경
	,getHanguelDate: function(dates){

		if(!dates){
			return ''
		}
		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()
		let fullDate = year + '년 ' + ('0' + month).slice('-2') + '월 ' + ('0' + day).slice('-2') + '일'

		return fullDate
	}
	// 20210705 포멧을 2021-07-05 포멧으로 변경
	,getSearchDate: function(dates, point){
		if(!dates){
			return ''
		}

		dates = dates.replaceAll('-', '')
		let cPoint = ''
		if(point){
			cPoint = point
		}
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()
		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')

		return fullDate
	}

	,getLastWeekDate: function(dates, point){
		if(!dates){
			return ''
		}
		dates = dates.replaceAll('-', '')
		let date = new Date(dates.substring(0, 4), dates.substring(4, 6) - 1, dates.substring(6, 8))
		date.setDate(date.getDate() - 6);
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		let cPoint = ''
		if(point){
			cPoint = point
		}

		let fullDate = year + cPoint + ('0' + month).slice('-2') + cPoint + ('0' + day).slice('-2')

		return fullDate
	}

	, getWeeks: function(date){
		let d = date
		// Copy date so don't modify original
		d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
		// Set to nearest Thursday: current date + 4 - current day number
		// Make Sunday's day number 7
		d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
		// Get first day of year
		let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
		// Calculate full weeks to nearest Thursday
		let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
		// Return array of year and week number
		//console.log('getWeeks d ', d, d.getUTCFullYear(), weekNo)
		return [d.getUTCFullYear(), weekNo];

	}


	, getWeek: function(date) {
		let d = new Date(date);
		//let dayNum = d.getDay();
		//d.setDate(d.getDate() + 4 - dayNum);
		let yearStart = new Date(d.getFullYear(),0,1);
		return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
	}

// Returns the four-digit year corresponding to the ISO week of the date.
	, getWeekYea: function() {
		let date = new Date(this.getTime());
		date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
		return date.getFullYear();
	}

	,getTimeStory: function(start, end) {

		if(!start){
			return ''
		}
		start = start.replace('T', ' ').replace('Z', '').replaceAll('-', '/')

		let begin = Math.floor(+new Date(start) / 1000)

		let finish = Math.floor(+new Date() / 1000)

		let t = ''

		if (end) {
			begin = Math.floor(+new Date(start) / 1000)
			finish = Math.floor(+new Date(end) / 1000)
		}

		t = finish - begin


		if(t < 5){
			return '조금전'
		}else if(t < 60){
			return t + '초전'
		}else if(t < 3600){
			return Math.floor(t / 60) + '분전'
		}else if(t < 86400){ // 1일 이내
			return Math.floor(t / 3600) + '시간전'
		}else if(t < 604800){ // 1주 이내
			return Math.floor(t / 86400) + '일전'
		}else if(t < 2628000){ // 1달 이내
			return Math.floor(t / 604800) + '주전'
		}else if(t < 31536000){ // 1년 이내
			return Math.floor(t / 2628000) + '달전'
		}else{
			return '오래전'
		}
	}

	, getTimeItems: function(){
		let t = []

		t.push(
			[
				{ name: '오전', value: 'am'}
				, { name: '오후', value: 'pm'}
			]
		)

		let hours = []
		for(let i = 1; i <= 12; i++){
			hours.push({ name: ('0' + i).slice(-2), value: ('0' + i).slice(-2)})
		}

		t.push(hours)

		let mins = []

		for(let i = 0; i < 60; i++){
			mins.push({ name: ('0' + i).slice(-2), value: ('0' + i).slice(-2)})
		}

		t.push(mins)

		return t
	}

	, transVideoTime: function(time){
		console.log(time)
		let t = ['00', '00', '00']
		if(time < 60){
			t[2] = time
		}else{
			t[2] = time%60
			let d = Math.floor(time/60)
			if(d < 60){
				t[1] = d
			}else{
				t[1] = d%60
				let dd = Math.floor(d/60)
				t[0] = dd
			}
		}

		return ('00' + t[0]).slice(-2) + ':' + ('00' + t[1]).slice(-2) + ':' + ('00' + t[2]).slice(-2)
	}
	, transTime: function(time){

		let t = ''
		let d = time
		let type = d[0]
		let h = ('00' + d[1]).slice(-2)
		let m = ('00' + d[2]).slice(-2)

		if(type == 'pm'){
			h = Number(h) + 12
		}
		t = h + ':' + m + ':00'

		return t
	}

	, transTimeEdit: function(time){
		console.log('transTimeEdit', time)

		let t = ''
		if(!time){
			return t
		}
		let d = time.split(':')
		let dd = '오전'
		if(d[0] > 12){
			dd = '오후'
			d[0] -= 12
		}
		let h = ('00' + d[0]).slice(-2)
		let m = ('00' + d[1]).slice(-2)

		t = dd + ' ' + h + ':' + m

		return t
	}

	, compare: function(time){
		let t = true
		let now = this.getNow('-')

		if(now > time){
			t = false
		}
		return t
	}
	, now: function(){
		let date = new Date();

		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		let H = date.getHours();
		let m = date.getMinutes();
		let s = date.getSeconds();

		let now = year + '-' + ('00' + month).slice(-2) + '-' + ('00' + day).slice(-2) + ' ' + ('00' + H).slice(-2) + ':' + ('00' + m).slice(-2) + ':' + ('00' + s).slice(-2)
		return now
	}

}