<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4> {{ $language.appLanguage.setUsageLanguage }} </h4>
							</div>
							<div class="text_guide_desc pt-15">
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap flex-row justify-space-between">
						<div
							class="btn_area flex-1"
						>
							<button
								@click="setLanguage('kr')"
								class="btn_l btn_fill_blue"
							>한국어</button>
						</div>
						<div
							class="btn_area flex-1 ml-10"
						>
							<button
								@click="setLanguage('en')"
								class="btn_l btn_fill_gray"
							>english</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'appLanguage'
		, methods: {
			setLanguage: function(type){
				localStorage.setItem('language', type)
				this.$bus.$emit('on', true)
				window.location.reload()
			}
		}
	}
</script>