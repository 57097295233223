<template>
	<div class="bg_dark full-height flex-column overflow-y-auto">
		<div
			v-if="is_type"
			class="text-right pa-10"
		>
			<button
				v-if="type != 'result'"
				@click="cancel"
			><v-icon class="color-white">mdi-close</v-icon></button>
		</div>
		<div
			v-else
			class="pa-10"
		>
			<button
				v-if="type != 'result'"
				@click="back"
			><v-icon class="color-white">mdi-chevron-left</v-icon></button>
			<div class="mt-10 progress-bar-base position-relative"><div class="progress-bar-step" :class="'progress-bar-step-' + step"></div></div>
		</div>
		<div class="full-height ">
			<Start
				v-if="type == ''"

				@click="setStep"
			></Start>
			<Login
				v-else-if="type == 'social' || type == 'login' || type == 'join'"
				:user="user"
				:type="type"
				:is_auto="is_auto"

				@click="setStep"
				@cancel="cancel"
			></Login>
			<Agree
				v-else-if="type == 'agree' || type == 'agree2'"
				:agree_type="type"

				@click="setStep"
			></Agree>
			<Profile
				v-else-if="type == 'profile'"
				:params="params"
				@click="setStep"
			></Profile>
			<Fav
				v-else-if="type == 'fav'"
				:params="params"
				@click="setStep"
			></Fav>
			<Cartel
				v-else-if="type == 'cartel'"
				:params="params"
				@click="setStep"
			></Cartel>
			<PIN
				v-else-if="type == 'pin'"

				:options="pin_option"
				:params="params"
				style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999999"

				@click="setStep"
				@cancel="pinCancel"
			></PIN>
			<Result
				v-else-if="type == 'result'"
				:member_number="params.member_number"
				@click="reset"
			></Result>
		</div>
	</div>
</template>

<script>
	import Login from "@/view/V2/Auth/Login";
	import Agree from "@/view/V2/Auth/Agree";
	import Profile from "@/view/V2/Auth/Profile";
	import Start from "@/view/V2/Auth/Start";
	import Fav from "@/view/V2/Auth/Fav";
	import Cartel from "@/view/V2/Auth/Cartel";
	import PIN from "@/view/Auth/mafia007";
	import Result from "@/view/V2/Auth/Result";
	export default {
		name: 'Auth'
		, props: ['user', 'to', 'is_auto']
		, components: {Result, PIN, Cartel, Fav, Start, Profile, Agree, Login},
		data: function(){
			return {
				step: 0
				, type: ''
				, params: ''
				, pin_option: {
					pin_type: 'join'
					, is_can_cancel: true
				}
				, is_login: false
			}
		}
		, computed: {
			is_type: function(){
				let t = true
				switch (this.type){
					case 'profile': case 'fav': case 'cartel':
						t = false
						break;
				}
				return t
			}
			, entry_cartl_number: function(){
				let t = []
				this.params.entry_cartl_number.filter( (item) => {
					t.push(item.cartl_number)
				})

				return t
			}
		}
		, methods: {
			cancel: function(){
				switch (this.type){
					case '0':
						break;
					default:
						this.$emit('cancel')
						break;
				}
			}
			, back: function(){

				switch (this.type){
					case 'cartel':
					case 'fav':
					case 'profile':
						this.type = 'login'
						break;
					default:
						break;
				}

				if(this.step > 0){
					this.step--
				}else{
					this.step = 0
					this.type = 'login'
				}
			}
			, setStep: function({step, type, params, is_login}){
				console.log('params', params, is_login)
				switch(type) {
					case 'pin_login':
						this.$emit('cancel')
						this.$bus.$emit('onPin', 'pin_login', this.to)
						break;
					case 'login':
						this.type = type
						break;
					default:
						this.type = type
						break;
				}
				if(params){
					this.params = params
					if(params.session_token){
						this.$encodeStorage.setSessionToken(params.session_token)
					}
				}
				this.step = step
				switch (type){
					case 'profile': this.step = 0; break;
					case 'fav': this.step = 1; break;
					case 'cartel': this.step = 2; break;
				}
				this.is_login = is_login
			}
			, reset: function(){
				this.step = 0
				this.type = ''
				this.params = ''
				this.pin_option = {
					pin_type: 'join'
					, is_can_cancel: true
				}
			}
			, pinCancel: function(){
				this.type = 'login'
			}
		}
		, created() {
			if(this.is_auto){
				this.type = 'login'
			}
		}
	}
</script>

<style>
	.progress-bar-base {padding: 2px; background-color: #fff; opacity: 0.3}
	.progress-bar-step { position: absolute; left: 0; top: 0; padding: 2px; background-color: #293097}
	.progress-bar-step-0 { width: 33.33%}
	.progress-bar-step-1 { width: 66.66%}
	.progress-bar-step-2 { width: 100%}
</style>