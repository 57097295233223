export const language_en = {
    base: {
        page_number: 1
        , pagerecnum: 10
    }
    , state: {
        'CA003': [
            {code: 'CA00300001', name: 'Apply For Membership'}
            , {code: 'CA00300002', name: 'Screening to Join'}
            , {code: 'CA00300003', name: 'Unable to Join'}
            , {code: 'CA00300004', name: 'Joining'}
            , {code: 'CA00300005', name: 'Stop'}
            , {code: 'CA00300006', name: 'Withdrawal'}
            , {code: 'CA00300007', name: 'Forced withdrawal'}
        ]
    },

    state_02: {
        CA00300001: 'Apply For Membership',  // {{  $language.state_02.CA00300001 }}
        CA00300002: 'Screening to Join', // {{  $language.state_02.CA00300002 }}
        CA00300003: 'Unable to Join', // {{  $language.state_02.CA00300003 }}
        CA00300004: 'Joining', // {{  $language.state_02.CA00300004 }}
        CA00300005: 'Stop', // {{  $language.state_02.CA00300005 }}
        CA00300006: 'Withdrawal', // {{  $language.state_02.CA00300006 }}
        CA00300007: 'Forced withdrawal', // {{  $language.state_02.CA00300007 }}
    }

    , common: {
        askRemove: 'Are you sure you want to delete?'
        , start: 'Start'
        , cancel_request: 'Cancel'
        , modify: 'Modify'
        , text_tip: 'TIP'
        , text_tip1: 'Cover pictures must be registered separately on the mobile and PC web.'
        , text_tip2: 'Go to the PC web and register additional dedicated cover photos.'
        , text_tip3: 'www.mafinft.com'
        , text_tip4: '* 1920px * 300px more'
        , request: 'Request'
        , buy: 'BUY'
        , credit_card: 'Credit Card'
        , credit_select: 'Credit Select'
        , system_notice_title: 'Checking the system'
        , system_notice_txt01: 'In order to provide more seamless and reliable service to metamafia users, we are checking the system as follows.'
        , system_notice_txt02: 'I ask for your understanding.'
        , system_notice_btn01: 'ban'
        , system_notice_btn02: 'close'
        , not_select: 'No items selected.'
        , success: 'Processed successfully.' // this.$language.common.success
        , fail: 'DB Error!'
        , error: 'System Error!'
        , dummy: 'This is dummy data.'
        , request_to_admin_01: 'If the problem persists, please contact the administrator.'  // {{ $language.common.request_to_admin_01 }}
        , no_search_data: 'No search results found.'  // this.$language.common.no_search_data
        , no_search_member: 'No search user found.'  // this.$language.common.no_search_data
        , no_board_list: 'No search result.'
        , personal: 'Personal'
        , corporation: 'Corp'
        , out: {
            name: 'Forced withdrawal from the cartel'
            , main_txt: 'Would you like to forcibly withdraw the member below from the cartel?'
        }
        , deactivate: {
            name: 'Stop Cartel Activities'
            , main_txt: 'Are you sure you want to suspend the following members from within the cartel?'
        }
        , join_confirm: {
            name: 'Admission authorization'   // {{  $language.common.join_confirm.name }}
            , main_txt: 'Would you like to approve the member below to join the cartel?'
        }
        , join_cancel: {
            name: 'Delete subscription'
            , main_txt: 'Do you want to delete (disapprove) the member below from the cartel?'
        }
        , safe: {
            name: 'Unstopping'
            , main_txt: 'The members below have an in-cartel suspension period. Are you sure you want to release it?'
        }
        , service_info_metamafia: 'MetaMafia Information'
        , app_notice_push: 'App Announcement..'
        , cannot_service_now: 'The Metamafia app is currently not available.'
        , release_new_version: 'A new version has been released.'
        , push_new_update_01: 'There is a new update.'
        , push_new_update_02: 'It is possible after the update.'
        , error_update: 'Update Error'  // {{  $language.common.error_update }}

        , sending: 'Transferring' // {{  $language.common.sending }}
        , complete_send: 'Transmission completed' // {{  $language.common.complete_send }}
        , copy_link: 'Copy Link' // {{  $language.common.copy_link }}
        , transfer_ID: 'Transaction ID' // {{  $language.common.transfer_ID }}
        , multiple_choice: 'Multiple selections' // {{  $language.common.multiple_choice }}
        , startDate: 'Start Date' // {{  $language.common.startDate }}
        , endDate: 'End Date'  // {{  $language.common.endDate }}
        , startTime: 'Start Time'
        , endTime: 'End Time'  // {{  $language.common.endTime }}
        , setEndTime: 'Set an End Time'  // {{  $language.common.setEndTime }}
        , setEndDate_require: 'Please. Set an end date.'  // {{  $language.common.setEndDate_require }}
        , setEndTime_require: 'Please. Set an end Time.'  // {{  $language.common.setEndTime_require }}
        , all: 'All' // {{  $language.common.all }}
        , lookup: 'Lookup' // {{  $language.common.lookup }}
        , register: 'Registration' // {{  $language.common.register }}
        , new_open: 'Registration'
        , re_register: 'Re0Registration' // {{  $language.common.re_register }}
        , next: 'next' // {{  $language.common.next }}
        , save: 'save' // {{  $language.common.save }}
        , open_request: 'Request'
        , signUp: 'Sign-up'  // {{  $language.common.signUp }}
        , signUpComplete: 'Complete Sign Up'
        , title: 'Community dedicated to culture and arts, Mafia Project'  // {{  $language.common.title }}
        , login: 'Login'  // {{  $language.common.login }}
        , ok: 'OK'  // {{  $language.common.ok }}
        , again: 'retry'  // {{  $language.common.again }}
        , cancel: 'cancel'  // {{  $language.common.cancel }}
        , clear: 'clear'
        , back: 'back' // {{  $language.common.back }}
        , local_data_reset: 'Reset Local DATA' // {{  $language.common.local_data_reset }}
        , request_fail: 'Request Failed'
        , auth: 'Authentication'
        , copy: 'copy' //{{  $language.common.copy }}
        , nickName: 'nickname'  //{{  $language.common.nickName }}
        , statusMessage: 'Status Message' //{{  $language.common.statusMessage }}
        , email: 'email' //{{  $language.common.statusMessage }}
        , email_txt: 'Email is used in case of lost PIN and OTP.'
        , status: 'Status' //{{  $language.common.status }}
        , smsNumber: 'PhoneNumber'
        , update: 'Update'  //{{  $language.common.update }}
        , destroy: 'End'
        , later: 'Later'
        , setting: 'Setting'  //{{  $language.common.setting }}
        , soundBackgroundImg: 'Sound Background Image'
        , comment: 'Comment' //{{  $language.common.comment }}
        , modify_comment: 'Edit Comment'
        , comment_require: 'Please leave a comment.'  // {{  $language.common.comment_require }}
        , comment_require_02: 'Please enter a comment.'  // {{  $language.common.comment_require_02 }}
        , cut_off: 'Cut off'  // {{  $language.common.cut_off }}
        , write_reply: 'Write a reply'  // {{  $language.common.write_reply }}
        , explain_cartel: 'Cartel Description'  // {{  $language.common.explain_cartel }}
        , cartel_sns: 'SMS Url'  // {{  $language.common.cartel_sns }}
        , setting_cartel: 'Set Cartel'
        , cartel: 'Cartel'  // {{  $language.common.cartel }}
        , establish: 'Establish'
        , cartel_establish: 'Establish Cartel'  // {{  $language.common.cartel_establish }}
        , complete_cartel_establish: 'Complete Establish Cartel'  // {{  $language.common.complete_cartel_establish }}
        , list: 'List'
        , input_search_keyword: 'Enter search terms'
        , close: 'close'  // {{  $language.common.close }}
        , search: 'Search' // {{  $language.common.search }}
        , compulsory_exit: 'kick out' // {{  $language.common.compulsory_exit }}
        , searchResultUser: 'User Search Results'
        , article_delete_move_user: 'Delete and move user post'  // {{  $language.common.article_delete_move_user }}
        , searchResultCartel: 'Cartel Search Results'
        , searchInfoUser: 'User Search Information'  // {{  $language.common.searchInfoUser }}
        , seeMore: 'More'
        , sympathy: 'Empathy'
        , sympathy_members: 'People who sympathize with me.'
        , menu: 'Menu'
        , invite: 'Invite' // {{  $language.common.invite }}
        , invite_member: 'Invite'
        , not_join: 'Unregistered'
        , search_member: 'Search Member' // {{  $language.common.search_member }}
        , search_member_result: 'Search Result of Member'
        , notice: 'Announcement'  //{{  $language.common.notice }}
        , community: 'Community'  //{{  $language.common.community }}
        , notify_notice: 'Notification Announcement'  //{{  $language.common.notify_notice }}
        , dark_mode: 'Dark Mode'  //{{  $language.common.notify_notice }}
        , announcement: 'Notice'
        , NotFoundNotice: 'No announcements are registered.'  // {{  $language.common.NotFoundNotice }}
        , NotFoundArticle: 'There are no posts registered.'   // {{  $language.common.NotFoundArticle }}
        , NotFoundRegisteredNotice: 'There are no notifications registered.'   // {{  $language.common.NotFoundRegisteredNotice }}
        , voting: 'Voting'   // {{  $language.common.voting }}
        , vote: 'vote'   // {{  $language.common.voting }}
        , complete_vote: 'Voting completed'   // {{  $language.common.complete_vote }}
        , terminate_vote: 'End of voting'   // {{  $language.common.terminate_vote }}
        , memberCount: 'members'
        , manageMember: 'Member management.'  // {{  $language.common.manageMember }}
        , join: 'Join'
        , cartel_join: 'Join with Cartel'
        , oneLineCheering: 'One-line cheering.'  //{{  $language.common.oneLineCheering }}
        , cartelStory: 'Cartel Story'  //{{  $language.common.oneLineCheering }}
        , live_member: 'Viewing member'
        , member: 'Member'  // {{  $language.common.member }}
        , friend: 'Friend'  // {{  $language.common.friend }}
        , myHeart: 'My Heart'  // {{  $language.common.friend }}
        , result: 'Result'
        , cannotInviteFriend: 'No friends are available to invite.'
        , cannotInviteMember: 'No members are available to invite.'
        , cannotFutureMember: 'You don\'t have a friend who can give me a Futures.'  // {{  $language.common.cannotFutureMember }}
        , succeedInvite: 'Invitation request completed.'  // {{  $language.common.succeedInvite }}
        , reason_report: 'Reason for reporting'
        , complete: 'Complete'  // {{  $language.common.complete }}
        , cartel_editing: 'Edit Cartel'
        , cartel_intro: 'Intro Cartel'
        , disclosure_active: 'Activity information disclosure'  // {{  $language.common.disclosure_active }}
        , notify: 'Notification' // {{  $language.common.notify }}
        , notify_sound_default: 'Default notification tone' // {{  $language.common.notify_sound_default }}
        , notify_default: 'Default notification' // {{  $language.common.notify_default }}
        , alarm: 'Notification'  // {{  $language.common.alarm }}
        , view_member: 'Look at the members.'
        , withdrawal_cartel: 'Withdrawal cartel' //{{  $language.common.withdrawal_cartel }}
        , iu: 'IU' //{{  $language.common.iu }}
        , withdrawal: 'withdrawal'  //{{  $language.common.withdrawal }}
        , withdrawal_mafia: 'Withdrawal MAFIA'  //{{  $language.common.withdrawal_mafia }}
        , manage_grade_member: 'Member grade management'
        , closer_cartel: 'Cartel closure'
        , disclosure: 'disclosure'  //{{  $language.common.disclosure }}
        , Nondisclosure: 'nondisclosure'  //{{  $language.common.Nondisclosure }}
        , closure: 'closure'
        , authority: 'authority'
        , dismissal: 'dismissal'
        , myinfo: 'My Information'  //{{  $language.common.myinfo }}
        , appointment: 'appointment' //{{  $language.common.appointment }}
        , boss: 'boss' //{{  $language.common.boss }}
        , under_boss: 'manager' //{{  $language.common.under_boss }}
        , manage_member: 'manage member' //{{  $language.common.manage_member }}
        , manage_register: 'manage register' //{{  $language.common.manage_register }}
        , manage_grade: 'manage grade' //{{  $language.common.manage_register }}
        , manage_grade_txt: 'manage grade condition' //{{  $language.common.manage_register }}
        , waiting_approval: 'waiting approval' //{{  $language.common.waiting_approval }}
        , stopped_member: 'stopped member' //{{  $language.common.stopped_member }}
        , approval: 'approval' //{{  $language.common.approval }}
        , delete: 'delete' //{{  $language.common.delete }}
        , cafo: 'cafo' //{{  $language.common.cafo }}
        , remaining_day: 'remaining day' //{{  $language.common.remaining_day }}
        , termination: 'termination' //{{  $language.common.termination }}
        , days_stop_activity: 'days stop activity' //{{  $language.common.days_stop_activity }}
        , statistics: 'statistics' //{{  $language.common.statistics }}
        , excellent_member: 'excellent member' //{{  $language.common.excellent_member }}
        , daily: 'daily' //{{  $language.common.daily }}
        , weekly: 'weekly' //{{  $language.common.weekly }}
        , monthly: 'monthly' //{{  $language.common.monthly }}
        , visit: 'visit' //{{  $language.common.visit }}
        , visit_count: 'visit count' //{{  $language.common.visit_count }}
        , join_count: 'Number of subscriptions' //{{  $language.common.join_count }}
        , new_article_count: 'the number of new articles' //{{  $language.common.new_article_count }}
        , check_wrote: 'Check your writing' //{{  $language.common.check_wrote }}
        , wrote: 'writing' //{{  $language.common.wrote }}
        , registered_cartel: 'Registered cartel' //{{  $language.common.registered_cartel }}
        , accumulate: 'accumulate' //{{  $language.common.accumulate }}
        , history: 'history' //{{  $language.common.history }}
        , move_order: 'Move in order' //{{  $language.common.move_order }}
        , normal: 'normal' //{{  $language.common.normal }}
        , read: 'read' //{{  $language.common.read }}
        , citizen: 'citizen' //{{  $language.common.citizen }}
        , gangster: 'gangster' //{{  $language.common.gangster }}
        , makeFace: 'make a face' //{{  $language.common.makeFace }}
        , attending_count_current: 'Current number of participants' //{{  $language.common.attending_count_current }}
        , input_vote_name: 'Enter voting title' //{{  $language.common.input_vote_name }}
        , input_vote_item: 'Enter voting items' //{{  $language.common.input_vote_item }}
        , add_vote_item: 'Add voting items' //{{  $language.common.add_vote_item }}
        , morning: 'morning' //{{  $language.common.morning }}
        , afternoon: 'afternoon' //{{  $language.common.afternoon }}
        , hour: 'hour' //{{  $language.common.hour }}
        , minute: 'minute' //{{  $language.common.minute }}
        , year: 'year' //{{  $language.common.minute }}
        , month: 'month' //{{  $language.common.minute }}
        , day: 'day' //{{  $language.common.day }}
        , picture: 'picture' //{{  $language.common.picture }}
        , video: 'video' //{{  $language.common.video }}
        , sound: 'sound' //{{  $language.common.sound }}
        , media: 'media' //{{  $language.common.media }}
        , main: 'main' //{{  $language.common.main }}
        , file: 'file' //{{  $language.common.file }}
        , mike: 'mike' //{{  $language.common.mike }}
        , camera: 'camera' //{{  $language.common.camera }}
        , audio: 'audio' //{{  $language.common.audio }}
        , record: 'record' //{{  $language.common.audio }}
        , take_qr: 'Take a QR Code' //{{  $language.common.take_qr }}
        , edit_profile: 'edit profile' //{{  $language.common.edit_profile }}
        , number_member: 'M Number' //{{  $language.common.number_member }}
        , number_phone: 'Phone number' //{{  $language.common.number_phone }}
        , release_info: 'release info' //{{  $language.common.release_info }}
        , test_version: 'test version' //{{  $language.common.test_version }}
        , version: 'version' //{{  $language.common.version }}
        , version_info: 'version info' //{{  $language.common.version_info }}
        , delete_data: 'delete data' //{{  $language.common.delete_data }}
        , mypage: 'My page' //{{  $language.common.mypage }}
        , attach_image: 'Attach Image' //{{  $language.common.attach_image }}
        , mounting_item: 'Mount item' //{{  $language.common.mounting_item }}
        , mounting: 'Mounting' //{{  $language.common.mounting }}
        , search_friend: 'Search friends' //{{  $language.common.search_friend }}
        , notify_friend: 'Notify friends' //{{  $language.common.notify_friend }}
        , search_friend_result: 'Search friends result' //{{  $language.common.search_friend_result }}
        , my_auth: 'Identification' //{{  $language.common.my_auth }}
        , limit: 'Limit' //{{  $language.common.limit }}
        , account: 'Account' //{{  $language.common.account }}
        , withdrawal_account: 'Withdrawal account' //{{  $language.common.withdrawal_account }}
        , logout: 'Logout' //{{  $language.common.logout }}
        , logout_mafia: 'Logout with MAFIA' //{{  $language.common.logout_mafia }}
        , blocked_member: 'Blocked member' //{{  $language.common.blocked_member }}
        , deposit: 'Deposit' //{{  $language.common.deposit }}
        , withdraw: 'Withdraw' //{{  $language.common.withdraw }}
        , withdraw_apply_on: 'apply' //{{  $language.common.withdraw_apply_on }}
        , futures: 'Gift' //{{  $language.common.futures }}
        , futures_history: 'Gift history' //{{  $language.common.futures }}
        , transfer_history: 'Deposit and withdrawal history' //{{  $language.common.transfer_history }}
        , transfer_time: 'Deposit date and time' //{{  $language.common.transfer_time }}
        , futures_time: 'Gift date and time' //{{  $language.common.futures_time }}
        , time: 'Date and Time' //{{  $language.common.time }}
        , staking: 'Staking' //{{  $language.common.staking }}
        , mafia_card: 'Mafia Card' //{{  $language.common.mafia_card }}
        , mafia_card_detail: 'Mafia Card Detail' //{{  $language.common.mafia_card_detail }}
        , external_nft_wallet: 'External Nft Wallet' //{{  $language.common.external_nft_wallet }}
        , external_nft: 'External NFT' //{{  $language.common.external_nft }}
        , union_card: 'Union Card' //{{  $language.common.union_card }}
        , utility_card: 'Utility Card' //{{  $language.common.utility_card }}
        , utility_card_list: 'Utility Card List' //{{  $language.common.utility_card_list }}
        , cancellation: 'Unstake' //{{  $language.common.cancellation }}
        , min: 'Minimum' //{{  $language.common.min }}
        , max: 'Maximum' //{{  $language.common.max }}

        , deleted: 'It was deleted.' //{{  $language.common.deleted }}
        , invalid_wallet: 'Wallet information does not exist or wallet address is not generated normally' //{{  $language.common.invalid_wallet }}
        , will_create: 'Create a wallet for use by MAFINFT' //{{  $language.common.will_create }}
        , Not_exist_nft_wallet: 'There is no external NFT wallet now.' //{{  $language.common.Not_exist_nft_wallet }}
        , nft_wallet_require: 'Create a wallet and keep the external NFT.' //{{  $language.common.nft_wallet_require }}
        , nft_wallet_require_02: 'Create a wallet and buy a mafia NFT card.' //{{  $language.common.nft_wallet_require_02 }}
        , Not_found_history: 'No History' //{{  $language.common.Not_found_history }}
        , complete_withdraw_request: 'Withdrawal request has been completed.' //{{  $language.common.complete_withdraw_request }}
        , cancellation_utility_card_question: 'Do you want to release the utility card?' //{{  $language.common.cancellation_utility_card_question }}
        , cancellation_utility_card_question_02: 'If you turn it off, you will not be able to use the strength.' //{{  $language.common.cancellation_utility_card_question_02 }}
        , mount_utility_card_question: 'Do you confirm the installation of the utility card?' //{{  $language.common.mount_utility_card_question }}
        , mount_utility_card_question_02: 'Once fitted, the corresponding strengthening capability can be used.' //{{  $language.common.mount_utility_card_question_02 }}
        , complete_futures: 'The gift is complete.' //{{  $language.common.complete_futures }}
        , logout_question: 'Do you want to logout?' //{{  $language.common.logout_question }}
        , no_searched_friend: 'No friends have been selected.' //{{  $language.common.no_searched_friend }}
        , no_selected_friend: 'No friends are selected.' //{{  $language.common.no_selected_friend }}
        , copied_member_number: 'Copied to clipboard.' //{{  $language.common.copied_member_number }}
        , message_test: 'The Message' //{{  $language.common.message_test }}
        , no_registered_comment: 'No comments are registered.' //{{  $language.common.no_registered_comment }}
        , title_require: 'Please enter a title.' //{{  $language.common.title_require }}
        , content_require: 'Please enter the contents.' //{{  $language.common.content_require }}
        , choice_vote_require: 'Please select a vote.' //{{  $language.common.choice_vote_require }}
        , cancel_vote_write: 'Cancel Vote Creation' //{{  $language.common.cancel_vote_write }}
        , cancel_vote_write_question: 'Are you sure you want to cancel the voting?' //{{  $language.common.cancel_vote_write_question }}
        , cancel_vote_write_confirm: 'Voting List created will be deleted.' //{{  $language.common.cancel_vote_write_confirm }}
        , no_excellent_member: 'There are no excellent members.' //{{  $language.common.no_excellent_member }}
        , do_you_add_friend: 'Would you like to add a friend?' //{{  $language.common.do_you_add_friend }}
        , do_you_cancel_friend: 'Would you like to cancel your friend?' //{{  $language.common.do_you_cancel_friend }}
        , no_lookup_member: 'There are no members available to view.' //{{  $language.common.no_lookup_member }}
        , enter_member_name: 'Please enter your member name.' //{{  $language.common.enter_member_name }}
        , already_account: 'This email account is already registered.'
        , already_friend: 'He\'s already registered.'
        , bbs_code_notice: 'notice'
        , error_file_limit: 'Up to 8 attachments are allowed.'
        , error_vote_limit: 'You can vote up to 10 items.'
        , error_date_not_today: 'The voting end date cannot be set earlier than today.'
        , error_date_not_now: 'Voting ends cannot be specified earlier than the current time.'
        , error_confirm_type: 'There is no request distinction.'
        , text_delete_comment: 'This comment has been deleted.'
        , error_empty_search_value: 'Please enter your search term.'   //{{  $language.common.error_empty_search_value }}
        , error_request: 'Invalid Request.'   //{{  $language.common.error_request }}
        , first_sympathy_require: 'Press the first sympathy.'   //{{  $language.common.first_sympathy_require }}
        , not_exist_page: 'This page does not exist.'   //{{  $language.common.not_exist_page }}
        , reuse_after_check: 'Please check and use it again.'   //{{  $language.common.reuse_after_check }}

        , expire_token: 'Token Expiration'  //{{  $language.common.expire_token }}
        , impossible_token: 'Unable to process token'  //{{  $language.common.impossible_token }}
        , error_token: 'Token Error!'  //{{  $language.common.error_token }}

        , message_login_require: 'Please login and use it again.'  //{{  $language.common.message_login_require }}
        , message_wrong: 'There\'s a problem. If the problem persists, please contact the administrator.'  //{{  $language.common.message_wrong }}
        , message_error: 'A problem occurred while processing the request'  //{{  $language.common.message_error }}
        , message_error_network: 'The network is not stable.'  //{{  $language.common.message_error_network }}
    }
    , confirm: {
        delete_bbs: {
            title: 'Delete post'
            , main: 'Are you sure you want to delete this post?'
            , sub: 'Click OK to delete the post.'
        }
        , block_account: {
            title: 'Block user'
            , main: 'Are you sure you want to block that user?'
            , sub: 'Click OK to block.'
        }
        , block_cancel: {
            title: 'Unblock user'
            , main: 'Are you sure you want to unblock that user?'  //{{  $language.block_cancel.main }}
            , sub: 'Click OK to unblock the user.'  //{{  $language.block_cancel.sub }}
        }
        , add_friend: {
            title: 'Add to friends'  //{{  $language.confirm.add_friend.title }}
            , main: 'Would you like to add a friend?'
            , sub: 'Do you want to receive all new notifications from that friend?'
            , sub2: 'Do you want to receive all new notifications from that friend?'  //{{  $language.confirm.add_friend.sub2 }}
        }
        , cancel_friend: {
            title: 'Cancel friend'  //{{  $language.confirm.cancel_friend.title }}
            , main: 'Are you sure you want to cancel your friend?'  //{{  $language.confirm.cancel_friend.main }}
            , sub: 'If you want to cancel a friend, click OK.'  //{{  $language.confirm.cancel_friend.sub }}
        }
        , report_bbs: {
            title: 'Report Post'
            , main: 'Would you like to report this post?'
            , sub: 'reason: '
        }
        , delete_comment: {
            title: 'Delete comment'
            , main: 'Are you sure you want to delete that comment?'
            , sub: 'If you want to delete, please click the delete button.'
        }
        , report_comment: {
            title: 'Report comment'
            , main: 'Would you like to report that comment?'
            , sub: 'reason: '
        }

        , write_comment: {
            title: 'Write a comment'  //{{  $language.confirm.write_comment.title }}
            , choice_comment: 'Write Comment whether or not.'  //{{  $language.confirm.write_comment.choice_comment }}
        }
    }
    , word: {
        write: 'write'  //{{  $language.word.write }}
        , modify: 'edit'  //{{  $language.word.modify }}
        , delete: 'delete'
        , friend: 'friend'
        , friend_add: 'add to friend'
        , friend_cancel: 'cancel friend'
        , block: 'cut off'
        , block_cancel: 'Unblock'  //{{  $language.word.block_cancel }}
    }
    , title: {
        report_bbs: 'Report post'
        , report_comment: 'Report comment'
        , change_location_bbs: 'Move post location'
    }

    , mypage: {
        language_in_use: 'Language to use'
        , title_personal_confirm: 'Identification of yourself'
        , select_language: 'Select the language to use'
        , credit_management: 'Credit Card'
        , credit_regist: 'Credit Regist'
        , credit: {
            credit_setup_title: 'Setup Main Card'
            , credit_setup_name: 'Main'
            , credit_setup_txt: 'Do you want to set the card as the representative card?'
            , credit_setup_txt_sub: 'If there is an existing representative card registered, the representative card will be changed.'
            , credit_delete_title: 'Delete Card'
            , credit_delete_txt: 'Are you sure you want to delete the card?'
            , credit_delete_txt_sub: 'If you want to delete, click the OK button.'
            , credit_none_txt: 'No credit card registered.'
            , credit_regist_complete_txt: 'Credit card registration complete.'
            , credit_regist_fail_txt: 'Credit card registration failed.'
            , credit_regist_fail_txt_sub: 'Please Try Again.'
            , credit_card_number: 'Card Number'
            , credit_card_validity: 'Validity Period'
            , credit_card_cvc: 'CVC'
            , credit_card_pass: 'Card Secret'
            , credit_birthym: 'BirthDay'
            , credit_business_number: 'Business Number'
            , credit_regist_txt_cvc: 'cvc 3 digit'
            , credit_regist_txt_pass: 'first 2 digits'
            , credit_regist_txt_birth: '6 digit birthday'
            , credit_regist_txt_corporation: '10 digit business number'
        }
    }
    , auth: {
        login_with_kakoa: 'Login with kakao'
        , login_with_google: 'Login with Google'
        , login_with_apple: 'Login with Apple'
        , login_with_apple_fail: 'Failed Login with Apple'
        , join_with_kakao: 'Join with Kakao'
        , join_with_google: 'Join with Google'
        , join_with_apple: 'Join with Apple'

        , password_message_01: 'The 6-digit password is automatically switched when entered.'

        , question_pin: 'Have you forgotten your PIN number?'
        , request_pin_01: 'Enter your PIN number one more time.'
        , request_pin_02: 'Set a PIN for security.'
        , request_pin_03: 'Enter your New PIN number'
        , request_pin_04: 'Enter your Secure PIN number'
        , request_pin_05: 'Personal authentication is required to change the PIN number.'  //{{  $language.auth.request_pin_05 }}
        , request_pin_rematch: 'The PIN number does not match. Please set it up again.'
        , changed_pin: 'Your PIN number has been changed'  //{{  $language.auth.changed_pin }}
        , complete_changed_pin: 'Changed pin number'  //{{  $language.auth.complete_changed_pin }}
        , security_pin: 'Security Pin'

        , check_already_sign_up_account: 'Check Subscription Account'

        , check_account: 'Please check your account.'
        , withdrawn_account: 'This account has been withdrawn.'
        , block_account: 'Blocked account.'   //{{  $language.auth.block_account }}
        , stopped_account: 'This is a retired account.'
        , dormant_account: 'This is a dormant account.'
        , withdrawn_or_dormant_account: 'This is a withdrawal or dormant account.'
        , expired_auth: 'Expired account'
        , expired_auth_login: 'Your authentication has expired. Please login again.'
        , no_sign_up_account: 'You are not subscribed to this account.'
        , no_sign_up_info: 'No subscription information found.'
        , already_sign_up_account: 'This will be verified with your current registered account.'
        , not_found_block_account: 'No members are blocked.'   //{{  $language.auth.not_found_block_account }}

        , login_fail_apple: 'Apple login failed '
        , login_fail_sns_error: 'SNS Login Error'
        , google_otp: 'Google OTP'  //{{  $language.auth.google_otp }}
        , otp_auth: 'OTP authentication'  //{{  $language.auth.otp_auth }}
        , otp_auth_number: 'OTP authentication number' //{{  $language.auth.otp_auth_number }}
        , auth_number: 'verification code'  //{{  $language.auth.auth_number }}
        , otp_input: 'Enter 6-digit verification code'  //{{  $language.auth.otp_input }}
        , otp_input_01: 'Enter the authentication number.' //{{  $language.auth.otp_input_01 }}
        , otp_forget_01: 'Can\'t use OTP?'  //{{  $language.auth.otp_forget_01 }}
        , otp_message_01: 'Install the Google OTP authentication app on your smartphone.'  //{{  $language.auth.otp_message_01 }}
        , otp_message_02: 'Scan the barcode in the Google Authenticator app.'  //{{  $language.auth.otp_message_02 }}
        , otp_barcode_message_01: 'If barcode scanning is not possible, enter the key below.'  //{{  $language.auth.otp_barcode_message_01 }}
        , otp_copy_key: 'Your Google OTP key has been copied.'  //{{  $language.auth.otp_copy_key }}
        , sns_auth_require: 'Social authentication must be completed for user verification.'  //{{  $language.auth.sns_auth_require }}
        , re_register_otp_google: 'Google OTP Re Registration'  //{{  $language.auth.re_register_otp_google }}


        , sms_auth: 'mobile phone authentication'  //{{  $language.auth.sms_auth }}
        , auth_require: 'Please proceed with the authentication.'  //{{  $language.auth.auth_require }}
        , sms_number_inquire: 'Enter your mobile phone number.'
        , sms_code_inquire: 'Enter the sms authentication number.'

        , forgot_pin_title: 'Recovery PIN number loss'  //{{  $language.auth.forgot_pin_title }}
        , change_pin_number: 'Change PIN number'  //{{  $language.auth.change_pin_number }}

        , no_authority_lookup: 'You do not have permission to lookup'
        , no_authority_write_article: 'Notice does not have permission to create posts.' //{{  $language.auth.no_authority_write_article }}


        , auto_join_if_approval: 'Automatic subscription approval status'
        , auto_join_approval: 'Authorize automatic subscriptions'  //{{  $language.auth.auto_join_approval }}
        , can_all_authority: 'Have full authority.' //{{  $language.auth.can_all_authority }}
        , all_approval_stop_authority: 'Permission to approve and suspend membership' //{{  $language.auth.all_approval_stop_authority }}
        , all_approval_stop: 'Member approval and suspension of use' //{{  $language.auth.all_approval_stop }}


        , two_factor_info: 'Two-Factor Authentication info' //{{  $language.auth.two_factor_info }}
        , two_factor_status_non_register: 'Two-Factor authentication not registered' //{{  $language.auth.two_factor_status_non_register }}
        , two_factor_require_google: 'Available after setting up Google OTP.' //{{  $language.auth.two_factor_require_google }}

        , access_app_info: 'Guide to App Access Permissions' //{{  $language.auth.access_app_info }}
        , access_permission_essential: 'Required Access Permissions' //{{  $language.auth.access_permission_essential }}
        , access_app_permission_require: 'Please allow the access rights below to use the service.' //{{  $language.auth.access_app_permission_require }}

        , access_permission_info: 'Allowance Info' //{{  $language.auth.access_permission_info }}

    }
    , agreement: {
        title: 'Terms of Service',
        privacy_policy: 'Personal Information Utilization Policy',
        privacy_defence: 'Personal Information Protection',  //{{ $language.agreement.privacy_defence }}
        agreement_policy: 'Agree to terms and conditions',

        agree_yes: 'agree.',
        agree_no: 'disagree.',

        explanation_01: 'You need to agree to the terms and conditions to use the service.',
        explanation_02: 'All, confirm and agree.',
        explanation_03: '(Required) Terms of Service',
        explanation_04: '(Required) Consent to collection and use of personal information',
        dividingLine: 'dividing line',  //{{ $language.agreement.dividingLine }}

    },
    appLanguage: {
        title: 'app version',
        setUsageLanguage: 'set usage language'
    },
    kakao: {
        title: 'Community dedicated to culture and arts, Mafia Project',
        auth: 'authentication kakao',  //
        login: 'login with kakao',  //{{ $language.kakao.login }}
    },
    apple: {
        login: 'login with apple',  //{{ $language.apple.login }}
        login_failed: 'Failed Apple login',  //{{ $language.apple.login_failed }}
    },

    google: {
        login: 'login with google'  //{{ $language.google.login }}
    },

    withdrawal_info: {
        message_01: 'Do you want to leave the mafia game?',  //{{ $language.withdrawal_info.message_01 }}
        message_02: 'The withdrawn mafia account and wallet information cannot be recovered, so please choose carefully.',  //{{ $language.withdrawal_info.message_02 }}

        message_03: 'Please check before resigning from the mafia!',  //{{ $language.withdrawal_info.message_03 }}
        message_04: 'When resigning from the cartel, your registered articles will not be automatically deleted. However, all information will be deleted, such as transaction history in the NFT wallet and coin wallet. In addition, you cannot log in with a canceled Mafia account, so all wallets linked to the Mafia service will be unavailable.',  //{{ $language.withdrawal_info.message_04 }}
        message_05: 'Make sure to send NFTs and coins in your wallet to an external exchange or wallet.',  //{{ $language.withdrawal_info.message_05 }}
        message_06: 'Please choose carefully as the deleted mafia account and wallet information cannot be recovered.',  //{{ $language.withdrawal_info.message_06 }}
        message_07: 'You currently own NFT but agree to opt-out.',  //{{ $language.withdrawal_info.message_07 }}
    },

    greetings: {
        greeting_01: 'Welcome to MAFIA.',  //{{ $language.greetings.greeting_01 }}
        greeting_02: 'MAFIA is a cultural mafia that encompasses all fields of art. You can buy an NFT character card to create a cartel (organization) and join it to communicate in various ways.',  //{{ $language.greetings.greeting_02 }}
        greeting_03: 'Meet the various MAFIA collections.',  //{{ $language.greetings.greeting_03 }}
        greeting_04: 'Buy an NFT card and communicate in the cartel.',  //{{ $language.greetings.greeting_04 }}
        greeting_05: 'Buy the MAFI NFT',  //{{ $language.greetings.greeting_05 }}
    },

    interested: {
        title: 'Interest',
        choice_interested: 'Select Interest',  //{{ $language.interested.choice_interested }}
        select: 'Please select the field you are interested in MAFIA.',
        cartel_select: 'Choose your cartel interests.',
        select_02: 'Choose your cartel interests.',  //{{ $language.interested.select_02 }}
        music: 'I am interested in music.', //{{ $language.interested.music }}
        art: 'I am interested in art and design.',  //{{ $language.interested.art }}
        fashion: 'I am interested in fashion design and modeling.',  //{{ $language.interested.fashion }}
        influence: 'I am interested in running a YouTuber, blogger, etc.',  //{{ $language.interested.influence }}
        youtube_require: 'Please enter a YouTube link',  //{{ $language.interested.youtube_require }}
    },

    nice: {
        nice_auth_title: 'NICE Identification',   //{{  $language.nice.nice_auth_title }}
        nice_auth_title_txt: 'Enter activity information',   //{{  $language.nice.nice_auth_title }}
        nickName_message_01: 'Set a nickname to be active in MAFIA.',
        nickName_message_02: 'Nickname cannot be changed after setting.',
        nickName_message_03: 'Please enter Nickname and status message',
        nickName_require: 'Please enter Nickname.',  //{{  $language.nice.nickName_require }}
        status_message_require: 'Please enter status message.',  //{{  $language.nice.status_message_require }}
    },

    grade: {
        article_delete: 'Delete Grade', //{{  $language.notice.article_delete }}
        article_delete_message: 'Are you sure you want to delete that rating?',
        article_delete_message_confirm: 'Click OK to delete the rating.',
    },
    notice: {
        title: 'Board',
        write: 'write',  //{{  $language.notice.write }}
        choice: 'choose board', //{{  $language.notice.choice }}
        manage: 'Bulletin board', //{{  $language.notice.manage }}
        create: 'Create bulletin board', //{{  $language.notice.create }}
        create2: 'Create Subscription Board', //{{  $language.notice.create }}
        add: 'Add bulletin board', //{{  $language.notice.add }}
        edit: 'Edit bulletin board', //{{  $language.notice.edit }}
        name: 'bulletin board name', //{{  $language.notice.name }}
        name_require: 'Please enter a name for the bulletin board.', //{{  $language.notice.name_require }}
        set_board_authority: 'Board Permission Settings', //{{  $language.notice.set_board_authority }}
        board_authority: 'Board Permission', //{{  $language.notice.board_authority }}
        set_board_public: 'Bulletin Board Public Settings', //{{  $language.notice.set_board_public }}
        set_board_authority_message: 'Set permissions to read and write bulletin board posts.', //{{  $language.notice.set_board_authority_message }}
        article: 'Post',
        article_detail: 'Post Detail',
        article_delete: 'Delete post', //{{  $language.notice.article_delete }}
        article_delete_authority: 'Delete post permission', // {{  $language.notice.article_delete_authority }}
        article_delete_message: 'Are you sure you want to delete this post?',
        article_delete_message_confirm: 'Click OK to delete the post.',
        all_article: 'All article',
        new_article: 'New post',
        all_article_view: 'View full article',
        each_notice_view: 'View each bulletin board',
        wroteArticle: 'What I wrote.',
        register_article: 'Writing registration',  // {{  $language.notice.register_article }}
        delete_board_register: 'When a bulletin board is deleted, registered posts are also deleted. Are you sure you want to delete the post?',  // {{  $language.notice.delete_board_register }}
        delete_board_register_confirm: 'If you want to delete, please click the delete button.',  // {{  $language.notice.delete_board_register_confirm }}
        if_edit: 'Voting registration, modification, and deletion are not possible when revising the text.',  // {{  $language.notice.if_edit }}
    },

    notify: {
        message_01: 'Please set the notification entry for new posts.',
        message_02: 'Please set my notification item.', //{{  $language.notify.message_02 }}
        notify_notice: 'Announcement notification',  //{{  $language.notify.notify_notice }}
        notify_notice_set: 'Announcement Notification settings',  //{{  $language.notify.notify_notice_set }}
        notify_comment: 'Comment notification',  //{{  $language.notify.notify_comment }}
        notify_comment_set: 'Setting Comment notification',  //{{  $language.notify.notify_comment_set }}
        notify_cartel_set: 'Setting Cartel notification',
        notify_cartel: 'Comment notification',  //{{  $language.notify.notify_cartel }}
        notify_set: 'Set notification',  //{{  $language.notify.notify_set }}
    },

    emoticon: {
        best: 'Best',
        funny: 'Funny',
        like: 'Good',
        sad: 'Sad',
        surprise: 'Surprised',
        angry: 'Angry',
    },

    cartel: {
        cover_web: 'Web Cover Image',
        notice_grade_info: 'Grade Information',
        notice_grade_info_txt: 'The score is the sum of the number of visits (1 point) + posts (3 points)',
        notice_grade_change: 'Grade Information',
        notice_grade_chagne_txt1: '',
        notice_grade_chagne_txt2: 'Your grade has been changed',
        manage_cartel: 'Cartel management',
        recommend_cartel: 'Recommended Cartel', // {{  $language.cartel.recommend_cartel }}
        current_under_boss: 'Current Under boos',
        need_appointment_under_boss: 'Manager appointment required',
        select_under_boss_authority: 'Select Manager Permissions', // {{  $language.cartel.select_under_boss_authority }}
        under_boss_authority: 'Manager Permissions', // {{  $language.cartel.under_boss_authority }}
        need_appointment_under_boss_02: 'You need an Manager appointment.',
        be_met_criteria_member_under_boss: 'Member who meets the Manager conditions',
        fellow_appointment_under_boss: 'Are you sure you want to designate the member below as an Manager?',
        name: 'Cartel name',  // {{  $language.cartel.name }}
        analyze_cartel: 'Cartel Statistics',  // {{  $language.cartel.analyze_cartel }}
        recommend_cartel_message_01: 'Join the cartel.',
        WeAreCartel: 'It is Cartel',
        recommendCartelBigView: 'View large recommended cartels',
        NoRecommendCartel: 'There are no recommended cartels.',
        NoRegisteredRecommendCartel: 'There are no recommended cartels registered.',
        Not_Found_Cartel_Joined: 'No cartels are subscribed.',  //{{  $language.cartel.Not_Found_Cartel_Joined }}

        name_require: 'Please enter a cartel name.',  // {{  $language.cartel.name_require }}
        explain_require: 'Please explain the cartel.',  // {{  $language.cartel.explain_require }}
        sns_require: 'Please Enter SNS URL.',  // {{  $language.cartel.explain_require }}
        image_require: 'Please select a cartel image.',  // {{  $language.cartel.image_require }}
        interested_require: 'Choose your cartel interests.',  // {{  $language.cartel.interested_require }}

        needApprovalAdminCartel_01: 'This cartel requires administrator approval.',
        needApprovalAdminCartel_02: 'Cartel activities are possible after administrator approval.',
        joinAfterWithdrawalCartel: 'If you leave the cartel, you can rejoin after 7 days. Written posts and comments are not automatically deleted.',
        agreeCartel: 'I agree to leave the cartel.',
        completeJoinCartel: 'Cartel subscription completed.',
        completeCreateCartel: 'MAFIA cartel creation completed.',  // {{  $language.cartel.completeCreateCartel }}
        manage_under_boss: 'Manager management', // {{  $language.cartel.manage_under_boss }}
        boss_approval_need_join: 'You can only join the cartel with the approval of the boss or Manager.', // {{  $language.cartel.boss_approval_need_join }}
        appointment_and_dismissal: 'Appointment and authority, dismissal',  // {{  $language.cartel.appointment_and_dismissal }}
        cartel_boss : 'Cartel boss',
        cartel_member: 'Cartel member',

        closerForMemberMessage_01: 'There must be no members to close the cartel.',
        closerForMemberMessage_02: 'If you apply for closure, it will be closed in 24 hours.',
        already_joined_member_public: 'Open the bulletin board only to members of the cartel.',  //{{  $language.cartel.already_joined_member_public }}
        agreeCloseCartel: 'I agree to close the cartel.',

        cartel_request: 'Cartel Open Request'
        , cartel_request_modify: 'Cartel Open Request Modify'
        , cartel_request_list: 'Request List'
        , cartel_live_request: 'Request to open live'
        , cartel_live_request_modify: 'Edit live opening information'
        , cartel_live_request_list: 'live list'
        , regist_cover_image: 'Cover Image'
        , info_request_cover_image: 'Select Cartel Cover Image'
        , info_request_cover_image_size1: 'W 750px H 500px over'
        , info_request_cover_image_size2: 'Best Size W 750px over'
        , info_request_cover_image_size3: 'H 500px over'
        , regist_logo_image: 'Cartel Logo'
    },

    report: {
        CA01300001: 'Inappropriate publicity',
        CA01300002: 'Hate speech',
        CA01300003: 'Obscene or inappropriate content',
        CA01300004: 'Repetition of the same',
        CA01300005: 'Copyright infringement',
        CA01300006: 'False information and incitement',
    },

    payment: {
        method: 'Payment method', // {{  $language.payment.method }}
        nft_history: 'NFT payment history' // {{  $language.payment.nft_history }}

    },

    nft: {
        withdraw_gab_fee: 'Withdrawal gas fee',  // {{  $language.nft.withdraw_gab_fee }}
        nft_info: 'NFT Info.',  // {{  $language.nft.nft_info }}
        mafia_nft_future: 'MAFIA NFT Gift.',  // {{  $language.nft.mafia_nft_future }}
        card_name: 'Card Name.',  // {{  $language.nft.card_name }}
        holder: 'Holder.',  // {{  $language.nft.holder }}
        address_contract: 'Contract Address.',  // {{  $language.nft.address_contract }}
        address_deposit_require: 'Enter deposit address.',  // {{  $language.nft.address_deposit_require }}
        address_withdraw_require: 'Enter withdrawal address.',  // {{  $language.nft.address_withdraw_require }}
        amount_withdraw_require: 'Enter withdrawal amount.',  // {{  $language.nft.amount_withdraw_require }}
        quantity_withdraw_require: 'Enter withdrawal quantity.',  // {{  $language.nft.quantity_withdraw_require }}
        quantity_withdraw_check: 'Check withdrawal quantity.',  // {{  $language.nft.quantity_withdraw_check }}
        quantity_withdraw_lack: 'Lack of withdrawal quantity.',  // {{  $language.nft.quantity_withdraw_lack }}
        address_withdraw_require_02: 'Please enter the withdrawal wallet address.',  // {{  $language.nft.address_withdraw_require_02 }}
        nft_card: 'NFT Card.',  // {{  $language.nft.nft_card }}
        buy_random_nftcard: 'Buy a random NFT card.',  // {{  $language.nft.buy_random_nftcard }}
        buy_random_nftcard_title: 'Buy NFT Card',  // {{  $language.nft.buy_random_nftcard_title }}
        add: 'Add quantity',  // {{  $language.nft.add }}
        sub: 'Subtract quantity',   // {{  $language.nft.sub }}
        price: 'Sell price',   // {{  $language.nft.price }}
        final_payment: 'Final payment',   // {{  $language.nft.final_payment }}
        wallet_require: 'Please create a wallet and use it.',   // {{  $language.nft.wallet_require }}
        not_found_wallet_address: 'There is no wallet address.',   // {{  $language.nft.not_found_wallet_address }}
        copied_wallet_address: 'There is wallet address has been copied.',   // {{  $language.nft.copied_wallet_address }}
        no_wallet: 'There is no created wallet',   // {{  $language.nft.no_wallet }}
        wallet_check_require: 'There is no have enough coins to hold. Check your wallet.',   // {{  $language.nft.wallet_check_require }}
        nft_info_detail: 'NFT Detail Info',   // {{  $language.nft.nft_info_detail }}
        complete_buy_nft: 'Complete Buy NFT',   // {{  $language.nft.complete_buy_nft }}
        set_maincard: 'Set main card',   // {{  $language.nft.set_maincard }}
        maincard: 'Main card',   // {{  $language.nft.maincard }}
        nft_list: 'NFT List',   // {{  $language.nft.nft_list }}
        nft_swap: 'NFT SWAP',   // {{  $language.nft.nft_swap }}
        choice_coin: 'Choose Coin',   // {{  $language.nft.choice_coin }}
        osolremio: 'OSOLREMIO',   // {{  $language.nft.osolremio }}
        fee: 'fee',  // {{  $language.nft.fee }}
        payment: 'Payment',  // {{  $language.nft.payment }}
        create_wallet: 'Create a wallet',  // {{  $language.nft.create_wallet }}
        choice_wallet: 'Choose a wallet',  // {{  $language.nft.choice_wallet }}
        detail_wallet: 'Wallet Detail',  // {{  $language.nft.detail_wallet }}
        choice_create_wallet: 'Select a wallet to create',  // {{  $language.nft.choice_create_wallet }}
        get_card_as_random: 'You can win cards of various grades with random cards.',  // {{  $language.nft.get_card_as_random }}
        nft_not_registered: 'No NFT cards are registered.',  // {{  $language.nft.nft_not_registered }}
        nft_not_taken: 'You do not have an NFT card.',  // {{  $language.nft.nft_not_taken }}
        utility_not_taken: 'You do not have a utility card.',  // {{  $language.nft.utility_not_taken }}
        notify_staking_list: 'Stacking history notification',  // {{  $language.nft.notify_staking_list }}
        staking_card: 'A card in staking',  // {{  $language.nft.staking_card }}
        complete_withdraw_nftcard: 'NFT card withdrawal completed.',  // {{  $language.nft.complete_withdraw_nftcard }}
        complete_staking: 'Stacking process completed.',  // {{  $language.nft.complete_staking }}
        complete_clear_staking: 'Unstacking completed.',  // {{  $language.nft.complete_clear_staking }}
        staking_question: 'Would you like to staking',  // {{  $language.nft.staking_question }}
        staking_clear_question: 'Would you like to unstake?',  // {{  $language.nft.staking_clear_question }}
        mining_level: 'The amount mined depends on the grade.',  // {{  $language.nft.mining_level }}

        nft_deposit: 'NFT Deposit',  // {{  $language.nft.nft_deposit }}
        nft_withdraw: 'NFT Withdraw',  // {{  $language.nft.nft_withdraw }}
        nft_wallet_require: 'You can receive an NFT after creating a wallet.',  // {{  $language.nft.nft_wallet_require }}
        nft_wallet_create: 'Creating a Wallet',  // {{  $language.nft.nft_wallet_create }}
        wallet_address_not: 'Creating a Wallet',  // {{  $language.nft.nft_wallet_create }}
        clear_staking: 'Unstaking',  // {{  $language.nft.clear_staking }}
    },

    image: {
        image_delete: 'Delete Image',
        image_delete_question: 'Are you sure you want to delete the attached image?',
        image_delete_confirm: 'Press OK to delete the image.',
        image_cash_save: '>Image cache, read or save writing files',   // {{  $language.image.image_cash_save }}
    },

    disclosure_message: {
        reveal_01: 'Please choose whether to reveal it or not.',  // {{  $language.disclosure_message.reveal_01 }}
        reveal_02: 'When activity information is not disclosed, friends can\'t check my profile for the cartel.', // {{  $language.disclosure_message.reveal_02 }}
    },

    excel: {
        agency_name: 'Agency name',  // {{  $language.excel.agency_name }}
        account: 'Account',  // {{  $language.excel.account }}
        name: 'Name',  // {{  $language.excel.name }}
        phone: 'Phone',  // {{  $language.excel.phone }}
        regDate: 'Registration Date',  // {{  $language.excel.regDate }}
    },

    reset: {
        account_list: 'Account list',  // {{  this.$language.reset.account_list }}
        wallet_list: 'Wallets list',  // {{  this.$language.reset.wallet_list }}
        join_info: 'Join info',  // {{  this.$language.reset.join_info }}
        bought_nft_list: 'Bought nft list',  // {{  this.$language.reset.bought_nft_list }}
        transfer_list: 'history',  // {{  this.$language.reset.transfer_list }}
        reported_comment_list: 'Report a comment list',  // {{  this.$language.reset.reported_comment_list }}
        blocked_list: 'Blocking list',  // {{  this.$language.reset.blocked_list }}
        notice_list: 'Bulletin Board List',  // {{  this.$language.reset.notice_list }}
        joined_cartel_list: 'Subscribed cartels list',  // {{  this.$language.reset.joined_cartel_list }}
        cartel_member_list: 'Cartel member list',  // {{  this.$language.reset.cartel_member_list }}
        friends_list: 'Friends list',  // {{  this.$language.reset.friends_list }}
        futures_list: 'Gift list',  // {{  this.$language.reset.futures_list }}
        seq_list: 'SEQ list',  // {{  this.$language.reset.seq_list }}
        articles_list: 'Articles list',  // {{  this.$language.reset.articles_list }}
        cartel_list: 'Cartel list',  // {{  this.$language.reset.cartel_list }}
    },

    terms: {
        v1_1: '제 1 조 (목적 및 정의)',
        v1_2: '주식회사 카카오(이하 ‘회사’)가 제공하는 서비스를 이용해 주셔서 감사합니다. 회사는 여러분이 회사가 제공하는 다양한 인터넷과 모바일 서비스(카카오 서비스, Daum 서비스, 멜론 서비스 등을 의미하며 이하 해당 서비스들을 모두 합하여 “통합서비스” 또는 “서비스”라 함)에 더 가깝고 편리하게 다가갈 수 있도록 ‘카카오 통합서비스약관’(이하 ‘본 약관’)을 마련하였습니다. 여러분은 본 약관에 동의함으로써 통합서비스에 가입하여 통합서비스를 이용할 수 있습니다. 단, 여러분은 회사가 아닌 계열사를 포함한 제3자가 제공하는 서비스 (예: ㈜카카오모빌리티가 제공하는 카카오 T 택시 서비스)에 가입되지는 않으며, 회사가 제공하는 유료서비스 (예: 멜론 유료서비스)의 경우 여러분이 별도의 유료이용약관에 대한 동의한 때에 회사와 여러분 간의 유료서비스 이용계약이 성립합니다. 본 약관은 여러분이 통합서비스를 이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정하고 있으므로 조금만 시간을 내서 주의 깊게 읽어주시기 바랍니다.',
        v1_3: '카카오 서비스: 회사가 제공하는 1) “카카오” 브랜드를 사용하는 서비스(예:카카오톡) 또는 2) 카카오계정으로 이용하는 서비스(Daum 및 Melon 브랜드 서비스는 제외, 예: 브런치) (단, 서비스 명칭에 ‘카카오’가 사용되더라도 회사가 아닌 카카오 계열사에서 제공하는 서비스 (예: 카카오 T택시 서비스)는 본 약관의 카카오 서비스에 포함되지 않습니다)' +
            'Daum 서비스: 회사가 제공하는 Daum(다음) 브랜드를 사용하는 서비스(예: Daum 포털 서비스)' +
            '멜론 서비스: 회사가 제공하는 Melon(멜론) 브랜드를 사용하는 서비스(예: Melon 서비스)' +
            '개별 서비스: 통합서비스를 구성하는 카카오 서비스, Daum 서비스, 멜론 서비스 등 브랜드 단위의 서비스를 각 의미함' +
            '세부 하위 서비스: 개별 서비스를 구성하는 개별 서비스 내의 세부 하위 서비스를 의미하며, 예를 들어 각 개별 서비스 내의 유료서비스, 카카오 서비스 내의 카카오톡 서비스, Daum 서비스 내의 카페, 메일 등 서비스, 멜론 서비스 내의 멜론티켓 서비스 등을 의미함',

        v2_1: '제 2 조 (목적 및 정의)',
        v2_2: '주식회사 카카오(이하 ‘회사’)가 제공하는 서비스를 이용해 주셔서 감사합니다. 회사는 여러분이 회사가 제공하는 다양한 인터넷과 모바일 서비스(카카오 서비스, Daum 서비스, 멜론 서비스 등을 의미하며 이하 해당 서비스들을 모두 합하여 “통합서비스” 또는 “서비스”라 함)에 더 가깝고 편리하게 다가갈 수 있도록 ‘카카오 통합서비스약관’(이하 ‘본 약관’)을 마련하였습니다. 여러분은 본 약관에 동의함으로써 통합서비스에 가입하여 통합서비스를 이용할 수 있습니다. 단, 여러분은 회사가 아닌 계열사를 포함한 제3자가 제공하는 서비스 (예: ㈜카카오모빌리티가 제공하는 카카오 T 택시 서비스)에 가입되지는 않으며, 회사가 제공하는 유료서비스 (예: 멜론 유료서비스)의 경우 여러분이 별도의 유료이용약관에 대한 동의한 때에 회사와 여러분 간의 유료서비스 이용계약이 성립합니다. 본 약관은 여러분이 통합서비스를 이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정하고 있으므로 조금만 시간을 내서 주의 깊게 읽어주시기 바랍니다.',
        v2_3: '카카오 서비스: 회사가 제공하는 1) “카카오” 브랜드를 사용하는 서비스(예:카카오톡) 또는 2) 카카오계정으로 이용하는 서비스(Daum 및 Melon 브랜드 서비스는 제외, 예: 브런치) (단, 서비스 명칭에 ‘카카오’가 사용되더라도 회사가 아닌 카카오 계열사에서 제공하는 서비스 (예: 카카오 T택시 서비스)는 본 약관의 카카오 서비스에 포함되지 않습니다)' +
            'Daum 서비스: 회사가 제공하는 Daum(다음) 브랜드를 사용하는 서비스(예: Daum 포털 서비스)' +
            '멜론 서비스: 회사가 제공하는 Melon(멜론) 브랜드를 사용하는 서비스(예: Melon 서비스)' +
            '개별 서비스: 통합서비스를 구성하는 카카오 서비스, Daum 서비스, 멜론 서비스 등 브랜드 단위의 서비스를 각 의미함' +
            '세부 하위 서비스: 개별 서비스를 구성하는 개별 서비스 내의 세부 하위 서비스를 의미하며, 예를 들어 각 개별 서비스 내의 유료서비스, 카카오 서비스 내의 카카오톡 서비스, Daum 서비스 내의 카페, 메일 등 서비스, 멜론 서비스 내의 멜론티켓 서비스 등을 의미함',
    },

    warning: {
        join: 'ID, nickname, and activity details are disclosed to the cartel\'s management team for smooth cartel operation during the cartel\'s activities, and activities that violate cartel policies may be subject to sanctions. You may refuse this consent, but you cannot join the cartel.'
    },

    staking: {
        txt_reward_withdrawal: 'reward'
        , txt_reward_withdrawal_history: 'history'
        , txt_reward_withdrawal_date: 'Request'
        , txt_reward_withdrawal_date2: 'Complete'
        , txt_reward_withdrawal_error: 'Enter withdrawal amount'
        , txt_reward_withdrawal_error2: 'You cannot withdraw more than the withdrawal limit'
        , txt_reward_withdrawal_quantity: 'Quantity'
        , txt_reward_withdrawal_possible_quantity: 'Possible'
        , txt_reward_withdrawal_complete_quantity: 'Complete'
        , txt_reward_withdrawal_full_quantity: 'Full'
        , txt_staking_history: 'Staking History'
        , txt_staking_history_date: 'Date'
        , txt_staking_history_quantity: 'Quantity'
        , txt_staking_history_none: 'No history'
    }
    , live: {
        live_name: 'Live broadcast name'
        , live_description: 'Live notice'
        , live_name_required: 'Enter the name of the live broadcast'
        , live_notice_required: 'Enter the notice of the live broadcast'
        , live_camera_use: 'Using a live camera'
        , live_camera_use_info: 'You can use the camera during the broadcast'
        , live_request_title: 'Request to start a live broadcast'
        , live_request: 'Start a live broadcast'
        , live_request_modify_title: 'To modify a information'
        , live_request_modify: 'Modify a information'
        , live_streaming_setting: 'Live streaming setup'
        , live_exit: 'End the live broadcast'
        , live_exit_txt: 'Would you like to end the live broadcast?'
        , live_chat_off_txt: 'Would you like to close the chat room?'
        , live_on: 'Start the streaming'
        , live_off: 'End the streaming'
        , chat_room_off: 'Close the chat room'
        , live_off_txt: 'The live chat has ended'
        , live_out: 'Exit the live broadcast'
        , live_out_btn: 'Exit'
        , live_out_txt: 'Are you sure you want to exit the live broadcast?'
        , upload_thumbnail: 'Please upload a thumbnail.'
        , upload_thumbnail_btn: 'Upload a thumbnail'
        , thumbnail_size: 'At least a ratio of 750px width to 500px height'
        , live_ready: 'It is being prepared.'
        , send_msg: 'send a message.'
        , live_compulsory_exit_title: 'Remove live chat participants'
        , live_compulsory_exit: 'Would you like to remove the selected member for using profanity, hate speech, explicit content, or inappropriate behavior?\nThis user is not allowed to re-enter.'
        , live_compulsory_exit_title_user: 'Remove from live chat'
        , live_compulsory_exit_user: 'You have been removed from this live chat due to inappropriate behavior.\nPlease contact the moderator for any inquiries.'
        , live_chat_participants: 'Live chat participants'
        , live_network_error: 'A network error has occurred. Try again.'
        , live_network_error_title: 'network error'
    }
    , live_chat: {
        chat_in: '채팅에 참여하였습니다.'
        , disconnect: '연결이 종료되었습니다.'
        , chat_off: '퇴장하셨습니다.'
        , modify_chat_info: '채팅방 설정이 변경되었습니다.'
        , compulsory_exit_user: '강퇴당하셨습니다.'
        , chat_out: '채팅방을 나갔습니다.'
    }

    , subscribe: {
        btn_subscribe_add: 'subscription board'
        , btn_subscribe_normal: 'general bulletin board'
        , title_subscribe_add: 'What is a subscription board?'
        , txt_subscribe_add_main: 'By delivering special or close content and offering special benefits to members who purchase subscription plans, cartels can generate revenue.'
        , txt_subscribe_add_sub: ''
        , title_subscribe_input_name: 'Subscription board name'
        , title_subscribe_permission_admin: 'Set Manager Permissions'
        , txt_subscribe_permission_admin: 'Give the operator permission to the cartel subscription board (registration, modification, deletion)'
        , title_subscribe_permission_open: 'Subscription Bulletin Public Settings'
        , txt_subscribe_permission_open: 'Disclosure of the entire metamafia'
        , title_subscribe_total_history: 'Subscription Consolidation History'
        , title_subscribe_plane_management: 'Manage subscription plans'
        , title_subscribe_use: 'Plan Sales'
        , txt_subscribe_complete: 'sSubscription is complete.'
        , title_subscribe_plane: 'Plan'
        , txt_subscribe_period: 'Period'
        , txt_is_plan_sale: 'Set the sales status of the plan'
        , title_plan_icon: 'Subscription Plan Icon'
        , title_plan_name: 'Subscription Plan Name'
        , title_plan_info: 'Subscription Plan Description'
        , txt_plan_info: 'Please enter a description of your subscription plan'
        , title_plan_price: 'Set Plan Pricing'
        , txt_plan_price: 'Enter Plan Price'
        , title_plan_fee: 'a planning fee'
        , title_plan_calculate_price: 'Plan Settlement Amount'
        , txt_plan_calculate_price: 'Please enter the estimated amount of the plan settlement'
        , select_payment_type: 'Select payment method when purchasing subscription plan'
        , title_plane_agree: '(Mandatory) Terms and Conditions of Sale'
        , txt_plane_agree: 'Additional taxes and withdrawal fees will be added when applying for settlement withdrawal. In the case of the plan fee, it may be changed according to the policy, and we will notify you 100 days before the change according to the terms and conditions.'
        , btn_plan_save: 'Save Plan'
        , btn_plan_confirm: 'Check the plan'
        , title_subscribe_member: 'Subscribers'
        , title_subscribe_member_finish: 'End Subscription'
        , title_subscribe_plan_list: 'Subscription Plan'
        , btn_subscribe_list: 'Payment details'
        , txt_subscribe_member_management: 'Take care of the members who are subscribed to'
        , title_subscribe_member_management: 'Managing Subscribers'
        , title_subscribe_plan_choice: 'Select applicable plan'
        , txt_subscribe_plan_choice: 'Only members of the selected plan level or higher can view this article'
        , title_subscribe_advanced: 'Advanced settings'
        , title_subscribe_load_text: 'Load Text'
        , title_subscribe_open: 'Text disclosure'
        , txt_subscribe_open: 'If you hide the body of this post, unauthorized members will not be able to see it.'
        , title_subscribe_open_comment: 'Comment public'
        , txt_subscribe_open_comment: 'If you hide comments on this post, unauthorized members will not be able to see them.'
    }
    , cartel_new: {
        title_support: 'Sponsoring'
        , title_support_history: 'Spon History'
        , title_support_history_detail: 'Spon Detail'
        , title_subscribe: 'Subscribe'
        , title_plane_management: 'Plan management'
        , title_subscribe_management: 'Subscribe management'
        , title_subscribe_permission: 'Check permissions'
        , txt_subscribe_permission_main: 'You do not have permission to open'
        , txt_subscribe_permission_sub: 'Buy a subscription plan for browsing rights on that board.'
        , title_plan_choice: 'Select subscription plan'
        , title_subscribe_history: 'Subscription History'
        , btn_subscribe: 'Subscribe'
        , title_finish: 'Finish'
        , error_not_subscribe_plan: 'There are no subscription plans in use.'
        , txt_subscribe_history_ok: 'Have a subscription history'
        , txt_subscribe_history_no: 'No subscription history'
        , title_cartel_subscribe: 'Subscription'
        , title_subscribe_plan_info: 'Plan Description'
        , select_payment: 'Select payment method'
        , error_not_payment: 'No payment method'
        , title_plane_now: 'Current Plan'
        , etc_end: ''
        , title_not_subscribe_plan: 'None'
        , title_plane_amount: 'Price'
        , title_plane_amount_upgrade: 'Upgrade Price'
        , title_plane_vat: 'VAT'
        , title_plane_total_price: 'Total Price'
        , title_notice: 'Notice'
        , txt_notice1: 'For subscription upgrade products, the remaining subscription period will be automatically canceled and the difference will be paid excluding refunds'
        , txt_notice2: 'This product is a subscription plan payment under the "Service Terms and Conditions", and the agreement terms and conditions apply'
        , txt_notice3: 'Due to the nature of the subscription product, you cannot withdraw your purchase'
        , txt_notice4: 'I agree with the subscription plan'
        , txt_plan1: 'Configure various subscription plans'
        , title_calculate: 'Subscription settlement'
        , txt_calculate: 'Get a subscription settlement.'
        , txt_finish_plan: 'End in days'
    }
    , point: {
        title_my_point: 'My Heart'
        , title_my_point_available: 'Available Bullet'
        , title_my_point_support: 'Sponsored Bullet'
        , title_my_quantity: 'Quantity'
        , txt_heart: 'Heart'
        , txt_purchase: 'Buying'
        , error_not_point_purchase: 'No point information available for purchase.'
        , error_not_credit: 'No credit card.'
        , title_heart_payment: 'Buy heart'
        , txt_heart_payment_ask: 'Would you like to buy ?'
        , btn_charge: 'Charge'
        , btn_list: 'List'
    }
    , point_result: {
        title_result: 'Heart charged complete'
        , title_payment_type: 'Payment Method'
        , none_payment_type: 'There are no registered payment methods.'
        , btn_confirm: 'OK'
        , title_payment_amount: 'Price'
        , title_payment_date: 'Date'
        , title_payment_number: 'Approval Number'
        , title_payment_detail: 'Detail'
        , title_infomation: 'Information'
        , title_subscribe_product: 'Subscription Products'
        , title_payment_information: 'Payment Information'
        , title_tid: 'TID'
        , title_payment_type_add: 'Add payment method'
        , btn_modify: 'Plan Modify '
        , title_payment_quntity: 'Quantity'
        , btn_add_list: 'Charge'
        , btn_use_list: 'Usage'
        , title_div: 'Sortation'
        , title_detail: 'Detail'
        , title_date: 'Date'
        , title_payment_status: 'Payment Status'
        , title_product: 'Product'
        , title_credit: 'Creidt'
        , title_payment_div: 'Trade classification'
        , txt_not_enough_point: 'Not enough point'
    }

    , add: {
        title_sponsor: 'Sponsor'
        , title_support_message: 'Message'
        , title_remove_file: 'Delete File'
        , txt_remove_file_main: 'Would you like to delete this attachment?'
        , txt_remove_file_sub: 'Deleted files cannot be recovered.'
        , txt_add_subscribe: 'Please create a subscription bulletin board first.'
        , swap: 'NFT SWAP'
        , title_swap: '윤지성 NFT'
        , title_swap_notice: 'Notice'
        , txt_swap_notice1: '· Swapped NFTs cannot be canceled due to their nature.'
        , txt_swap_notice2: '　Therefore, please check carefully before swapping'
        , txt_swap_notice3: '· Swapped NFTs are paid out randomly.'
        , txt_swap_notice4: 'I agree to the above product precautions. (Required)'
        , title_swap_result: 'SWAP complete'
        , txt_swap_result: 'You can check the NFT CARD you swapped in the NFT collection'
        , txt_not_plan: 'There is no registered plan information.'
    }

    , launchpad: {
        select_payment: 'Select payment method'
        , select_payment_input: 'Please select payment method'
        , select_card: 'Select credit card'
        , select_card_input: 'Please select credit card'
        , select_quantity: 'Select quantity'
        , last_payment_quantity: 'Final payment quantity'
        , last_payment_price: 'Final payment price'
        , payment_quantity: 'Payment quantity'
        , payment_price: 'Payment price'
        , vat: 'VAT'
        , basic_discount: 'Basic discount'
        , purchase_guide_title: 'Purchase Guide and Terms/Important Notices'
        , purchase_guide_1: 'When purchasing NFT credit cards, value-added tax (VAT) may apply.'
        , purchase_guide_2: '"For purchased NFTs, refunds are not possible.'
        , purchase_guide_3: 'NFTs are available for purchase only during specific times, and afterward, they can be acquired through the respective cartel.'
        , purchase_guide_4: 'Purchased NFTs can be viewed in your NFT collection.'
        , visit_cartel: 'Visit the cartel'
        , currently: 'Currently,'
        , have_been_sold: ' have been sold'
        , txt_purchase: 'Purchase'
        , txt_register: 'COMING SOON'
        , txt_coming_soon: 'COMING SOON'
        , txt_minting_ended: 'ended'
        , minting_ended: 'ENDED'
        , view: 'View'
        , selling_price: 'PRICE'
        , txt_nft_result: 'You can view the MAFIA CARD you purchased in your NFT collection.'
    }

    , voting: {
        title_voting_board: 'VOTING 게시판 '
        , title_voting_board_add: '보팅 게시판 추가'
        , title_voting_board_manage: 'VOTING 게시판 관리'
        , title_voting_board_delete: 'VOTING 게시판 삭제'
        , txt_voting_board_delete: 'VOTING 게시판 삭제 시 등록된 글도 삭제가 됩니다.'
        , txt_voting_board_delete2: '게시판을 삭제하시겠습니까?'
        , txt_voting_board_delete3: '삭제를 원하시면 삭제버튼을 눌러주세요'
        , title_voting_board_title: 'VOTING 게시판 이름'
        , title_voting_board_manager_permission: '운영자 권한 설정'
        , txt_voting_board_manager_permission: '운영자에게 카르텔 보팅등록, 수정 권한을 줍니다'
        , title_voting_board_open: '보팅 게시판 공개 설정'
        , txt_voting_board_open: '카르텔에 가인됩 회원에게만 게시판을 공개합니다'
        , title_voting_alram: 'VOTING 알림'
        , txt_voting_alram_ing: '신규, 참여한 보팅 종료 알림'
        , title_voting_cancel: '신규 보팅 취소'
        , txt_voting_cancel: '신규 보팅 등록을 취소 하시겠습니까?'
        , txt_voting_cancel2: '취소를 원하시면 확인버튼을 눌러주세요'
        , title_voting_title: '보팅 제목'
        , txt_voting_title: '제목을 입력하세요'
        , title_voting_contents: '보팅 내용'
        , txt_voting_contents: '내용을 입력하세요'
        , title_voting_period: '보팅 기간'
        , title_voting_period_start: '시작일'
        , title_voting_period_ed: '종료일'
        , title_voting_permission: 'VOTING 참여 권한'
        , txt_voting_permission: '보팅에 참여할 수 있는 회원을 설정합니다'
        , txt_voting_permission2: '기본은 전체로 설정됩니다'
        , title_voting_multiple: '복수 선택'
        , txt_voting_multiple: '보팅시 복수선택이 가능합니다'
        , title_voting_comment_able: '댓글 작성'
        , txt_voting_comment_able: '보팅에 댓글이 작성 가능합니다'
        , title_voting_anonymous: '익명 투표'
        , txt_voting_anonymous: 'VOTING 참여자를 노출하지 않습니다.'
        , title_voting_rate: 'VOTING 참여율 표기'
        , txt_voting_rate1: '투표수료 표기'
        , txt_voting_rate2: '투표율로 표기'
        , txt_voting_rate3: '투표율 숨김'
        , title_voting_reword: 'VOTING 참여 보상'
        , txt_voting_reword: '보팅 참여자에 한해 보상을 제공합니다'
        , title_voting_list: 'VOTING 항목'
        , txt_voting_list_empty: '보팅 항목이 없습니다'
        , txt_voting_list_empty2: '보팅 항목은 2개이상 등록하세요'
        , title_voting_add: '보팅 항목 추가'
        , txt_voting_add: '보팅 항목 입력'
        , title_voting_add_contents: '상세 내용'
        , txt_voting_add_contents: '보팅 항목의 상세내용 및 동영상등을 첨부할 수 있습니다.'
        , title_voting_add_file: '첨부파일 상세'
        , txt_voting_add_file: '첨부파일은 하나만 등록가능합니다'
        , btn_voting_add_file_img: '사진 첨부'
        , btn_voting_add_link: '동영상 링크'
        , title_voting_add_link: '동영상 URL 입력'
        , txt_voting_add_link: '동영상 URL을 입력하세요'
        , txt_voting_add_file_preview: '미리보기'
        , title_voting_item_contents: 'VOTING 항목 설명'
        , txt_voting_item_contents: 'VOTING 항목 설명을 입력하세요'
        , title_voting_social_url: '소셜 URL'
        , txt_voting_social_url: 'URL을 입력하세요'
        , txt_voting_social_max: '소셜URL은 최대 10개까지만 등록가능합니다'
        , title_voting_social_select: '소셜 아이콘 선택'
        , error_voting_add_file: '첨부파일을 입력하세요'
        , error_voting_add_contents: '설명을 입력하세요'
        , title_voting_item_modify: 'VOTING 항목 수정'
        , sub_voting_item_modify: '보팅 항목 수정'
        , title_voting_item_delete: 'VOTING 항목 삭제'
        , sub_voting_item_delete: '보팅 항목 삭제'
        , txt_voting_item_delete: '보팅 항목을 삭제 하시겠습니까'
        , title_voting_item_add: 'VOTING 항목 등록'
        , txt_voting_item_add: 'VOTING을 등록하시겠습니까'
        , txt_voting_item_add2: '등록된 보팅은 수정시 제약을 받습니다'
        , txt_voting_item_add3: '확인 후 등록하세요'
        , txt_voting_period: '보팅이 종료되었습니다'
        , txt_voting_period2: 'VOTING 기간은 현재시간 이후로 설정하세요'
        , title_voting: 'VOTING'
        , title_voting_new: '신규 VOTING'
        , title_voting_new_setting: '신규 VOTING 설정'
        , txt_voting_new: '메인 커버사진을 등록하세요'
        , txt_voting_new2: '커버 사진은 VOTING 목록에서 노출됩니다'
        , title_voting_modify: 'VOTING 수정'
        , title_voting_finish: 'VOTING 종료'
        , sub_voting_finish: '보팅 종료'
        , title_voting_delete: 'VOTING 삭제'
        , sub_voting_delete: '보팅 삭제'
        , txt_voting_delete: '해당 보팅을 삭제하시겠습니까?'
        , txt_voting_delete1: '보팅 기간이 남았습니다'
        , txt_voting_delete2: '보팅을 종료하시겠습니까?'
        , tab_voting_status1: '진행중'
        , tab_voting_status2: '예약'
        , tab_voting_status3: '종료'
        , tab_voting_status4: '임시저장'
        , title_voting_hidden: 'VOTING 숨김'
        , txt_voting_hidden1: '투표를 멤버에게 숨김'
        , txt_voting_hidden2: '투표를 멤버에게 노출'
        , title_voting_count: '보팅 참여자'
        , txt_voting_order1: '시간순'
        , txt_voting_order2: '닉네임순'
        , title_voting_temp: '임시저장'
        , txt_voting_temp1: '임시저장된 VOTING 불러오기'
        , txt_voting_temp2: '임시저장된 VOTING 삭제'
        , title_voting_temp_delete: '임시저장 VOTING 삭제'
        , txt_voting_temp_delete: '임시저장된 VOTING을 삭제하시겠습니까?'
        , txt_voting_unable: 'VOTING에 참여할 수 없는 등급입니다'
        , notify_voting_complete: 'VOTING참여가 완료되었습니다'
        , notify_voting_already: '이미 참여한 투표입니다'
        , tab_voting_now: '진행중'
        , tab_voting_end: '종료'
        , txt_voting_info: '보팅 결과는 '
        , txt_voting_info2: '까지 게시됩니다'
        , txt_voting_cover_image: '보팅 메인 커버사진을 추가하세요'
        , txt_voting_cover_image2: '커버사진은 목록에서 노출됩니다'
        , title_vote_complete: '투표 완료'
        , title_vote_doing: 'VOTE'
        , title_voting_detail_contents: '상세보기'
        , title_voting_detail_members: '참여자'
    }

    , shorts: {
        title_shorts: '숏플'
        , title_shorts_item: '숏플 동영상 업로드'
        , txt_item_info: '1분 이내의 동영상을 업로드 하고 게시하세요'
        , btn_upload: '동영상 업로드'
        , title_shorts_contents: '숏플 내용'
        , error_shorts_contents: '숏플 내용을 입력하세요'
        , txt_shorts_success: '숏플이 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
        , txt_shorts_success2: '동영상이 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
        , txt_shorts_contents: '140자 내로 내용을 입력하세요'
        , title_shorts_cover: '커버수정'
        , title_shorts_option: '고급설정'
        , title_my_shorts: '등록 숏플'
        , title_my_shorts_detail: '등록 숏플 상세'
        , title_my_cartel: '가입 카르텔'
        , subject_ing: '게시중'
        , subject_fail: '게시 실패'
        , subject_shorts_search: '숏플 검색'
        , txt_not_list: '등록된 숏플이 없습니다.'
    }

    , tv:{
        title_tv: '마피아 TV'
        , title_tv_manage: '마피아 TV 관리'
        , subject_tv_list: '등록 마피아 TV'
        , txt_tv_list: '등록된 영상을 관리합니다'
        , subject_tv_permission: '마피아TV 권한 설정'
        , txt_tv_permission: '운영자 작성 권한 및 공개 여부를 설정합니다'
        , txt_tv_list_search: '마피아TV검색'
        , txt_tv_list_count: '등록된 '
        , txt_tv_list_movie: '동영상 '
        , title_tv_item: '마피아 TV 동영상 업로드'
        , subject_tv_upload: '동영상을 업로드하고 게시하세요'
        , btn_upload: '동영상 업로드'
        , title_tv_title: '마피아 TV 제목'
        , txt_tv_title: '제목을 입력하세요'
        , title_tv_contents: '마피아 TV 내용'
        , txt_tv_contents: '내용을 입력하세요'
        , txt_tv_success: '영상을 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
        , txt_tv_success2: '동영상이 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
        , title_tv_edit: '마피아 TV 수정'
        , title_tv_search: '마피아 TV 검색'
        , btn_series: '시리즈'
        , btn_alone: '단편'
        , title_tv_series: '마피아 TV 시리즈'
        , txt_tv_series_title: '시리즈 제목'
        , txt_tv_series_contents: '시리즈 내용'
        , title_tv_series_detail: '시리즈 상세'
        , title_tv_episode: '에피소드'
        , title_tv_episode_sort: '순서변경'
        , txt_tv_upload: '마피아TV에 어떤 동영상을 올리고 싶으신가요?'
        , title_tv_upload_alone: '한편의 영상만 올린다면'
        , txt_tv_upload_alone: '단편 동영상'
        , title_tv_series_delete: '시리즈 삭제'
        , txt_tv_upload_series: '여러개의 스토리 동영상을 올린다면'
        , title_tv_upload_series: '시리즈 동영상'
        , txt_tv_upload_series_select: '시리즈를 선택하세요'
        , txt_tv_series_delete: '선택한 시리즈를 삭제 하겠습니까?'
        , txt_tv_series_delete_sub: '삭제 시 포함된 동영상도 삭제 됩니다.'
        , txt_tv_upload_info: '신규 시리즈를 추가하게 되면 시리즈 별로 동영상을 올릴 수 있습니다. 시리즈로 등록된 영상은 마피아TV 내 시리즈, 단편 두 곳에서 확인이 가능합니다.'
        , title_tv_series_upload: '시리즈 영상 업로드'
        , txt_tv_series_upload: '추가하신 시리즈에 영상을 업로드 할 준비가 완료 되었습니다. 영상을 업로드 하시겠습니까?'
        , btn_tv_series_after: '나중에'
        , btn_tv_series: '업로드'
    }

    , calculate: {
        title_calculate: '카르텔 정산'
        ,
    }

    , home: {
        txt_after_login: '가입 카르텔에서 새로운 소식을 확인하세요'
    }


}