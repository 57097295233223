<template>
	<div class="bg_dark">
		<div class="flex_wrap">
			<div class="header">
				<div class="intro_logo" >
					<div class="logo"><span class="hide">Culture M.A.F.I.A.</span></div>
				</div>
			</div>
			<div class="password_area">
				<div class="container">
					<div class="row">
						<div class="text_guide">
							<h4>{{ pin_text }}</h4>
						</div>
						<div class="password">
							<span class="hide">{{ $language.auth.password_message_01 }}</span>
							<!-- TODO : 비밀번호 입력 시 .on 클래스 추가 -->
							<span
								v-for="(item, index) in pinForType"
								:key="'pin_' + index"
								class="word"
								:class="{ on: item }"
							></span>
						</div>
						<div class="text_guide_desc">
						</div>
					</div>
				</div>
			</div>

			<div class="password_keyboard">
				<div class="container">
					<table class="keyboard">
						<tbody>
						<tr
							v-for="num in 3"
							:key="'num_' + num"
						>
							<td
								v-for="num_s in 3"
								:key="'num_' + num + '_' + num_s"
							>
								<button type="button" class="key" @click="setPin(num_s + (3 * (num - 1)))">
									<span>{{ num_s + (3 * (num - 1)) }}</span>
								</button>
							</td>
						</tr>
						<tr>
							<td>
								<button
									v-if="is_can_cancel"
									type="button" class="key" @click="cancel"
								>
									<span class="cancel">{{ $language.common.cancel }} <!-- 취소 --></span>
								</button>
								<button
									v-else-if="(options.pin_type == 'set' || options.pin_type == 'join') && is_confirm"
									type="button" class="key" @click="reset"
								>
									<span class="cancel"><v-icon class="color-white">mdi-refresh</v-icon></span>
								</button>
							</td>
							<td>
								<button type="button" class="key" @click="setPin(0)">
									<span>0</span>
								</button>
							</td>
							<td>
								<button type="button" class="key" @click="back()">
									<span class="ico_del"><em class="hide">{{ $language.common.clear }}</em></span>
								</button>
							</td>
						</tr>
						</tbody>
					</table>
					<div
						v-if="is_change"
						class="find_link"
					>
						<a @click="onOtp">
							{{ $language.auth.question_pin }} <!-- 혹시 PIN번호를 잊으셨나요? --> <i class="icon-arrow-right"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>

export default {
	name: 'PIN'
	, props: ['options', 'user', 'params']
	, components: {  }
	, data: function(){
		return {
			program: {
				name: this.$language.auth.security_pin
				, not_header: true
				, not_footer: true
				, wrap_type: 'flex'
			}
			, step: 0
			, step_confirm: 0
			, max: 5
			, pin: [false, false, false, false, false, false]
			, pin_confirm: [false, false, false, false, false, false]
			, is_confirm: false
			, is_otp: false
			, is_can_cancel: this.options.is_can_cancel
		}
	}
	,computed: {

		pinForType: function(){

			if(this.is_confirm){
				return  this.pin_confirm
			}else{
				return this.pin
			}
		}
		, pin_type: function(){
			let type = 'set'

			if(this.options){
				type = this.options.pin_type
			}else if(this.$route.params.pin_type){
				type = this.$route.params.pin_type
			}

			console.log('pin_type', type)
			return type
		}
		, is_change: function(){
			let t = false

			//if(this.pin_type == 'check' || this.pin_type == 'pin_login'){
			if(this.pin_type == 'pin_login'){
				t = true
			}

			return t
		}
		, pin_text: function(){
			let t = ''

			if(this.pin_type == 'set'){
				if(this.is_confirm){
					t = this.$language.auth.request_pin_01
				}else{
					t = this.$language.auth.request_pin_02
				}
			}else if(this.pin_type == 'update'){
				if(this.is_confirm){
					t = this.$language.auth.request_pin_01
				}else {
					t = this.$language.auth.request_pin_03
				}
			}else if(this.pin_type == 'lost'){
				if(this.is_confirm){
					t = this.$language.auth.request_pin_01
				}else {
					t = this.$language.auth.request_pin_03
				}
			}else if(this.pin_type == 'join'){
				if(this.is_confirm){
					t = this.$language.auth.request_pin_01
				}else {
					t = this.$language.auth.request_pin_03
				}
			}else{
				t = this.$language.auth.request_pin_04
			}

			return t
		}
		, entry_cartl_number: function(){
			let t = []
			this.params.entry_cartl_number.filter( (item) => {
				t.push(item.cartl_number)
			})
			return t
		}
	}
	,methods: {

		setPin: function(number){
			// console.log(number)
			if(this.is_confirm){
				if (!this.pin_confirm[this.step_confirm]) {
					this.$set(this.pin_confirm, this.step_confirm, number + '')
					this.step_confirm++
				}
			}else {
				if (!this.pin[this.step]) {
					this.$set(this.pin, this.step, number + '')
					this.step++
				}
			}
		}
		,back: function(){
			if(this.is_confirm) {
				this.step_confirm--
				this.$set(this.pin_confirm, this.step_confirm, false)
			}else {
				this.step--
				this.$set(this.pin, this.step, false)
			}
		}
		, reset: function(){
			this.is_confirm = false
			this.step = 0
			this.step_confirm = 0
			this.pin = [false, false, false, false, false, false]
			this.pin_confirm = [false, false, false, false, false, false]
		}
		,clear: function() {
			if(this.is_confirm){
				this.pin_confirm = [false, false, false, false, false, false]
				this.step_confirm = 0
			}else{
				this.pin = [false, false, false, false, false, false]
				this.step = 0
			}
		}
		, cancel: function(){
			console.log('this.pin_type', this.pin_type)
			switch(this.pin_type){
				default:
				case 'set': case 'lost': case 'join': case 'pin_login':
					this.clear()
					this.$bus.$emit('logout')
					this.$emit('cancel')
					break
				case 'update':
					this.$emit('cancel')
					break
				case 'check':
					this.$emit('cancel')
					break
			}
		}


		/*
		* MEM-04-003 핀번호 저장 요청
		* 회원가입시 요청
		*
		* */
		, postPin: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin
					, data: {
						pinnumber: this.pin.toString().replaceAll(',', '')
						, confirmation_pinnumber: this.pin_confirm.toString().replaceAll(',', '')
						, entry_cartl_number: this.entry_cartl_number
					}
					,type: true
				})

				if(result.success){
					this.$emit('click', {type: 'result', params: result.data})
				}else{
					throw result.message
				}

			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.reset()
			}
		}
		/*
		* MEM-22-003 핀번호 분실 저장
		*
		* */

		, postPinBeforLogin: async function(){
			console.log('postPinBeforLogin')

			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_befor_login
					, data: {
						pinnumber: this.pin.toString().replaceAll(',', '')
						, confirmation_pinnumber: this.pin_confirm.toString().replaceAll(',', '')
					}
					,type: true
				})

				if(result.success){
					this.$emit('click')
					this.$bus.$emit('getDeviceToken')
				}else{
					throw result.message
				}

			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.reset()
			}
		}
		/*
		* MEM-05-002 로그인 핀번호 확인 요청
		*
		* */
		, postPinLogin: async function(){
			console.log('postPinLogin')

			let certfc = this.$encodeStorage.getItem('login_info')
			let pin_token = this.$encodeStorage.getItem('pin_token')
			let pin_sesison_token = this.$encodeStorage.getItem('pin_session_token')
			let url = this.$api_url.api_path.post_pin_login
			if(certfc && certfc.member_number && pin_token){
				url = this.$api_url.api_path.post_pin_token_login
			}else{
				await this.$encodeStorage.removeItem('pin_session_token')
			}
			let data = {
				member_number: certfc.member_number
				, pinnumber: this.pin.toString().replaceAll(',', '')
				, pin_token: pin_token

			}
			if(pin_token && pin_sesison_token){
				data.pin_certfc_session_token = pin_sesison_token
			}
			try{
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: data
					, type: true
				})

				if(result.success){

					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					await this.$encodeStorage.setSessionAt(result.data)
					await this.$encodeStorage.setItem('pin_token', result.data.pin_token)
					// await this.$encodeStorage.setItem('login_info', result.data)
					await this.$encodeStorage.removeItem('pin_session_token')

					//await this.$router.push({ name: 'main'})
					this.$bus.$emit('setUser', result.data)
					setTimeout( () => {
						this.$router.push(this.options.to ? this.options.to : { name: 'main', href: true})
					}, 500)
				}else{
					throw result
				}

			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e.message})
				if(e.code == 'E000100032' || e.code == 'E000500013'){
					this.cancel()
				}else if(e.code == 'E000400038'){
					await this.$encodeStorage.setItem('pin_session_token', e.data.pin_certfc_session_token)
				}
			}finally {
				this.reset()
			}
		}

		// MEM-21-002 구글 OTP 변경 핀번호 확인
		, postOtpPinCheck: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_otp_pin_check
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.pin.toString().replaceAll(',', '')
					}
					, type: true
				})
				if(result.success){
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.clear()
			}
		}
		// MEM-17-001 핀 번호 변경 핀 인증
		, postPinCheck: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.pin.toString().replaceAll(',', '')
					}
					, type: true
				})
				if(result.success){
					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					this.$emit('click', this.pin.toString().replaceAll(',', ''))
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.clear()
			}
		}

		, postPinUpdate: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_update
					, data: {
						member_number: this.user.member_number
						, chg_pinnumber: this.pin.toString().replaceAll(',', '')
						, chg_pinnumber_confirmation: this.pin_confirm.toString().replaceAll(',', '')
					}
					, type: true
				})
				if(result.success){
					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					this.$emit('click', this.pin.toString().replaceAll(',', ''))
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.reset()
			}
		}
		, onOtp: function(){
			this.$bus.$emit('offPin')
			this.$router.push({ name: 'pin_sms'})
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
	,watch: {
		step: {
			handler: function(call){
				if(call > this.max){
					this.step = this.max
				}
				if(call < 0){
					this.step = 0
				}
			}
		}
		,step_confirm: {
			handler: function(call){
				if(call > this.max){
					this.step_confirm = this.max
				}
				if(call < 0){
					this.step_confirm = 0
				}
			}
		}
		,pin: {
			deep: true
			,handler: function(call){
				// console.log('watch pin', call)
				if(this.pin_type == 'set' || this.pin_type == 'join' || this.pin_type == 'update' || this.pin_type == 'lost' || this.pin_type == 'pin_modify_before_login'){

					let is_set = false
					call.forEach(function(val){
						if(val === false){
							is_set = false
							return false
						}else{
							is_set = true
						}
					})

					if(is_set) {
						this.is_confirm = true
					}
				}else if(this.pin_type == 'pin_login'){

					let is_set = false
					call.forEach(function(val){
						// console.log(val + ' : ' + typeof val)
						if(val === false){
							is_set = false
							return false
						}else{
							is_set = true
						}
					})

					if(is_set) {
						this.postPinLogin()
					}
				}else if(this.pin_type == 'check' || this.pin_type == 'otp_pin_check'){
					let is_set = false
					call.forEach(function(val){
						// console.log(val + ' : ' + typeof val)
						if(val === false){
							is_set = false
							return false
						}else{
							is_set = true
						}
					})

					if(is_set) {
						if(this.pin_type == 'otp_pin_check'){
							this.postOtpPinCheck()
						}else{
							this.postPinCheck()
						}
					}
				}
			}
		}
		,pin_confirm: {
			deep: true
			,handler: function(call){

				if(this.is_confirm){

					let is_set = false
					call.forEach(function(val){
						if(!val){
							is_set = false
							return false
						}else{
							is_set = true
						}
					})

					if(is_set) {
						if(this.pin.toString() == this.pin_confirm.toString()){
							if(this.pin_type == 'update') {
								this.postPinUpdate()
							}else if(this.pin_type == 'pin_modify_before_login'){
								this.postPinBeforLogin()
							}else if(this.pin_type == 'lost'){
								this.$emit('click',  this.pin.toString().replaceAll(',', ''),  this.pin_confirm.toString().replaceAll(',', ''))
							}else{
								this.postPin()
							}
						}else{
							this.$bus.$emit('notify',  { type: 'error', message: this.$language.auth.request_pin_rematch })
							this.clear()
						}
					}
				}
			}
		}
	}
}
</script>