<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="logo_top">
					<img :src="require('@/assets/image/logo_60.png')" alt="마피아">
					<div class="text_guide pt-10">
						<!--						<h4>MAFIA에<br>
													관심 있는 분야를 선택해주세요.</h4>-->
						{{ $language.interested.select }}
					</div>
				</div>
				<ul class="interest_list">
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400001"
								type="checkbox" id="music">
							<label for="music">
								<strong>Music</strong>
								<p>{{ $language.interested.music }}</p>
							</label>
						</div>
					</li>
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400002"
								type="checkbox" id="art">
							<label for="art">
								<strong>Art</strong>
								<p>{{ $language.interested.art }}</p>
							</label>
						</div>
					</li>
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400003"
								type="checkbox" id="fashion">
							<label for="fashion">
								<strong>Fashion</strong>
								<p>{{ $language.interested.fashion }}</p>
							</label>
						</div>
					</li>
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400004"
								type="checkbox" id="influence">
							<label for="influence">
								<strong>Influence</strong>
								<p>{{ $language.interested.influence }}</p>
							</label>
						</div>
					</li>
				</ul>
				<div class="intro_btn_wrap mt-30 pb-30">
					<div class="btn_area">
						<button class="btn_l btn_fill_blue" @click="toNext">{{ $language.common.next }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Fav'
	,components: {},
	props: ['Axios']
	, data: function(){
		return {
			program: {
				name: this.$language.interested.title
				, not_header: true
				, not_footer: true
			}
			,item: {
				fav: []
			}
			, items_fav: []
		}
	}
	,methods:{
		toNext: async  function(){
			try{

				if(!this.item.fav.length){
					throw this.$language.interested.select_02
				}

				let data = []
				this.item.fav.forEach((v) => data.push({ concern_sphere_code: v}))

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_concernsphere
					, data: {
						concern_sphere_list: data
					}
					, type: true
				})

				if(result.success){
					this.$emit('click', { type: 'cartel', params: {concern_sphere_list: data}})
				}else{
					if(result.code == 'E000400016'){
						this.$bus.$emit('to', { name: 'nice'})
					}else if(result.code == 'E000400043'){
						this.$bus.$emit('to', { name: 'sms'})
					}else{
						throw result.message
					}
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
	}
	,created() {
	}
}
</script>