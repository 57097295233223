<template>
	<div class="bg_dark  ">
		<div class="full-height">
			<div class="container  ">
				<div class="row  ">
					<div
						v-if="false"
						class="logo_top"
					>
						<img :src="require('@/assets/image/logo_60.png')" alt="MAFIA">
						<div class="text_guide pt-10">
							<h4 class="mt-10 text-center size-px-16">{{ $language.nice.nickName_message_01 }} <!-- MAFIA에서 활동할 닉네임을 설정하세요 --></h4>
							<span class="alert_info error pt-10">{{ $language.nice.nickName_message_02 }} <!-- 닉네임은 설정 후 변경이 불가능합니다 --> </span>
						</div>
					</div>
					<div class=" ">
						<div
							class="logo_top"
						>
							<div class="text_guide pt-10">
								<h4 class="text-left size-px-24 font-weight-bold">안녕하세요 :)</h4>
								<span class="text-left mt-10 alert_info color-white size-px-18 font-weight-bold">닉네임을 입력하세요</span>
							</div>
						</div>
						<div class="pt-30 ">
							<div class="dark_input input_count">
								<input
									v-model="account.nick"
									type="text" :placeholder="`${$language.common.nickName} ${$language.common.required}`"  maxlength='20'
									:rules="[$rules.trim(account, 'nick'), $rules.id(account, 'nick')]"
								>
								<!-- TODO : 텍스트 수 카운트 필요 -->
								<span class="input_text_count">{{ account.nick.length }}/20</span>
							</div>
							<div class="dark_input input_count mt-10">
								<input
									v-model="account.memo"
									type="text" :placeholder="$language.common.statusMessage"  maxlength='30'>
								<!-- TODO : 텍스트 수 카운트 필요 -->
								<span class="input_text_count">{{ account.memo.length }}/30</span>
							</div>
							<template
								v-if="false"
							>
								<div class="dark_input input_count mt-10">
									<input
										v-model="account.email"
										type="text" :placeholder="`${$language.common.email} `"  maxlength='30'>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ account.email.length }}/30</span>
								</div>
								<div class="mt-10 size-px-14 color-gray text-right hide">{{ $language.common.email_txt }}</div>
							</template>
							<div class="intro_btn_wrap mt-30 pb-30">
								<div class="btn_area"  style="height: 50px">
									<button class="btn_l btn_fill_blue" @click="postAccount">{{ $language.common.next }}</button>
								</div>
								<form
									style="display: none"
									name="form_chk" method="get">
									<input type="hidden" name="m" value="checkplusService">     <!-- 필수 데이타로, 누락하시면 안됩니다. -->
									<input type="hidden" name="EncodeData" value="">  <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
									<input type ="hidden" name="recvMethodType" value ="get">
									<!--
									<a href="javascript:fnPopup();"> CheckPlus 안심본인인증 Click</a>
									-->
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<PIN
			v-if="is_pin"
			:options="pin_option"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%"

			@click="pinClick"
			@cancel="pinCancel"
		></PIN>

	</div>
</template>

<script>

import PIN from "@/view/Auth/mafia007";

export default {
	name: 'Profile'
	, props: ['Axios', 'params']
	, components: {PIN}
	, data: function(){
		return {
			program: {
				name: this.$language.nice.nice_auth_title
				, not_header: true
				, not_footer: true
			}
			,account: {
				nick: ''
				, memo: ''
				, auth: ''
				, HPNO: ''
				, email: ''
			}
			,is_pin: false
			,pin_option: {
				pin_type: 'set'
			}
		}
	}
	,methods: {
		postAccount: async function(){
			try {
				if (!this.account.nick || !this.account.memo) {
					throw this.$language.nice.nice_auth_title_txt
				}

				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_account
					, data: {
						nickname: this.account.nick
						, state_message: this.account.memo
						, email: this.account.email
						, agree_stip_list: this.params.stip_list
					}
					, type: true
				})

				if(result.success){
					this.$emit('click', { type: 'fav', params: result.data})
				}else{
					if(result.code == 'E000400017'){
						this.$emit('click', { type: 'sms'})
					}else{
						throw result.message
					}
				}

			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postAccount2: async function () {


			try {

				if(!this.account.nick || !this.account.memo){
					throw this.$language.nice.nickName_message_03
				}
				const result = await this.$Request({
					method: 'post'
					, url: 'https://nice.reappay.net/check-pay-plus/request'
					, header: {
						'Content-Type': 'application/json; charset=utf-8'
					}
					, isResult: true
				})

				if (result.success) {

					this.fnPopup(result.data.enc_data)

					let self = this

					window.addEventListener('message', function (e) {
						console.log('on message !!')
						for(let ee in e){
							console.log(ee, e[ee])
						}
						const call = e.data

						if (call.success) {
							self.$set(self.account, 'auth', call.payload)
							self.$set(self.account, 'HPNO', call.phone_no)

							console.log(self.account)

							self.isSmsConfirm = true
							self.isCode = true

							let join_info = localStorage.getItem('join_info')
							if(!join_info){
								self.$bus.$emit('notify',  {type: 'error', message: this.$language.auth.no_sign_up_info})
							}
							join_info = JSON.parse(join_info)
							join_info.nick = self.account.nick
							join_info.memo = self.account.memo
							localStorage.setItem('join_info', JSON.stringify(join_info))
							self.is_pin = true

						} else {
							self.isSmsConfirm = false
							self.isCode = false
							self.$bus.$emit('notify',  {type: 'error', message: call.resp_message})

						}
					})
				} else {
					this.isSmsConfirm = false
					this.isCode = false
					this.$bus.$emit('notify',  {type: 'error', message: result.data.resp_message})
				}

			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				//
			}
		}
		, fnPopup(encode) {
			window.name = "Parent_window";
			let w = window.open('', 'popupChk', 'height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
			document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
			document.form_chk.target = "popupChk";
			document.form_chk.EncodeData.value = encode
			console.log('document.form_chk.EncodeData', document.form_chk.EncodeData.value)
			document.form_chk.submit();

			return w
		}
		,pinClick: async function(){
			let data = localStorage.getItem('join_info')
			if(!data){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.auth.no_sign_up_info})
				this.$emit('to', { name: 'join'})

				return false
			}

			data = JSON.parse(data)
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: ''
					,data: data
				})

				if(result.success){
					this.$emit('to', { name: 'mafia010'})
				}else{
					throw result.message
				}
				this.is_pin = false
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)

				console.log(this.item)

				if(process.env.VUE_APP_TYPE == 'sample'){
					console.log('do sample')
					let result = await this.$sample.postJoin(data)
					console.log('finish sample')
					console.log(result)
					if(result.success){
						localStorage.setItem('account', JSON.stringify(result.data))
						this.$emit('to', { name: 'mafia010'})
						this.is_pin = false
					}else{
						this.$bus.$emit('notify',  { type: 'error', message: result.message })
					}
				}
			}
			this.pin_option.pin_type = 'set'
		}
		,pinCancel: function(){
			console.log('cancel')
			this.is_pin = false
			this.pin_option.pin_type = 'set'
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		console.log('this.params', this.params)
	}
}
</script>