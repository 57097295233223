
import { language } from '@/resources/lang/kr/common'
import {Axios} from "@/resources/axios/axios";
import {sample} from "@/resources/sample/sample"

export const request = {
    language: language,
    Axios: Axios,
    sample: sample,

    upload_url: function(file_name){
        //console.log('file_name', file_name)
        if(!file_name){
            return ''
        }
        if(file_name.indexOf('https') > -1){
            return file_name
        }
        if(file_name == 'BC00200001'){
            return require('@/assets/image/coin_ETH.png')
        }else if(file_name == 'BC00200002'){
            return require('@/assets/image/coin_klay.png')
        }else if(file_name == 'BC00200003'){
            return require('@/assets/image/coin_mafi.png')
        }else if(file_name == 'BC00200004'){
            return require('@/assets/image/coin_mafi.png')
        }else if(file_name == 'BC00200005') {
            return require('@/assets/image/coin_reap.png')
        }else if(file_name == 'BC00200006') {
            return require('@/assets/image/coin_POLYGON.png')
        }
        if(file_name.indexOf('data:image') > -1){
            return file_name
        }

        if(process.env.VUE_APP_TYPE == 'sample'){
            try{
                return require('@/assets/image/' + file_name)
            }catch (e) {
                console.log(e)
                return ''
            }
        }else{
            return require(file_name)
        }
    },

    init: async function({ method, url, data, header, authorize, multipart, TOKEN, blob, isResult, name, type, is_data, credent, origin, form_data }){
        try {

            // console.log('request init: ', method, url, data, name, type)
            /*
            for(let [key, val] of Object.entries(data)){
                console.log(key, val)
            }

             */
            if(!type && process.env.VUE_APP_TYPE == 'sample'){
                if(method == 'get'){
                    let result = this.sample.getSampleData(data)
                    // console.log('request result', result)
                    return result
                }else{
                    if(typeof this.sample[name] === 'function'){
                        return this.sample[name](data)
                    }else{
                        throw 'not method ' + method
                    }
                }

            }else{
                // console.log(' do axios ')
                return Axios({ method, url, data, header, authorize, multipart, TOKEN, blob, isResult, is_data, credent, origin, form_data })
            }
        }catch (e) {
            // console.log('init', e)
            return { success: false, message: e}
        }
    }
    , set: function(obj, arg){

        let t = ''
        if(obj){
            t = obj[arg]
        }
        return t
    }
}

export async function Request({method, url, data, header, authorize, multipart, TOKEN, blob, isResult, name, type, is_data, credent, origin, form_data}){
    try {

        let result = await request.init({
            method: method
            , url: url
            , data: data
            , header: header
            , authorize: authorize
            , multipart: multipart
            , TOKEN: TOKEN
            , blob: blob
            , isResult: isResult
            , name: name
            , type: type
            , is_data: is_data
            , credent: credent
            , origin: origin
            , form_data: form_data
        })
//         console.log('result', result)
        if(isResult){

            return {success: true, data: result.data}
        }else{

            if(result.success){

                result = result.result
                const data = result.data
                const header = result.headers

                let message = data.resultinfo.result_msg ? data.resultinfo.result_msg : data.resultinfo.result_div_cd == 'OK' ? '' : '요청 처리 실패'

                if(data.resultinfo.result_div_cd != 'OK'){
                    // console.log('axios result', getUrl(), JSON.stringify(getParams()), JSON.stringify(getData()), message)
                    console.log('error cd', data.resultinfo.result_cd)
                    if(data.resultinfo.result_cd.indexOf('E000100001') > -1) {
                        this.$bus.$emit('onUnauthorized')
                    }else if(data.resultinfo.result_cd.indexOf('E00010004') > -1 || data.resultinfo.result_cd.indexOf('E00010004') > -1) {
                        this.$bus.$emit('onVersion', {
                            success: false
                            , data: data.d
                            , message: message
                            , header: header
                            , code: data.resultinfo.result_cd
                        })
                        return false
                    }else if(data.resultinfo.result_cd.indexOf('E0014') > -1 || data.resultinfo.result_cd.indexOf('E0001') > -1) {
                        this.$bus.$emit('onError', {
                            success: false
                            , data: data.d
                            , message: message
                            , header: header
                            , code: data.resultinfo.result_cd
                        })

                        return {
                            success: false
                            , data: data.d
                            , message: message
                            , header: header
                            , code: data.resultinfo.result_cd
                        }
                    }
                }

                if(data.d?.cartl_member_grade_chg_info){
                    if(data.d.cartl_member_grade_chg_info.chg_fg == 'Y'){
                        sessionStorage.setItem('cartl_member_grade_chg_info', JSON.stringify(data.d.cartl_member_grade_chg_info))
                    }
                }

                return {
                    success: data.resultinfo.result_div_cd == 'OK' ? true : false
                    , data: data.d
                    , message: message
                    , header: header
                    , code: data.resultinfo.result_cd
                }
            }else{
                throw result.error
            }
        }
    }catch (error){

        console.log('result error', error)

        if(error?.status == 401) {
            this.$bus.$emit('onUnauthorized')
        }else if(error?.status == 400){
            this.$bus.$emit('notify', { type: 'error', message: '400'})
        }else{
            return {
                success: false
                , message: error?.message ? error.message : 'Network Error'
                , code: error?.response ? error.response : 'error'
                , data: error?.data
            }
        }
    }
}