<template>
	<div
		class="position-fixed-full z-index-layer"
	>
		<div class="bg-layer"></div>
		<div
			class="position-relative viewer pa-20 flex-column justify-center full-width full-height"
			:class="{ 'position-relative-full': is_full}"
		>
			<div style="max-height: 90%;" class="overflow-y-auto">
				<slot name="body"></slot>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PopupLayer'
	, props: ['is_full']
}
</script>

<style>

</style>