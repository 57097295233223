<template>
	<div
		v-if="program.type == 'mypage'"
		class="bottom_btn" style="background-color: #fff; "
	>
		<div class="btn_container">
			<p class="copyright pt-20">Copyright © MAFI Inc. </p>
		</div>
	</div>

	<div
		v-else
		ref="bottom"
		class="bottom_nav "
		:class="[{ bottom_nav: is_cartel}, lb]"
	>
		<div
			@click="setLb"
			class="ma-auto radius-10" style="background-color: silver; width: 30px; height: 5px; margin: 5px auto 0"
		></div>
		<ul
			class="nav_bar"
		>
			<li :class="{ active: is_cartel}"><a @click="to('home')" class="ico_home"><strong>HOME</strong></a></li>
			<li :class="{ active: is_drops}" ><a @click="to('post')" class="ico_cartel"><strong>포스트</strong></a></li>
			<li
				v-if="$is_on_tv"
				:class="{ active: is_tv}"
			><a @click="to('MafiaTvList')" class="ico_mafiatv"><strong>마피아TV</strong></a></li>
			<li
				:class="{ active: is_shorts}"
			><a @click="toShort" class="ico_shorts"><strong>숏플 </strong></a></li>
			<li :class="{ active: is_more}"><a @click="to('more')" class="ico_collection"><strong>{{ $language.common.seeMore }} <!-- 더보기--> </strong></a></li>
		</ul>
		<v-icon
			v-if="is_top"
			@click="toTop"
			large
			class="position-absolute color-white radius-100" style="right: 20px; top: -70px; width: 60px; height: 60px; background-color: var(--blue02)"
		>mdi-arrow-up</v-icon>
	</div>
</template>

<script>
	export default {
		name: 'Bottom'
		, props: ['program', 'user']
		, data: function(){
			return {
				lb: 'down'
				, is_top: false
				, items: []
			}
		}
		,computed:{
			is_more: function(){
				let t = false
				if(this.$route.path.indexOf('more') > 0){
					t = true
				}
				return t
			}
			,is_drops: function(){
				let t = false
				if(this.$route.path.indexOf('post') > 0){
					t = true
				}
				return t
			}
			,is_cartel: function(){
				let t = false

				if(this.$route.path == '/' || this.$route.path == '/index' || this.$route.path == '/home'){
					t = true
				}
				return t
			}
            ,is_launchpad: function() {
                let t = false
                if (this.$route.path.indexOf('launchpad') > 0) {
                    t = true
                }
                return t
            }
			, is_ios_app: function(){
				let t = true
				let domain_m = process.env.VUE_APP_DOMAIN_M
				let location = window.location.href
				if(location.indexOf(domain_m) > -1 && (!this.$common.isApp() || this.$common.getMobile() != 'ios')){
					t = false
				}
				return t
			}
			, is_shorts: function(){
				let t = false

				if(this.$route.path.indexOf('cartel/shorts') > 0){
					t = true
				}
				return t
			}
			, is_tv: function(){
				let t = false

				if(this.$route.path.indexOf('cartel/tv') > 0){
					t = true
				}
				return t
			}
			, shorts_info: function(){
				let t = {}
				this.items.filter ( (item) => {
					if(item.board_type_code == 'CA00700006'){
						t = item
					}
				})
				return t
			}
		}
		, methods: {

			to: function(type){
				if(this.$route.name != type){
					this.$bus.$emit('to', { name: type})
				}
			}
			, toShort: function(){
				this.$bus.$emit('to', { name: 'CartelShorts'})
			}
			, setLb: function () {
				if (this.lb == 'down') {
					this.lb = 'up'
				} else {
					this.lb = 'down'
				}
			}
			, toTop: function(){
				document.body.scrollTo({ top: 0, behavior: "smooth"})
			}
		}
		,created() {
			//console.log('bottom', this.program)

			let is_scroll = false
			if(this.program.type != 'mypage'){

				setTimeout(() => {
					window.atvImg()

					let lt = 0
					let body = document.body
					body.addEventListener('scroll', () => {

						let st = body.scrollTop

						if(st > 0){
							this.is_top = true
						}else{
							this.is_top = false
						}

						if (is_scroll) {
							return false
						} else {

							if (st > lt) {
								// this.lb = 'up'
							} else {
								// this.lb = 'down'
							}

							lt = st

							is_scroll = false
						}
					})
				}, 100)
			}
		}
	}
</script>

<style>

.down {
	bottom: 0 !important;
	transition: all 1s
}

.up {
	bottom: -65px !important;
	transition: bottom 1s
}
</style>