<template>
	<div class="header">
		<div
			class="top"
			:class="{ top_wh: is_cartel}"
		>
			<h2 class="page_tit">{{ program.title }}</h2>

			<div
				class="util"
			>
				<button
					v-if="!program.not_close"
					class="close"
					@click="goBack"
				>
					<i class="icon icon-close"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HeaderPopup'
	, props: ['program']
	, data: function(){
		return {

		}
	}
	, computed: {
		is_cartel: function(){
			let t = false
			if(this.program.type == 'popup_cartel'){
				t = true
			}

			return t
		}
	}
	,methods: {
		goBack: function(){
			if(this.program.from){
				this.$router.push({ name: this.program.from})
			}else{
				this.$router.back()
			}
		}
	}
}
</script>