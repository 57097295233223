<template>
	<Popup_confirm
		v-if="is_notice"
		@click="is_notice = false"
		@cancel="ban"
	>
		<template v-slot:title>{{  item_notice.title }}</template>
		<template
			v-slot:main-txt
		><div :inner-html.prop="item_notice.contents | nl2br"></div></template>
		<template
			v-slot:name-cancel
		>{{  $language.common.system_notice_btn01 }}</template>
		<template
			v-slot:name-confirm
		>{{  $language.common.system_notice_btn02 }}</template>
	</Popup_confirm>
</template>

<script>
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'system01'
		, components: {Popup_confirm}
		, props: ['user']
		, data: function(){
			return {
				item_notice: {
					title: '시스템 점검 사전 안내'
					, contents: '메타마피아 사용자들에게 보다 원활하고 안정된 서비스를 제공하기 위해 아래와 같이 시스템 점검을 진행할 예정입니다. 이용에 불편함이 없도록 빠르게 점검을 완료하겠습니다.'
					, sub: '점검일시: 23.04.01 00:00 ~ 23.40.02 00:00'
					, popup_id: 1
				}
				, is_notice: false
			}
		}
		, computed: {
		}
		, methods: {
			ban: function(){
				let t = localStorage.getItem('system_notice')
				if(!t){
					t = []
				}else{
					t = JSON.parse(t)
				}
				t.push(this.item_notice.popup_id)
				localStorage.setItem('system_notice', JSON.stringify(t))
				this.is_notice = false
			}
			, getSystemNotice: async function(){
				if(this.user.member_number && this.$route.fullPath.indexOf('/auth') < 0){

					try {
						this.$bus.$emit('on', true)
						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.get_system_notice
							, data: {
								member_number: this.user.member_number
							}
						})

						if (result.success) {
							this.item_notice = result.data

							let system_notice = localStorage.getItem('system_notice')

							if(this.item_notice.popup_id){
								if(!system_notice){
									this.is_notice = true
								}else{
									if(!system_notice.includes(this.item_notice.popup_id)){
										this.is_notice = true
									}else{
										this.is_notice = false
									}
								}
							}

						} else {
							throw result.message
						}
					} catch (e) {
						this.$bus.$emit('notify', {type: 'error', message: e})
					} finally {
						this.$bus.$emit('on', false)
					}
				}
			}
		}
		, created() {
			this.getSystemNotice()
		}
		, watch: {
			$route(){
				if(this.$route.name == 'main' || this.$route.name == 'mafia044' || this.$route.name == 'index'|| this.$route.name == 'cartel'){
					this.getSystemNotice()
				}
			}
		}
	}
</script>