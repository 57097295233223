<template>
	<div class="header">
		<div class="top top_mafi f-left">
			<div class="util">
				<button class="arrow_white" @click=goBack>
					<i class="icon icon-arrow-left"></i>
				</button>
			</div>
			<h2 class="page_tit">{{ program.title }}</h2>
		</div>
		<div
			v-if="program.is_save"
			class="save"
		>
			<button
				class="btn_save"
				@click="$emit('callBack')"
			>{{  txt_title }}</button>
		</div>
	</div>
</template>


<script>
export default {
	name: 'HeaderMypage'
	, props: ['program']
	, data: function(){
		return {

		}
	}
	, computed: {
		txt_title: function(){
			let t = this.$language.common.save

			if(this.program.name == 'mafiaCredit'){
				t = this.$language.common.register
			}else if(this.program.name == 'CartelRequestList'){
				t = this.$language.common.new_open
			}else if(this.program.callBack.title){
				t = this.program.callBack.title
			}

			return t
		}
	}
	,methods: {
		goBack: function(){
			if(this.program.from){
				this.$router.push({ name: this.program.from})
			}else{
				this.$router.back()
			}
		}
	}
}
</script>