<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4
									v-if="type == 'mobile'"
								>모바일 환경으로 접속하였습니다.</h4>
								<h4
									v-else
								>PC 환경으로 접속하였습니다.</h4>
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap flex-row justify-space-between">

						<div
							class="btn_area flex-1 ml-10"
						>
							<button
								class="btn_l btn_fill_gray"
								@click="toMode()"
							>모바일 버전으로 보기</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {common} from "@/assets/js/common";

	export default {
		name: 'mobile'
		, data: function(){
			return {
				program: {
					not_header: true
					, not_footer: true
				}
				, type: ''
			}
		}
		, methods: {
			is_mobile: function(){

				if(common.isMobile() == 'CM00500003'){
					this.type = 'mobile'
				}else{
					this.type = 'web'
				}
			}
			, toMode: function(){

				localStorage.setItem('mode', "mobile");
				window.document.location.reload()

				/*
				let location = window.location.href
				let domain = process.env.VUE_APP_DOMAIN
				let domain_m = process.env.VUE_APP_DOMAIN_M
				let domain_stg = process.env.VUE_APP_DOMAIN_STG
				let domain_stg_m = process.env.VUE_APP_DOMAIN_STG_M
				let domain_dev = process.env.VUE_APP_DOMAIN_DEV
				let domain_dev_m = process.env.VUE_APP_DOMAIN_DEV_M
				let domain_local = process.env.VUE_APP_DOMAIN_LOCAL
				let domain_local_m = process.env.VUE_APP_DOMAIN_LOCAL_M

				if(location.indexOf(domain_m) > -1){
					document.location.href = domain
				}else if(location.indexOf(domain_stg_m) > -1) {
					document.location.href = domain_stg
				}else if(location.indexOf(domain_dev_m) > -1) {
					document.location.href = domain_dev
				}else if(location.indexOf(domain_local_m) > -1) {
					document.location.href = domain_local
				}

				 */
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.is_mobile()
		}
	}
</script>