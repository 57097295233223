<template>
	<div
		class="wrap"
	>
		<template
			v-if="type == 'terms'"
		>
			<div class="header under-line">
				<div class="top top_wh">
					<h2 class="tit_center">{{ $language.agreement.title }}</h2>
					<div class="util">
						<button class="arrow" @click="$emit('cancel')">
							<i class="icon icon-arrow-left"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="pt-50 full-height flex-column">
				<div class="container full-height">
					<iframe
						v-if="terms.stip_file_url"
						:src="terms.stip_file_url"
						class="full-height full-width pl-10"
						style="border: none"
					></iframe>
					<div
						v-else
						class="row"
					>
						<div
							class="terms_wrap" v-html="terms.strip_contents"
						></div>
					</div>
				</div>

				<div
					class="mt-auto agree-btn-wrap"
				>
					<button
						class="btn"
						@click="setAgree"
					>{{ $language.agreement.agree_yes }}</button>
				</div>
			</div>
		</template>

		<template
			v-else-if="type == 'personal'"
		>
			<div class="header under-line">
				<div class="top top_wh">
					<h2 class="tit_center">{{ $language.agreement.privacy_policy }}</h2>
					<div class="util">
						<button class="arrow" @click="$emit('cancel')">
							<i class="icon icon-arrow-left"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="pt-50 full-height flex-column">
				<div class="container full-height ">
					<iframe
						v-if="personal.stip_file_url"
						:src="personal.stip_file_url"
						class="full-width full-height pl-10"
						style="border: none"
					></iframe>
					<div
						v-else
						class="row"
					>
						<div
							class="terms_wrap" v-html="personal.strip_contents"
						></div>
					</div>
				</div>

				<div
					class="mt-auto agree-btn-wrap"
				>
					<button
						class="btn"
						@click="setPersonal"
					>{{ $language.agreement.agree_yes }}</button>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'mafia0041'
		, props: ['type']
		, data: function(){
			return {
				program: {
					name: 'agree'
					, type: 'agree'
					, not_footer: true
				}
				, terms: {
					stip_number: ''
				}
				, personal: {
					stip_number: ''
				}
			}
		}
		, computed:{

		}
		, methods: {
			getData: async function(){
				try {

					const result = await this.$Request({
						method: 'POST'
						, url: this.$api_url.api_path.get_agreement
						, data: {

						}
						, type: true
					})
					console.log('result.success', result.success)
					if (result.success) {
						console.log('success')
						for(let i = 0; i < result.data.stip_list.length; i++){
							let t = result.data.stip_list[i]
							if(t.stip_code == 'CO00100002') {
								this.terms = t
								continue
							}
							if(t.stip_code == 'CO00100001') {
								this.personal = t
								continue
							}
						}
						// this.getFiles()
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				}
			}
			, setAgree: function(){
				let join_info = localStorage.getItem('join_info')
				if(!join_info){
					join_info = {
						terms_version: ''
					}
				}else{
					join_info = JSON.parse(join_info)
				}
				console.log('this.terms', this.terms)
				join_info.terms_version = this.terms.stip_number
				localStorage.setItem('join_info', JSON.stringify(join_info))
				this.$emit('click', 'terms', this.terms)
			}
			, setPersonal: function(){
				let join_info = localStorage.getItem('join_info')
				if(!join_info){
					join_info = {
						personal_version: ''
					}
				}else{
					join_info = JSON.parse(join_info)
				}
				join_info.personal_version = this.personal.stip_number
				localStorage.setItem('join_info', JSON.stringify(join_info))
				this.$emit('click', 'personal', this.personal)
			}
			, getFiles: function(){
				if(this.type == 'terms'){
					if(this.terms.stip_offer_code == 'CO00200002'){
						window.open(this.terms.stip_file_url, 'terms')
					}
				}else{
					if(this.personal.stip_offer_code == 'CO00200002'){
						window.open(this.personal.stip_file_url, 'terms')
					}
				}
			}
		}
		, created() {
			this.getData()
		}
	}
</script>
<style>

	.agree-btn-wrap { margin-top: auto}
	.agree-btn-wrap .btn { width: 100%; text-align: center; background-color: #171c61; padding: 10px; font-size: 14px; color: white}
	.section_wrap::after { padding-bottom: 0}
</style>