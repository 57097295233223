<template>
	<div class="header">
		<div
			class="top"
			:class="{ top_wh: is_cartel }"
		>
			<h1 class="logo">
				<a @click="toMain"><span class="hide">Culture M.A.F.I.A.</span></a>
			</h1>
			<div class="util">
				<a
					v-if="is_home || is_post || is_cartel"
					class="ico_search"
					@click="$bus.$emit('to', { name: 'mafia047'})"
				><span class="hide">{{  $language.common.search }}</span></a>
				<!-- TODO : 새로운 알림이 있을 경우 class : on 추가 -->
				<template
					v-if="user.member_number"
				>
					<a @click="$bus.$emit('to', { name: 'mafia118'})" class="ico_alram" :class="{on: is_notify_on}"><span class="hide">{{  $language.common.alarm }}</span></a>
					<a @click="$bus.$emit('to', { name: 'myPage'})" class="ico_profile"><span class="hide">{{  $language.common.myinfo }}</span></a>
				</template>
				<a
					v-else
					class="size-px-14 ml-10 mr-10 color-white"
					@click="onLogin"
				>{{ $language.common.login }}</a>
			</div>
		</div>
		<div
			v-if="false"
			class="bottom"
		>
			<div class="nav">
				<ul>
					<!-- TODO : 메뉴 선택 시 class : active 추가 -->
					<li
						:class="{active: is_nft}"
					><a @click="$bus.$emit('to', { name: 'nft'})" class="btn_l">NFT</a></li>
					<li
						:class="{active: is_wallet}"
					><a @click="$bus.$emit('to', { name: 'wallet'})" class="btn_l">WALLET</a></li>
					<li
						:class="{active: is_staking}"
					><a @click="$bus.$emit('to', { name: 'staking'})" class="btn_l">STAKING</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Header'
		, props: ['user']
		, data: function(){
			return {
				item_notify: {
					nrt_note_receive_fg: 'N'
					, nrt_note_receive_count: 0
				}
			}
		}
		,computed:{
			is_nft: function(){
				let t = false
				if(this.$route.path.indexOf('nft') > -1){
					t = true
				}
				return t
			}
			,
			is_wallet: function(){
				let t = false
				if(this.$route.path.indexOf('wallet') > -1){
					t = true
				}
				return t
			}
			,
			is_staking: function(){
				let t = false
				if(this.$route.path.indexOf('staking') > -1){
					t = true
				}
				return t
			}
			, is_home: function(){
				let t = false
				if(this.$route.path == '/' || this.$route.path == '/index' || this.$route.path == '/home' || this.$route.path == '/main' ){
					t = true
				}
				return t
			}
			,is_post: function(){
				let t = false
				if(this.$route.path.indexOf('post') > -1){
					t = true
				}
				return t
			}
			, is_cartel: function(){
				let t = false
				if(this.$route.path.indexOf('cartel') > -1){
					t = true
				}
				return t
			}
			, is_notify_on: function(){
				let t = false

				// console.log('this.item_notify.nrt_note_receive_fg', this.item_notify.nrt_note_receive_fg)
				if(this.item_notify.nrt_note_receive_fg == 'Y'){
					t = true
				}

				return t
			}
		}
		, methods: {
			toMain: function(){
				this.$bus.$emit('to', {name: 'main'})
			}
			, getCommNotify: async function(){
				if(!this.user.member_number){
					return false
				}
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_comm_notify
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.item_notify = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					// this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onLogin: function(){
				this.$bus.$emit('onLogin')
			}
		}
		, created() {
			this.getCommNotify()
		}
	}
</script>