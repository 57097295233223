export const language = {
  base: {
    page_number: 1
    , pagerecnum: 10
  }
  , state: {
    'CA003': [
      {code: 'CA00300001', name: '가입신청'}
      , {code: 'CA00300002', name: '가입심사중'}
      , {code: 'CA00300003', name: '가입불가'}
      , {code: 'CA00300004', name: '참여중'}
      , {code: 'CA00300005', name: '정지'}
      , {code: 'CA00300006', name: '탈퇴'}
      , {code: 'CA00300007', name: '강제탈퇴'}
    ]
  },

  state_02: {
    CA00300001: '가입신청',  // {{  $language.state_02.CA00300001 }}
    CA00300002: '가입심사중', // {{  $language.state_02.CA00300002 }}
    CA00300003: '가입불가', // {{  $language.state_02.CA00300003 }}
    CA00300004: '참여중', // {{  $language.state_02.CA00300004 }}
    CA00300005: '정지', // {{  $language.state_02.CA00300005 }}
    CA00300006: '탈퇴', // {{  $language.state_02.CA00300006 }}
    CA00300007: '강제탈퇴', // {{  $language.state_02.CA00300007 }}
  }

  , common: {
    askRemove: '삭제하시겠습니까?'
    , required: '필수'
    , start: '시작하기'
    , cancel_request: '요청 취소'
    , modify: '수정'
    , text_tip: 'TIP'
    , text_tip1: '커버 사진은 모바일과 PC웹을 별도로 등록해야 합니다.'
    , text_tip2: 'PC웹으로 접속하여 전용 커버 사진을 추가 등록하세요.'
    , text_tip3: 'www.mafinft.com'
    , text_tip4: '* 가로 1920px 세로 300px 이상'
    , request: '인증'
    , buy: '구입'
    , credit_card: '신용카드'
    , credit_select: '카드선택'
    , system_notice_title: '시스템 점검중입니다'
    , system_notice_txt01: '메타마피아 사용자들에게 보다 원활하고 안정된 서비스를 제공하게 위해 아래와 같이 시스템 점검 중에 있습니다.'
    , system_notice_txt02: '양해를 부탁드립니다.'
    , system_notice_btn01: '다시보지않기'
    , system_notice_btn02: '닫기'
    , not_select: '선택된 항목이 없습니다.'
    , success: '정상적으로 처리되었습니다' // this.$language.common.success
    , fail: 'DB 오류!'
    , error: '시스템 오류!'
    , dummy: '더미 데이터 입니다'
    , request_to_admin_01: '문제가 지속되면 관리자에게 문의하세요.'  // {{ $language.common.request_to_admin_01 }}
    , no_search_data: '검색 결과가 없습니다.'  // this.$language.common.no_search_data
    , no_search_member: '시청 중인 멤버가 없습니다.'
    , no_board_list: '등록된 게시판이 없습니다.'
    , personal: '개인'
    , corporation: '법인'
    , out: {
      name: '카르텔 강제탈퇴'
      , main_txt: '아래 멤버를 카르텔 내에서 강제탈퇴 시키겠습니까?'
    }
    , deactivate: {
      name: '카르텔 활동정지'
      , main_txt: '아래 멤버를 카르텔 내에서 활동정지 시키시겠습니까?'
    }
    , join_confirm: {
      name: '가입 승인'   // {{  $language.common.join_confirm.name }}
      , main_txt: '아래 멤버를 카르텔 가입 승인하시겠습니까?'
    }
    , join_cancel: {
      name: '가입 삭제'
      , main_txt: '아래 멤버를 카르텔 가입 삭제(불승인)하시겠습니까?'
    }
    , safe: {
      name: '정지 해제'
      , main_txt: '아래 멤버는 카르텔 내 활동 정지기간이 남아있습니다. 해제하시겠습니까?'
    }
    , service_info_metamafia: '메타마피아 안내'
    , app_notice_push: '앱 공지입니다.'
    , cannot_service_now: '현재는 메타마피아 앱을 이용할 수 없습니다.'
    , release_new_version: '새로운 버전이 출시 되었습니다.'
    , push_new_update_01: '새로운 업데이트가 있습니다.'
    , push_new_update_02: '업데이트 이후에 가능합니다.'
    , error_update: '업데이트 오류'  // {{  $language.common.error_update }}

    , sending: '전송중' // {{  $language.common.sending }}
    , complete_send: '전송완료' // {{  $language.common.complete_send }}
    , copy_link: '링크복사' // {{  $language.common.copy_link }}
    , transfer_ID: '거래ID' // {{  $language.common.transfer_ID }}
    , multiple_choice: '복수선택' // {{  $language.common.multiple_choice }}
    , startDate: '시작일' // {{  $language.common.startDate }}
    , endDate: '종료일'  // {{  $language.common.endDate }}
    , startTime: '시작시간'
    , endTime: '종료시간'  // {{  $language.common.endTime }}
    , setEndTime: '종료시간설정'  // {{  $language.common.setEndTime }}
    , setEndDate_require: '종료일을 설정하세요.'  // {{  $language.common.setEndDate_require }}
    , setEndTime_require: '종료시간을 설정하세요.'  // {{  $language.common.setEndTime_require }}
    , all: '전체' // {{  $language.common.all }}
    , lookup: '조회' // {{  $language.common.lookup }}
    , register: '등록' // {{  $language.common.register }}
    , new_open: '신규 개설'
    , re_register: '재등록' // {{  $language.common.re_register }}
    , next: '다음' // {{  $language.common.next }}
    , save: '저장' // {{  $language.common.save }}
    , open_request: '개설 요청'
    , signUp: '회원가입'  // {{  $language.common.signUp }}
    , signUpComplete: '회원가입 완료'
    , title: '문화예술 전용 커뮤니티 마피아 프로젝트'  // {{  $language.common.title }}
    , login: '로그인'  // {{  $language.common.login }}
    , ok: '확인'  // {{  $language.common.ok }}
    , again: '재시도'  // {{  $language.common.again }}
    , cancel: '취소'  // {{  $language.common.cancel }}
    , clear: '지우기'
    , back: '돌아가기' // {{  $language.common.back }}
    , local_data_reset: '로컬 데이터 리셋' // {{  $language.common.local_data_reset }}
    , request_fail: '요청처리실패'
    , auth: '인증'
    , copy: '복사' //{{  $language.common.copy }}
    , nickName: '닉네임'  //{{  $language.common.nickName }}
    , statusMessage: '상태메시지' //{{  $language.common.statusMessage }}
    , email: '이메일' //{{  $language.common.statusMessage }}
    , email_txt: '이메일은 PIN 및 OTP 분실 시 사용됩니다.'
    , status: '상태' //{{  $language.common.status }}
    , smsNumber: '휴대폰 번호'
    , update: '업데이트'  //{{  $language.common.update }}
    , destroy: '종료'
    , later: '나중에'
    , setting: '설정'  //{{  $language.common.setting }}
    , soundBackgroundImg: '사운드 배경이미지'
    , comment: '댓글' //{{  $language.common.comment }}
    , modify_comment: '댓글수정'
    , comment_require: '댓글을 남겨주세요.'  // {{  $language.common.comment_require }}
    , comment_require_02: '댓글을 입력하세요.'  // {{  $language.common.comment_require_02 }}
    , cut_off: '차단'  // {{  $language.common.cut_off }}
    , write_reply: '답글쓰기'  // {{  $language.common.write_reply }}
    , explain_cartel: '카르텔 설명'  // {{  $language.common.explain_cartel }}
    , cartel_sns: 'SNS URL'  // {{  $language.common.cartel_sns }}
    , setting_cartel: '카르텔 설정'
    , cartel: '카르텔'  // {{  $language.common.cartel }}
    , establish: '개설'
    , cartel_establish: '카르텔 개설'  // {{  $language.common.cartel_establish }}
    , complete_cartel_establish: '카르텔 개설 완료'  // {{  $language.common.complete_cartel_establish }}
    , list: '목록'
    , input_search_keyword: '검색어 입력'
    , close: '닫기'  // {{  $language.common.close }}
    , search: '검색' // {{  $language.common.search }}
    , compulsory_exit: '강퇴' // {{  $language.common.compulsory_exit }}
    , searchResultUser: '사용자 검색결과'
    , article_delete_move_user: '사용자 글 삭제 및 이동'  // {{  $language.common.article_delete_move_user }}
    , searchResultCartel: '카르텔 검색결과'
    , searchInfoUser: '사용자 검색 정보'  // {{  $language.common.searchInfoUser }}
    , seeMore: '더보기'
    , sympathy: '공감'
    , sympathy_members: '공감표현한 사람들'
    , menu: '메뉴'
    , invite: '초대' // {{  $language.common.invite }}
    , invite_member: '멤버초대'
    , not_join: '미가입'
    , search_member: '멤버검색' // {{  $language.common.search_member }}
    , search_member_result: '멤버검색결과'
    , notice: '공지사항'  //{{  $language.common.notice }}
    , community: '커뮤니티'  //{{  $language.common.community }}
    , notify_notice: '공지사항 알림'  //{{  $language.common.notify_notice }}
    , dark_mode: '다크모드'  //{{  $language.common.notify_notice }}
    , announcement: '공지'
    , NotFoundNotice: '등록된 공지사항이 없습니다.'  // {{  $language.common.NotFoundNotice }}
    , NotFoundArticle: '등록된 게시물이 없습니다.'   // {{  $language.common.NotFoundArticle }}
    , NotFoundRegisteredNotice: '등록된 알림이 없습니다.'   // {{  $language.common.NotFoundRegisteredNotice }}
    , voting: '투표'   // {{  $language.common.voting }}
    , vote: '투표하기'   // {{  $language.common.voting }}
    , complete_vote: '투표완료'   // {{  $language.common.complete_vote }}
    , terminate_vote: '투표종료'   // {{  $language.common.terminate_vote }}
    , memberCount: '멤버수'
    , manageMember: '멤버관리'  // {{  $language.common.manageMember }}
    , join: '가입'
    , cartel_join: '카르텔 가입'
    , oneLineCheering: '한줄응원'  //{{  $language.common.oneLineCheering }}
    , cartelStory: '카르텔 스토리'  //{{  $language.common.oneLineCheering }}
    , live_member: '시청 중인 멤버'
    , member: '회원'  // {{  $language.common.member }}
    , friend: '친구'  // {{  $language.common.friend }}
    , myHeart: 'My 불렛'  // {{  $language.common.friend }}
    , result: '결과'
    , cannotInviteFriend: '초대가능한 친구가 없습니다.'
    , cannotInviteMember: '초대가능한 멤버가 없습니다.'
    , cannotFutureMember: '선물 가능한 친구가 없습니다.'  // {{  $language.common.cannotFutureMember }}
    , succeedInvite: '초대요청이 완료되었습니다.'  // {{  $language.common.succeedInvite }}
    , reason_report: '신고 사유'
    , complete: '완료'  // {{  $language.common.complete }}
    , toBack: '이전'  // {{  $language.common.complete }}
    , cartel_editing: '카르텔 편집'
    , cartel_intro: '카르텔소개'
    , disclosure_active: '활동정보 공개'  // {{  $language.common.disclosure_active }}
    , notify: '알림' // {{  $language.common.notify }}
    , notify_sound_default: '기본알림음' // {{  $language.common.notify_sound_default }}
    , notify_default: '기본 알림' // {{  $language.common.notify_default }}
    , alarm: '알람'  // {{  $language.common.alarm }}
    , view_member: '멤버보기'
    , withdrawal_cartel: '카르텔 탈퇴' //{{  $language.common.withdrawal_cartel }}
    , iu: '아이유' //{{  $language.common.iu }}
    , withdrawal: '탈퇴하기'  //{{  $language.common.withdrawal }}
    , withdrawal_mafia: 'MAFIA 탈퇴'  //{{  $language.common.withdrawal_mafia }}
    , manage_grade_member: '멤버등급관리'
    , closer_cartel: '카르텔폐쇄'
    , disclosure: '공개'  //{{  $language.common.disclosure }}
    , Nondisclosure: '비공개'  //{{  $language.common.Nondisclosure }}
    , closure: '폐쇄하기'
    , authority: '권한'
    , dismissal: '해임'
    , myinfo: '내정보'  //{{  $language.common.myinfo }}
    , appointment: '임명' //{{  $language.common.appointment }}
    , boss: '보스' //{{  $language.common.boss }}
    , under_boss: '운영자' //{{  $language.common.under_boss }}
    , manage_member: '회원관리' //{{  $language.common.manage_member }}
    , manage_register: '등록관리' //{{  $language.common.manage_register }}
    , manage_grade: '멤버등급관리' //{{  $language.common.manage_register }}
    , manage_grade_txt: '멤버 등급 조건 관리' //{{  $language.common.manage_register }}
    , waiting_approval: '승인대기' //{{  $language.common.waiting_approval }}
    , stopped_member: '정지회원' //{{  $language.common.stopped_member }}
    , approval: '승인' //{{  $language.common.approval }}
    , delete: '삭제' //{{  $language.common.delete }}
    , cafo: '카포' //{{  $language.common.cafo }}
    , remaining_day: '남은날' //{{  $language.common.remaining_day }}
    , termination: '해지' //{{  $language.common.termination }}
    , days_stop_activity: '일간 활동정지' //{{  $language.common.days_stop_activity }}
    , statistics: '통계' //{{  $language.common.statistics }}
    , excellent_member: '우수회원' //{{  $language.common.excellent_member }}
    , daily: '일간' //{{  $language.common.daily }}
    , weekly: '주간' //{{  $language.common.weekly }}
    , monthly: '월간' //{{  $language.common.monthly }}
    , visit: '방문' //{{  $language.common.visit }}
    , visit_count: '방문자수' //{{  $language.common.visit_count }}
    , join_count: '가입수' //{{  $language.common.join_count }}
    , new_article_count: '새글수' //{{  $language.common.new_article_count }}
    , check_wrote: '작성글 확인' //{{  $language.common.check_wrote }}
    , wrote: '작성글' //{{  $language.common.wrote }}
    , registered_cartel: '가입 카르텔' //{{  $language.common.registered_cartel }}
    , accumulate: '누적' //{{  $language.common.accumulate }}
    , history: '내역' //{{  $language.common.history }}
    , move_order: '순서이동' //{{  $language.common.move_order }}
    , normal: '일반' //{{  $language.common.normal }}
    , read: '읽기' //{{  $language.common.read }}
    , citizen: '시민' //{{  $language.common.citizen }}
    , gangster: '갱스터' //{{  $language.common.gangster }}
    , makeFace: '표정짓기' //{{  $language.common.makeFace }}
    , attending_count_current: '현재 참여 인원수' //{{  $language.common.attending_count_current }}
    , input_vote_name: '투표 제목 입력' //{{  $language.common.input_vote_name }}
    , input_vote_item: '투표 항목 입력' //{{  $language.common.input_vote_item }}
    , add_vote_item: '투표 항목 추가' //{{  $language.common.add_vote_item }}
    , morning: '오전' //{{  $language.common.morning }}
    , afternoon: '오후' //{{  $language.common.afternoon }}
    , hour: '시' //{{  $language.common.hour }}
    , minute: '분' //{{  $language.common.minute }}
    , year: '년' //{{  $language.common.minute }}
    , month: '월' //{{  $language.common.minute }}
    , day: '일' //{{  $language.common.day }}
    , picture: '사진' //{{  $language.common.picture }}
    , video: '비디오' //{{  $language.common.video }}
    , sound: '사운드' //{{  $language.common.sound }}
    , media: '미디어' //{{  $language.common.media }}
    , main: '메인' //{{  $language.common.main }}
    , file: '파일' //{{  $language.common.file }}
    , mike: '마이크' //{{  $language.common.mike }}
    , camera: '카메라' //{{  $language.common.camera }}
    , audio: '오디오 재생' //{{  $language.common.audio }}
    , record: '녹음' //{{  $language.common.audio }}
    , take_qr: '사진 촬영 및 동영상 촬영' //{{  $language.common.take_qr }}
    , edit_profile: '프로필 편집' //{{  $language.common.edit_profile }}
    , number_member: '회원번호' //{{  $language.common.number_member }}
    , number_phone: '휴대폰 번호' //{{  $language.common.number_phone }}
    , release_info: '최신정보' //{{  $language.common.release_info }}
    , test_version: '테스트 버전' //{{  $language.common.test_version }}
    , version: '버전' //{{  $language.common.version }}
    , version_info: '버전 정보' //{{  $language.common.version_info }}
    , delete_data: '데이터 삭제' //{{  $language.common.delete_data }}
    , mypage: '마이페이지' //{{  $language.common.mypage }}
    , attach_image: '이미지첨부' //{{  $language.common.attach_image }}
    , mounting_item: '아이템장착' //{{  $language.common.mounting_item }}
    , mounting: '장착' //{{  $language.common.mounting }}
    , search_friend: '친구 검색' //{{  $language.common.search_friend }}
    , notify_friend: '친구 알림' //{{  $language.common.notify_friend }}
    , search_friend_result: '친구 검색 결과' //{{  $language.common.search_friend_result }}
    , my_auth: '본인인증' //{{  $language.common.my_auth }}
    , limit: '제한' //{{  $language.common.limit }}
    , account: '계정' //{{  $language.common.account }}
    , withdrawal_account: '계정탈퇴' //{{  $language.common.withdrawal_account }}
    , logout: '로그아웃' //{{  $language.common.logout }}
    , logout_mafia: 'MAFIA 로그아웃' //{{  $language.common.logout_mafia }}
    , blocked_member: '차단 회원' //{{  $language.common.blocked_member }}
    , deposit: '입금' //{{  $language.common.deposit }}
    , withdraw: '출금' //{{  $language.common.withdraw }}
    , withdraw_apply_on: '출금신청' //{{  $language.common.withdraw_apply_on }}
    , futures: '선물' //{{  $language.common.futures }}
    , futures_history: '선물이력' //{{  $language.common.futures }}
    , transfer_history: '입출금 이력' //{{  $language.common.transfer_history }}
    , transfer_time: '입출금 일시' //{{  $language.common.transfer_time }}
    , futures_time: '선물일시' //{{  $language.common.futures_time }}
    , time: '일시' //{{  $language.common.time }}
    , staking: '스테이킹' //{{  $language.common.staking }}
    , mafia_card: '마피아 카드' //{{  $language.common.mafia_card }}
    , mafia_card_detail: '마피아 카드 상세' //{{  $language.common.mafia_card_detail }}
    , external_nft_wallet: '외부 NFT 지갑' //{{  $language.common.external_nft_wallet }}
    , external_nft: '외부 NFT' //{{  $language.common.external_nft }}
    , union_card: '유니온 카드' //{{  $language.common.union_card }}
    , utility_card: '유틸리티 카드' //{{  $language.common.utility_card }}
    , utility_card_list: '유틸리티 카드 목록' //{{  $language.common.utility_card_list }}
    , cancellation: '해제' //{{  $language.common.cancellation }}
    , min: '최소' //{{  $language.common.min }}
    , max: '최대' //{{  $language.common.max }}

    , deleted: '삭제되었습니다.' //{{  $language.common.deleted }}
    , invalid_wallet: '지갑 정보가 없거나 지갑 주소가 정상적으로 생성되지 않았습니다' //{{  $language.common.invalid_wallet }}
    , will_create: 'MAFINFT에서 사용 할 지갑을 생성합니다' //{{  $language.common.will_create }}
    , Not_exist_nft_wallet: '현재 외부 NFT 지갑이 없습니다.' //{{  $language.common.Not_exist_nft_wallet }}
    , nft_wallet_require: '지갑 생성을 하여 외부 NFT를 보관하세요.' //{{  $language.common.nft_wallet_require }}
    , nft_wallet_require_02: '지갑 생성을 하여 마피아 NFT카드를 구입하세요.' //{{  $language.common.nft_wallet_require_02 }}
    , Not_found_history: '이력이 없습니다.' //{{  $language.common.Not_found_history }}
    , complete_withdraw_request: '출금요청이 완료되었습니다.' //{{  $language.common.complete_withdraw_request }}
    , cancellation_utility_card_question: '유틸리티 카드를 해제 하시겠습니까?.' //{{  $language.common.cancellation_utility_card_question }}
    , cancellation_utility_card_question_02: '해제하시면 해당 강화능력을 사용할 수 없습니다.' //{{  $language.common.cancellation_utility_card_question_02 }}
    , mount_utility_card_question: '유틸리티 카드를 장착 하시겠습니까?' //{{  $language.common.mount_utility_card_question }}
    , mount_utility_card_question_02: '장착하시면 해당 강화능력을 사용할 수 있습니다.' //{{  $language.common.mount_utility_card_question_02 }}
    , complete_futures: '선물하기가 완료되었습니다.' //{{  $language.common.complete_futures }}
    , logout_question: '로그아웃 하시겠습니까?' //{{  $language.common.logout_question }}
    , no_searched_friend: '조회된 친구가 없습니다.' //{{  $language.common.no_searched_friend }}
    , no_selected_friend: '선택된 친구가 없습니다.' //{{  $language.common.no_selected_friend }}
    , copied_member_number: '클립보드에 복사되었습니다.' //{{  $language.common.copied_member_number }}
    , message_test: '메시지 입니다.' //{{  $language.common.message_test }}
    , no_registered_comment: '등록된 댓글이 없습니다.' //{{  $language.common.no_registered_comment }}
    , title_require: '제목을 입력하세요.' //{{  $language.common.title_require }}
    , content_require: '내용을 입력하세요.' //{{  $language.common.content_require }}
    , choice_vote_require: '투표항목을 선택하세요 ' //{{  $language.common.choice_vote_require }}
    , cancel_vote_write: '투표 작성 취소' //{{  $language.common.cancel_vote_write }}
    , cancel_vote_write_question: '투표 작성을 취소하시겠습니까?' //{{  $language.common.cancel_vote_write_question }}
    , cancel_vote_write_confirm: '작성된 투표내역은 삭제됩니다.' //{{  $language.common.cancel_vote_write_confirm }}
    , no_excellent_member: '우수회원이 없습니다.' //{{  $language.common.no_excellent_member }}
    , do_you_add_friend: '친구 추가 하시겠습니까?' //{{  $language.common.do_you_add_friend }}
    , do_you_cancel_friend: '친구 취소 하시겠습니까?' //{{  $language.common.do_you_cancel_friend }}
    , no_lookup_member: '조회가능한 회원이 없습니다.' //{{  $language.common.no_lookup_member }}
    , enter_member_name: '회원 이름을 입력하세요.' //{{  $language.common.enter_member_name }}
    , already_account: '이미 등록된 이메일 계정입니다.'
    , already_friend: '이미 등록된 친구입니다'
    , bbs_code_notice: 'notice'
    , error_file_limit: '첨부파일은 최대 8개입니다.'
    , error_vote_limit: '투표항목은 최대 10개입니다.'
    , error_date_not_today: '투표종료일은 오늘 이전 날짜로 지정할 수 없습니다.'
    , error_date_not_now: '투표종료는 현재 시간 이전으로 지정할 수 없습니다.'
    , error_confirm_type: '요청 구분이 없습니다.'
    , text_delete_comment: '삭제된 댓글입니다'
    , error_empty_search_value: '검색어를 입력하세요'   //{{  $language.common.error_empty_search_value }}
    , error_request: '잘못된 요청입니다.'   //{{  $language.common.error_request }}
    , first_sympathy_require: '첫 공감을 눌러주세요.'   //{{  $language.common.first_sympathy_require }}
    , not_exist_page: '존재하지 않는 페이지입니다.'   //{{  $language.common.not_exist_page }}
    , reuse_after_check: '확인 후 다시 이용해주세요.'   //{{  $language.common.reuse_after_check }}

    , expire_token: '토큰 만료'  //{{  $language.common.expire_token }}
    , impossible_token: '토큰 처리 불가'  //{{  $language.common.impossible_token }}
    , error_token: '토큰 처리 오류'  //{{  $language.common.error_token }}

    , message_login_require: '로그인 후 다시 이용해주세요'  //{{  $language.common.message_login_require }}
    , message_wrong: '잘못된 요청입니다. 관리자에게 문의하세요'  //{{  $language.common.message_wrong }}
    , message_error: '요청 처리중 문제가 발생하였습니다'  //{{  $language.common.message_error }}
    , message_error_network: '네트워크가 원활하지 않습니다.'  //{{  $language.common.message_error_network }}
  }
  , confirm: {
    delete_bbs: {
      title: '게시물 삭제'
      , main: '해당 게시물을 삭제하시겠습니까?'
      , sub: '게시물을 삭제하시려면 확인을 눌려주세요'
    }
    , block_account: {
      title: '사용자 차단'
      , main: '해당 사용자를 차단하시겠습니까?'
      , sub: '사용자를 차단하시려면 확인을 눌려주세요'
    }
    , block_cancel: {
      title: '사용자 차단 해제'
      , main: '해당 사용자를 차단 해제 하시겠습니까?'  //{{  $language.block_cancel.main }}
      , sub: '사용자 차단을 해제하시려면 확인을 눌려주세요'  //{{  $language.block_cancel.sub }}
    }
    , add_friend: {
      title: '친구 추가'  //{{  $language.confirm.add_friend.title }}
      , main: '친구를 추가하시겠습니까?'
      , sub: '해당 친구의 모든 새글 알림을 받을까요?'
      , sub2: '친구의 새글 알림을 받을까요?'  //{{  $language.confirm.add_friend.sub2 }}
    }
    , cancel_friend: {
      title: '친구 취소'  //{{  $language.confirm.cancel_friend.title }}
      , main: '친구를 취소하시겠습니까?'  //{{  $language.confirm.cancel_friend.main }}
      , sub: '친구취소를 원하시면 확인을 눌러주세요'  //{{  $language.confirm.cancel_friend.sub }}
    }
    , report_bbs: {
      title: '게시물 신고'
      , main: '해당 게시물을 신고하시겠습니까?'
      , sub: '사유: '
    }
    , delete_comment: {
      title: '댓글 삭제'
      , main: '해당 댓글을 삭제하시겠습니까?'
      , sub: '댓글을 삭제하시려면 확인을 눌러주세요'
    }
    , report_comment: {
      title: '댓글 신고'
      , main: '해당 댓글을 신고하시겠습니까?'
      , sub: '사유: '
    }

    , write_comment: {
      title: '댓글 작성'  //{{  $language.confirm.write_comment.title }}
      , choice_comment: '댓글 작성 여부를 선택합니다.'  //{{  $language.confirm.write_comment.choice_comment }}
    }
  }
  , word: {
    write: '글쓰기'  //{{  $language.word.write }}
    , modify: '수정'  //{{  $language.word.modify }}
    , delete: '삭제'
    , friend: '친구'
    , friend_add: '친구 추가'
    , friend_cancel: '친구 취소'
    , block: '차단'
    , block_cancel: '차단 해제'  //{{  $language.word.block_cancel }}
  }
  , title: {
    report_bbs: '게시물 신고'
    , report_comment: '댓글 신고'
    , change_location_bbs: '게시물 위치 이동'
  }

  , mypage: {
    language_in_use: '사용 언어'
    , title_personal_confirm: '본인인증'
    , select_language: '사용 언어 선택'
    , credit_management: '신용카드 관리'
    , credit_regist: '신용카드 등록'
    , credit: {
      credit_setup_title: '대표카드 설정'
      , credit_setup_name: '대표카드'
      , credit_setup_txt: '해당카드를 대표카드로 설정 하시겠습니까?'
      , credit_setup_txt_sub: '기존에 등록된 대표카드가 있는 경우 대표카드가 변경됩니다.'
      , credit_delete_title: '카드 삭제'
      , credit_delete_txt: '해당카드를 삭제 하시겠습니까?'
      , credit_delete_txt_sub: '삭제를 원하시면 확인 버튼을 눌러주세요.'
      , credit_none_txt: '등록된 신용카드가 없습니다.'
      , credit_regist_complete_txt: '신용카드가 등록되었습니다.'
      , credit_regist_fail_txt: '신용카드 등록에 실패했습니다.'
      , credit_regist_fail_txt_sub: '다시 확인 후 등록하세요.'
      , credit_card_number: '카드번호'
      , credit_card_validity: '유효기간'
      , credit_card_cvc: 'CVC'
      , credit_card_pass: '카드 비밀번호'
      , credit_birthym: '생년월일'
      , credit_business_number: '사업자등록번호'
      , credit_regist_txt_cvc: 'CVC 3자리 숫자'
      , credit_regist_txt_pass: '앞 2자리'
      , credit_regist_txt_birth: '생년월일 6자리'
      , credit_regist_txt_corporation: '사업자등록번호 10자리'
    }
  }
  , auth: {
    login_with_kakoa: 'Kakao 로그인'
    , login_with_google: 'Google 로그인'
    , login_with_apple: 'Apple 로그인'
    , join_with_kakao: 'Kakao 회원가입'
    , join_with_google: 'Google 회원가입'
    , join_with_apple: 'Apple 회원가입'
    , login_with_apple_fail: 'Apple 로그인 실패'

    , password_message_01: '비밀번호 6자리는 입력하면 자동전환됩니다.'

    , question_pin: '혹시 PIN번호를 잊으셨나요?'
    , request_pin_01: 'PIN번호를 한번 더 입력하세요'
    , request_pin_02: '보안을 위해 PIN번호를 설정하세요'
    , request_pin_03: '신규 PIN번호를 입력하세요'
    , request_pin_04: '보안 PIN번호를 입력하세요.'
    , request_pin_05: 'PIN번호 변경을 위해서 본인인증이 필요합니다.'  //{{  $language.auth.request_pin_05 }}
    , request_pin_rematch: 'PIN 번호가 맞지 않습니다. 다시 설정해주세요'
    , changed_pin: 'PIN 번호가 변경되었습니다'  //{{  $language.auth.changed_pin }}
    , complete_changed_pin: 'PIN변경 완료'  //{{  $language.auth.complete_changed_pin }}
    , security_pin: '핀 보안'

    , check_already_sign_up_account: '가입 계정 확인'

    , check_account: '계정을 확인하세요.'
    , withdrawn_account: '탈퇴된 계정입니다.'
    , block_account: '차단된 계정.'   //{{  $language.auth.block_account }}
    , stopped_account: '사용정지된 계정입니다.'
    , dormant_account: '휴면 계정입니다.'
    , withdrawn_or_dormant_account: '탈퇴 또는 휴면 계정입니다.'
    , expired_auth: '인증이 만료되었습니다.'
    , expired_auth_login: '인증이 만료되었습니다. 다시 로그인하여 주세요.'
    , no_sign_up_account: '미가입 계정입니다.'
    , no_sign_up_info: '가입 정보가 없습니다.'
    , already_sign_up_account: '현재 가입된 계정으로 확인됩니다.'
    , not_found_block_account: '차단된 멤버가 없습니다.'   //{{  $language.auth.not_found_block_account }}

    , login_fail_apple: '애플 로그인 실패. 팝업이 차단된 경우 차단해제 후 다시 이용해주세요'
    , login_fail_sns_error: '소셜인증오류'
    , google_otp: 'Google OTP'  //{{  $language.auth.google_otp }}
    , otp_auth: 'OTP 인증'  //{{  $language.auth.otp_auth }}
    , otp_auth_number: 'OTP 인증번호' //{{  $language.auth.otp_auth_number }}
    , auth_number: '인증번호'  //{{  $language.auth.auth_number }}
    , otp_input: '6자리 인증번호 입력'  //{{  $language.auth.otp_input }}
    , otp_input_01: 'OTP 인증번호를 입력하세요' //{{  $language.auth.otp_input_01 }}
    , otp_forget_01: 'OTP를 사용할 수 없으신가요?'  //{{  $language.auth.otp_forget_01 }}
    , otp_message_01: 'Google OTP 인증 앱을 스마트폰에 설치하세요.'  //{{  $language.auth.otp_message_01 }}
    , otp_message_02: 'Google Authenticator 앱에서 바코드를 스캔하세요.'  //{{  $language.auth.otp_message_02 }}
    , otp_barcode_message_01: '바코드 스캔이 불가능한 경우 아래키를 입력하세요.'  //{{  $language.auth.otp_barcode_message_01 }}
    , otp_copy_key: 'Google OTP 키가 복사되었습니다.'  //{{  $language.auth.otp_copy_key }}
    , sns_auth_require: '사용자 확인을 위해 소셜 인증을 완료해야 합니다.'  //{{  $language.auth.sns_auth_require }}
    , re_register_otp_google: 'Google OTP 재등록'  //{{  $language.auth.re_register_otp_google }}


    , sms_auth: '휴대폰 본인인증'  //{{  $language.auth.sms_auth }}
    , auth_require: '본인인증을 진행하세요'  //{{  $language.auth.auth_require }}
    , sms_number_inquire: '휴대폰번호를 입력하세요.'
    , sms_code_inquire: '휴대폰 인증번호를 입력하세요.'

    , forgot_pin_title: 'PIN 번호 분실 복구'  //{{  $language.auth.forgot_pin_title }}
    , change_pin_number: 'PIN 번호 변경'  //{{  $language.auth.change_pin_number }}

    , no_authority_lookup: '조회권한이 없습니다.'
    , no_authority_write_article: '게시물 작성 권한이 없는 게시판입니다.' //{{  $language.auth.no_authority_write_article }}


    , auto_join_if_approval: '자동 가입 승인 여부'
    , auto_join_approval: '자동 가입 승인'  //{{  $language.auth.auto_join_approval }}
    , can_all_authority: '모든 권한을 가집니다.' //{{  $language.auth.can_all_authority }}
    , all_approval_stop_authority: '회원 승인 및 사용정지 권한' //{{  $language.auth.all_approval_stop_authority }}
    , all_approval_stop: '회원 승인 및 사용정지' //{{  $language.auth.all_approval_stop }}


    , two_factor_info: '이중인증 안내' //{{  $language.auth.two_factor_info }}
    , two_factor_status_non_register: '이중인증 미등록 상태입니다' //{{  $language.auth.two_factor_status_non_register }}
    , two_factor_require_google: 'Google OTP 설정 후 이용가능합니다.' //{{  $language.auth.two_factor_require_google }}

    , access_app_info: '앱 접근 권한 안내' //{{  $language.auth.access_app_info }}
    , access_permission_essential: '필수 접근 권한' //{{  $language.auth.access_permission_essential }}
    , access_app_permission_require: '서비스 이용을 위해 아래 접근권한을 허용해 주세요' //{{  $language.auth.access_app_permission_require }}

    , access_permission_info: '허용 안내' //{{  $language.auth.access_permission_info }}

  }
  , agreement: {
    title: '이용약관',
    privacy_policy: '개인정보 이용방침',
    privacy_defence: '개인정보 보호',  //{{ $language.agreement.privacy_defence }}
    agreement_policy: '약관동의',

    agree_yes: '동의합니다.',
    agree_no: '동의하지 않습니다.',

    explanation_01: '서비스 이용동의를 위해서는 약관동의가 필요합니다.',
    explanation_02: '모두 확인, 동의 합니다.',
    explanation_03: '(필수) 서비스 이용약관',
    explanation_04: '(필수) 개인정보 수집 및 이용동의',
    dividingLine: '구분선',  //{{ $language.agreement.dividingLine }}

  },
  appLanguage: {
    title: '앱 버전',
    setUsageLanguage: '사용 언어 설정'
  },
  kakao: {
    title: '문화예술 전용 커뮤니티 마피아 프로젝트',
    auth: '카카오 인증',  //
    login: 'Kakao 로그인',  //{{ $language.kakao.login }}
  },
  apple: {
    login: 'Apple 로그인',  //{{ $language.apple.login }}
    login_failed: 'Apple 로그인 실패',  //{{ $language.apple.login_failed }}
  },

  google: {
    login: 'Google 로그인'  //{{ $language.google.login }}
  },

  withdrawal_info: {
    message_01: '마피아를 탈퇴 하시겠습니까?',  //{{ $language.withdrawal_info.message_01 }}
    message_02: '탈퇴된 마피아 계정 및 지갑 정보는 복구할 수 없으니 신중하게 선택하시길 바랍니다.',  //{{ $language.withdrawal_info.message_02 }}

    message_03: '마피아 탈퇴 전 꼭 확인해 주세요!',  //{{ $language.withdrawal_info.message_03 }}
    message_04: '탈퇴 시 카르텔 내 등록한 작성글은 자동 삭제되지 않습니다. NFT지갑, 코인지갑 내 거래 내역 등의 모든 정보가 삭제됩니다. 탈퇴된 마피아 계정으로는 로그인할 수 없으므로 마피아 서비스에 연동된 모든 지갑은 이용할 수 없게 됩니다.',  //{{ $language.withdrawal_info.message_04 }}
    message_05: '꼭! 지갑 내 NFT, 코인을 외부 거래소 또는 지갑으로 전송하세요.',  //{{ $language.withdrawal_info.message_05 }}
    message_06: '탈퇴된 마피아 계정 및 지갑 정보는 복구할 수 없으니 신중하게 선택하시길 바랍니다.',  //{{ $language.withdrawal_info.message_06 }}
    message_07: '현재 소유한 NFT, 코인이 있으나 탈퇴에 동의합니다.',  //{{ $language.withdrawal_info.message_07 }}
  },

  greetings: {
    greeting_01: 'MAFIA가 되신걸 환영합니다.',  //{{ $language.greetings.greeting_01 }}
    greeting_02: 'MAFIA는 모든 예술 분야를 아우르는 문화 마피아입니다. NFT 캐릭터 카드를 구입해 카르텔(조직)을 생성하고 가입하여 다양한 소통을 나눌 수 있습니다.',  //{{ $language.greetings.greeting_02 }}
    greeting_03: '다양한 MAFIA 컬랙션을 만나보세요.',  //{{ $language.greetings.greeting_03 }}
    greeting_04: 'NFT 카드를 구매하고 카르텔에서 소통을 해보세요.',  //{{ $language.greetings.greeting_04 }}
    greeting_05: 'MAFI NFT 구매하기',  //{{ $language.greetings.greeting_05 }}
  },

  interested: {
    title: '관심분야',
    choice_interested: '관심사 선택',  //{{ $language.interested.choice_interested }}
    select: 'MAFIA에 관심 있는 분야를 선택해주세요.',
    cartel_select: '카르텔 관심사를 선택하세요.',
    select_02: '관심분야를 선택하세요.',  //{{ $language.interested.select_02 }}
    music: '음악을 좋아하고 관심을 가지고 있습니다.', //{{ $language.interested.music }}
    art: '예술, 디자인 등에 관심을 가지고 있습니다.',  //{{ $language.interested.art }}
    fashion: '패션 디자인, 모델 등에 관심을 가지고 있습니다.',  //{{ $language.interested.fashion }}
    influence: '유튜버, 블로거 등을 운영하는 것에 관심을 가지고 있습니다.',  //{{ $language.interested.influence }}
    youtube_require: '유튜브 링크를 입력하세요',  //{{ $language.interested.youtube_require }}
  },

  nice: {
    nice_auth_title: '활동정보',   //{{  $language.nice.nice_auth_title }}
    nice_auth_title_txt: '활동정보를 입력하세요',   //{{  $language.nice.nice_auth_title }}
    nickName_message_01: 'MAFIA에서 활동할 닉네임을 설정하세요.',
    nickName_message_02: '닉네임은 설정 후 변경이 불가능합니다.',
    nickName_message_03: '닉네임과 상태메시지를 입력하세요.',
    nickName_require: '닉네임을 입력해주세요.',  //{{  $language.nice.nickName_require }}
    status_message_require: '상태메시지를 입력해주세요.',  //{{  $language.nice.status_message_require }}
  },

  grade: {
    article_delete: '등급 삭제', //{{  $language.notice.article_delete }}
    article_delete_message: '해당 등급을 삭제 하시겠습니까?',
    article_delete_message_confirm: '등급을 삭제 하시려면 확인을 눌러주세요.',
  },
  notice: {
    title: '게시판',
    write: '작성',  //{{  $language.notice.write }}
    choice: '게시판 선택', //{{  $language.notice.choice }}
    manage: '게시판 관리', //{{  $language.notice.manage }}
    create: '게시판 생성', //{{  $language.notice.create }}
    create2: '구독 게시판 생성', //{{  $language.notice.create }}
    add: '게시판 추가', //{{  $language.notice.add }}
    edit: '게시판 수정', //{{  $language.notice.edit }}
    name: '게시판 이름', //{{  $language.notice.name }}
    name_require: '게시판 이름을 입력하세요.', //{{  $language.notice.name_require }}
    set_board_authority: '게시판 권한 설정', //{{  $language.notice.set_board_authority }}
    board_authority: '게시판 권한', //{{  $language.notice.board_authority }}
    set_board_public: '게시판 공개 설정', //{{  $language.notice.set_board_public }}
    set_board_authority_message: '게시판 글 읽기 및 작성 권한을 설정합니다.', //{{  $language.notice.set_board_authority_message }}
    article: '게시물',
    article_detail: '게시물 상세',
    article_delete: '게시물 삭제', //{{  $language.notice.article_delete }}
    article_delete_authority: '게시물 삭제 권한', // {{  $language.notice.article_delete_authority }}
    article_delete_message: '해당 게시글을 삭제 하시겠습니까?',
    article_delete_message_confirm: '게시글을 삭제 하시려면 확인을 눌러주세요.',
    all_article: '전체글',
    new_article: '새 글',
    all_article_view: '전체글 보기',
    each_notice_view: '각 게시판 보기',
    wroteArticle: '내가 쓴 글',
    register_article: '글등록',  // {{  $language.notice.register_article }}
    delete_board_register: '게시판 삭제 시 등록된 글도 삭제가 됩니다. 게시판을 삭제하시겠습니까?',  // {{  $language.notice.delete_board_register }}
    delete_board_register_confirm: '삭제를 원하시면 삭제버튼을 눌러주세요.',  // {{  $language.notice.delete_board_register_confirm }}
    if_edit: '글수정시 투표등록, 수정 및 삭제가 불가능합니다',  // {{  $language.notice.if_edit }}
  },

  notify: {
    message_01: '새글 피드 알림 항목을 설정하세요.',
    message_02: '내 소식 알림 항목을 설정하세요.', //{{  $language.notify.message_02 }}
    notify_notice: '공지글 알림',  //{{  $language.notify.notify_notice }}
    notify_notice_set: '공지글 알림설정',  //{{  $language.notify.notify_notice_set }}
    notify_comment: '댓글 알림',  //{{  $language.notify.notify_comment }}
    notify_comment_set: '댓글 알림설정',  //{{  $language.notify.notify_comment_set }}
    notify_cartel_set: '카르텔 알림 설정',
    notify_cartel: '카르텔 알림',  //{{  $language.notify.notify_cartel }}
    notify_set: '알림 설정',  //{{  $language.notify.notify_set }}
  },

  emoticon: {
    best: '최고에요',
    funny: '웃겨요',
    like: '좋아요',
    sad: '슬퍼요',
    surprise: '놀랐어요',
    angry: '화나요',
  },

  cartel: {
    after_join: '카르텔 가입 후 이용해주세요',
    cover_web: '웹 커버 이미지',
    notice_grade_info: '등급 안내',
    notice_grade_info_txt: '점수는 방문수(1점) + 게시글(3점)의 합산입니다.',
    notice_grade_change: '등급 변경 안내',
    notice_grade_chagne_txt1: '',
    notice_grade_chagne_txt2: '등급이 변경되었습니다.',
    manage_cartel: '카르텔 관리',
    recommend_cartel: '추천카르텔', // {{  $language.cartel.recommend_cartel }}
    current_under_boss: '현재 운영자',
    need_appointment_under_boss: '운영자 임명 필요',
    select_under_boss_authority: '운영자 권한 선택', // {{  $language.cartel.select_under_boss_authority }}
    under_boss_authority: '운영자 권한', // {{  $language.cartel.under_boss_authority }}
    need_appointment_under_boss_02: '운영자 임명이 필요합니다',
    be_met_criteria_member_under_boss: '운영자 조건에 충족된 멤버',
    fellow_appointment_under_boss: '아래 멤버를 운영자로 지정하시겠습니까?',
    name: '카르텔 이름',  // {{  $language.cartel.name }}
    analyze_cartel: '카르텔 통계',  // {{  $language.cartel.analyze_cartel }}
    recommend_cartel_message_01: '카르텔에 가입해 보세요.',
    WeAreCartel: '카르텔입니다',
    recommendCartelBigView: '추천 카르텔 크게보기',
    NoRecommendCartel: '추천카르텔이 없습니다.',
    NoRegisteredRecommendCartel: '등록된 추천카르텔이 없습니다.',
    Not_Found_Cartel_Joined: '가입된 카르텔이 없습니다.',  //{{  $language.cartel.Not_Found_Cartel_Joined }}
    cartel_boss : '카르텔 개설자',
    cartel_member: '카르텔 회원',
    recomm_cartel: '추천 카르텔',

    name_require: '카르텔 이름을 입력하세요.',  // {{  $language.cartel.name_require }}
    explain_require: '카르텔에 대한 설명을 해주세요.',  // {{  $language.cartel.explain_require }}
    sns_require: 'SNS URL을 입력해주세요.',  // {{  $language.cartel.explain_require }}
    image_require: '카르텔 이미지를 선택하세요.',  // {{  $language.cartel.image_require }}
    interested_require: '카르텔 관심사를 선택하세요.',  // {{  $language.cartel.interested_require }}

    needApprovalAdminCartel_01: '해당 카르텔은 관리자 승인이 필요합니다.',
    needApprovalAdminCartel_02: '관리자 승인 후 카르텔 활동이 가능합니다.',
    joinAfterWithdrawalCartel: '해당 카르텔 탈퇴 시 7일 이후 재가입이 가능합니다. 작성한 글과 댓글은 자동으로 삭제되지 않습니다.',
    agreeCartel: '카르텔 탈퇴에 동의합니다.',
    completeJoinCartel: '카르텔 가입이 완료되었습니다.',
    completeCreateCartel: 'MAFIA 카르텔 생성이 완료되었습니다.',  // {{  $language.cartel.completeCreateCartel }}
    manage_under_boss: '운영자관리', // {{  $language.cartel.manage_under_boss }}
    boss_approval_need_join: '비활성화 시 개설자 또는 운영자의 승인을 통해서만 카르텔 가입이 가능합니다.', // {{  $language.cartel.boss_approval_need_join }}
    appointment_and_dismissal: '임명 및 권한, 해임',  // {{  $language.cartel.appointment_and_dismissal }}

    closerForMemberMessage_01: '해당 카르텔 폐쇄를 위해서는 멤버가 없어야 합니다.',
    closerForMemberMessage_02: '폐쇄 신청을 할 경우 24시간 뒤 폐쇄가 됩니다.',
    already_joined_member_public: '메타마피아 전체 공개',  //{{  $language.cartel.already_joined_member_public }}
    agreeCloseCartel: '카르텔 폐쇄에 동의합니다.',

    cartel_request: '카르텔 개설 요청'
    , cartel_request_modify: '카르텔 개설 정보 수정'
    , cartel_request_list: '개설 요청 목록'
    , cartel_live_request: '라이브 개설 요청'
    , cartel_live_request_modify: '라이브 개설 정보 수정'
    , cartel_live_request_list: '라이브 목록'
    , regist_cover_image: '커버 이미지 등록'
    , info_request_cover_image: '카르텔 커버를 등록하세요'
    , info_request_cover_image_size1: '가로 750px 세로 500px 이상'
    , info_request_cover_image_size2: '커버이미지 최적 사이즈는 가로 750px 이상'
    , info_request_cover_image_size3: '세로 500px입니다'
    , regist_logo_image: '로고 등록'
  },

  report: {
    CA01300001: '부적절한 홍보',
    CA01300002: '혐오 발언',
    CA01300003: '음란성 또는 부적합한 내용',
    CA01300004: '같은내용 반복',
    CA01300005: '저작권 침해',
    CA01300006: '거짓 정보 및 선동',
  },

  payment: {
    method: '결제수단', // {{  $language.payment.method }}
    nft_history: 'NFT 결제 내역' // {{  $language.payment.nft_history }}

  },

  nft: {
    withdraw_gab_fee: '출금 가스비',  // {{  $language.nft.withdraw_gab_fee }}
    nft_info: 'NFT 정보.',  // {{  $language.nft.nft_info }}
    mafia_nft_future: '마피아 NFT 선물.',  // {{  $language.nft.mafia_nft_future }}
    card_name: '카드명.',  // {{  $language.nft.card_name }}
    holder: '소유자.',  // {{  $language.nft.holder }}
    address_contract: '컨트렉트 주소.',  // {{  $language.nft.address_contract }}
    address_deposit_require: '입금 주소 입력.',  // {{  $language.nft.address_deposit_require }}
    address_withdraw_require: '출금 주소 입력.',  // {{  $language.nft.address_withdraw_require }}
    amount_withdraw_require: '출금 금액 입력.',  // {{  $language.nft.amount_withdraw_require }}
    quantity_withdraw_require: '출금 수량 입력.',  // {{  $language.nft.quantity_withdraw_require }}
    quantity_withdraw_check: '출금 수량 확인.',  // {{  $language.nft.quantity_withdraw_check }}
    quantity_withdraw_lack: '출금 수량 부족.',  // {{  $language.nft.quantity_withdraw_lack }}
    address_withdraw_require_02: '출금지갑 주소를 입력하세요.',  // {{  $language.nft.address_withdraw_require_02 }}
    nft_card: 'NFT 카드.',  // {{  $language.nft.nft_card }}
    buy_random_nftcard: '랜덤NFT 카드를 구입하세요.',  // {{  $language.nft.buy_random_nftcard }}
    buy_random_nftcard_title: 'NFT 카드 구매',  // {{  $language.nft.buy_random_nftcard_title }}
    add: '수량더하기',  // {{  $language.nft.add }}
    sub: '수량빼기',   // {{  $language.nft.sub }}
    price: '판매가',   // {{  $language.nft.price }}
    final_payment: '최종 결제',   // {{  $language.nft.final_payment }}
    wallet_require: '지갑 생성 후 이용가능합니다',   // {{  $language.nft.wallet_require }}
    not_found_wallet_address: '지갑 주소가 없습니다.',   // {{  $language.nft.not_found_wallet_address }}
    copied_wallet_address: '지갑 주소가 복사되었습니다.',   // {{  $language.nft.copied_wallet_address }}
    no_wallet: '생성된 지갑이 없습니다',   // {{  $language.nft.no_wallet }}
    wallet_check_require: '보유 코인이 부족합니다. 지갑을 확인하세요.',   // {{  $language.nft.wallet_check_require }}
    nft_info_detail: 'NFT 상세정보',   // {{  $language.nft.nft_info_detail }}
    complete_buy_nft: 'NFT 구매 완료',   // {{  $language.nft.complete_buy_nft }}
    set_maincard: '메인카드 설정',   // {{  $language.nft.set_maincard }}
    maincard: '메인카드',   // {{  $language.nft.maincard }}
    nft_list: 'NFT 목록',   // {{  $language.nft.nft_list }}
    nft_swap: 'NFT SWAP',   // {{  $language.nft.nft_swap }}
    choice_coin: '코인 선택',   // {{  $language.nft.choice_coin }}
    osolremio: '오솔레미오',   // {{  $language.nft.osolremio }}
    fee: '수수료',  // {{  $language.nft.fee }}
    payment: '결제',  // {{  $language.nft.payment }}
    create_wallet: '지갑 생성',  // {{  $language.nft.create_wallet }}
    choice_wallet: '지갑 선택',  // {{  $language.nft.choice_wallet }}
    detail_wallet: '지갑 상세',  // {{  $language.nft.detail_wallet }}
    choice_create_wallet: '생성할 지갑을 선택하세요',  // {{  $language.nft.choice_create_wallet }}
    get_card_as_random: '랜덤 카드로 다양한 등급의 카드를 획득할 수 있습니다.',  // {{  $language.nft.get_card_as_random }}
    nft_not_registered: '등록된 NFT 카드가 없습니다.',  // {{  $language.nft.nft_not_registered }}
    nft_not_taken: '보유한 NFT 카드가 없습니다.',  // {{  $language.nft.nft_not_taken }}
    utility_not_taken: '보유한 유틸리티 카드가 없습니다.',  // {{  $language.nft.utility_not_taken }}
    notify_staking_list: '스테이킹 내역 알림',  // {{  $language.nft.notify_staking_list }}
    staking_card: '스테이킹중인 카드',  // {{  $language.nft.staking_card }}
    complete_withdraw_nftcard: 'NFT카드 출금이 완료되었습니다.',  // {{  $language.nft.complete_withdraw_nftcard }}
    complete_staking: '스테이킹 처리가 완료되었습니다.',  // {{  $language.nft.complete_staking }}
    complete_clear_staking: '스테이킹 해제가 완료되었습니다.',  // {{  $language.nft.complete_clear_staking }}
    staking_question: '스테이킹을 하시겠습니까?',  // {{  $language.nft.staking_question }}
    staking_clear_question: '스테이킹을 해제 하시겠습니까?',  // {{  $language.nft.staking_clear_question }}
    mining_level: '등급에 따라 채굴되는 양이 다릅니다.',  // {{  $language.nft.mining_level }}

    nft_deposit: 'NFT 입금',  // {{  $language.nft.nft_deposit }}
    nft_withdraw: 'NFT 출금',  // {{  $language.nft.nft_withdraw }}
    nft_wallet_require: '지갑을 생성 후 NFT를 받을 수 있습니다.',  // {{  $language.nft.nft_wallet_require }}
    nft_wallet_create: '지갑 생성',  // {{  $language.nft.nft_wallet_create }}
    wallet_address_not: '지갑 생성',  // {{  $language.nft.nft_wallet_create }}
    clear_staking: '스테이킹 해제',  // {{  $language.nft.clear_staking }}
  },

  image: {
    image_delete: '이미지 삭제',
    image_delete_question: '첨부된 이미지를 삭제 하시겠습니까?',
    image_delete_confirm: '이미지를 삭제 하시려면 확인을 눌러주세요.',
    image_cash_save: '이미지 캐시, 글 작성 파일 읽기 또는 저장',   // {{  $language.image.image_cash_save }}
  },

  disclosure_message: {
    reveal_01: '공개여부를 선택해 주세요.',  // {{  $language.disclosure_message.reveal_01 }}
    reveal_02: '활동정보 비공개 시 친구들이 내 프로필에서 해당 카르텔 가입 여부를 확인 할 수 없습니다..', // {{  $language.disclosure_message.reveal_02 }}
  },

  excel: {
    agency_name: '대리점명',  // {{  $language.excel.agency_name }}
    account: '아이디',  // {{  $language.excel.account }}
    name: '이름',  // {{  $language.excel.name }}
    phone: '연락처',  // {{  $language.excel.phone }}
    regDate: '가입일',  // {{  $language.excel.regDate }}
  },

  reset: {
    account_list: '계정 목록',  // {{  this.$language.reset.account_list }}
    wallet_list: '지갑 목록',  // {{  this.$language.reset.wallet_list }}
    join_info: '가입 정보',  // {{  this.$language.reset.join_info }}
    bought_nft_list: '구매한 nft 목록',  // {{  this.$language.reset.bought_nft_list }}
    transfer_list: '입출금 내역',  // {{  this.$language.reset.transfer_list }}
    reported_comment_list: '댓글 신고 내역',  // {{  this.$language.reset.reported_comment_list }}
    blocked_list: '차단 목록',  // {{  this.$language.reset.blocked_list }}
    notice_list: '게시판 목록',  // {{  this.$language.reset.notice_list }}
    joined_cartel_list: '가입 카르텔 목록',  // {{  this.$language.reset.joined_cartel_list }}
    cartel_member_list: '카르텔 멤버 목록',  // {{  this.$language.reset.cartel_member_list }}
    friends_list: '친구 목록',  // {{  this.$language.reset.friends_list }}
    futures_list: '선물 내역',  // {{  this.$language.reset.futures_list }}
    seq_list: 'SEQ 목록',  // {{  this.$language.reset.seq_list }}
    articles_list: '게시물 목록',  // {{  this.$language.reset.articles_list }}
    cartel_list: '카르텔 목록',  // {{  this.$language.reset.cartel_list }}
  },

  terms: {
    v1_1: '제 1 조 (목적 및 정의)',
    v1_2: '주식회사 카카오(이하 ‘회사’)가 제공하는 서비스를 이용해 주셔서 감사합니다. 회사는 여러분이 회사가 제공하는 다양한 인터넷과 모바일 서비스(카카오 서비스, Daum 서비스, 멜론 서비스 등을 의미하며 이하 해당 서비스들을 모두 합하여 “통합서비스” 또는 “서비스”라 함)에 더 가깝고 편리하게 다가갈 수 있도록 ‘카카오 통합서비스약관’(이하 ‘본 약관’)을 마련하였습니다. 여러분은 본 약관에 동의함으로써 통합서비스에 가입하여 통합서비스를 이용할 수 있습니다. 단, 여러분은 회사가 아닌 계열사를 포함한 제3자가 제공하는 서비스 (예: ㈜카카오모빌리티가 제공하는 카카오 T 택시 서비스)에 가입되지는 않으며, 회사가 제공하는 유료서비스 (예: 멜론 유료서비스)의 경우 여러분이 별도의 유료이용약관에 대한 동의한 때에 회사와 여러분 간의 유료서비스 이용계약이 성립합니다. 본 약관은 여러분이 통합서비스를 이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정하고 있으므로 조금만 시간을 내서 주의 깊게 읽어주시기 바랍니다.',
    v1_3: '카카오 서비스: 회사가 제공하는 1) “카카오” 브랜드를 사용하는 서비스(예:카카오톡) 또는 2) 카카오계정으로 이용하는 서비스(Daum 및 Melon 브랜드 서비스는 제외, 예: 브런치) (단, 서비스 명칭에 ‘카카오’가 사용되더라도 회사가 아닌 카카오 계열사에서 제공하는 서비스 (예: 카카오 T택시 서비스)는 본 약관의 카카오 서비스에 포함되지 않습니다)' +
        'Daum 서비스: 회사가 제공하는 Daum(다음) 브랜드를 사용하는 서비스(예: Daum 포털 서비스)' +
        '멜론 서비스: 회사가 제공하는 Melon(멜론) 브랜드를 사용하는 서비스(예: Melon 서비스)' +
        '개별 서비스: 통합서비스를 구성하는 카카오 서비스, Daum 서비스, 멜론 서비스 등 브랜드 단위의 서비스를 각 의미함' +
        '세부 하위 서비스: 개별 서비스를 구성하는 개별 서비스 내의 세부 하위 서비스를 의미하며, 예를 들어 각 개별 서비스 내의 유료서비스, 카카오 서비스 내의 카카오톡 서비스, Daum 서비스 내의 카페, 메일 등 서비스, 멜론 서비스 내의 멜론티켓 서비스 등을 의미함',

    v2_1: '제 2 조 (목적 및 정의)',
    v2_2: '주식회사 카카오(이하 ‘회사’)가 제공하는 서비스를 이용해 주셔서 감사합니다. 회사는 여러분이 회사가 제공하는 다양한 인터넷과 모바일 서비스(카카오 서비스, Daum 서비스, 멜론 서비스 등을 의미하며 이하 해당 서비스들을 모두 합하여 “통합서비스” 또는 “서비스”라 함)에 더 가깝고 편리하게 다가갈 수 있도록 ‘카카오 통합서비스약관’(이하 ‘본 약관’)을 마련하였습니다. 여러분은 본 약관에 동의함으로써 통합서비스에 가입하여 통합서비스를 이용할 수 있습니다. 단, 여러분은 회사가 아닌 계열사를 포함한 제3자가 제공하는 서비스 (예: ㈜카카오모빌리티가 제공하는 카카오 T 택시 서비스)에 가입되지는 않으며, 회사가 제공하는 유료서비스 (예: 멜론 유료서비스)의 경우 여러분이 별도의 유료이용약관에 대한 동의한 때에 회사와 여러분 간의 유료서비스 이용계약이 성립합니다. 본 약관은 여러분이 통합서비스를 이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정하고 있으므로 조금만 시간을 내서 주의 깊게 읽어주시기 바랍니다.',
    v2_3: '카카오 서비스: 회사가 제공하는 1) “카카오” 브랜드를 사용하는 서비스(예:카카오톡) 또는 2) 카카오계정으로 이용하는 서비스(Daum 및 Melon 브랜드 서비스는 제외, 예: 브런치) (단, 서비스 명칭에 ‘카카오’가 사용되더라도 회사가 아닌 카카오 계열사에서 제공하는 서비스 (예: 카카오 T택시 서비스)는 본 약관의 카카오 서비스에 포함되지 않습니다)' +
        'Daum 서비스: 회사가 제공하는 Daum(다음) 브랜드를 사용하는 서비스(예: Daum 포털 서비스)' +
        '멜론 서비스: 회사가 제공하는 Melon(멜론) 브랜드를 사용하는 서비스(예: Melon 서비스)' +
        '개별 서비스: 통합서비스를 구성하는 카카오 서비스, Daum 서비스, 멜론 서비스 등 브랜드 단위의 서비스를 각 의미함' +
        '세부 하위 서비스: 개별 서비스를 구성하는 개별 서비스 내의 세부 하위 서비스를 의미하며, 예를 들어 각 개별 서비스 내의 유료서비스, 카카오 서비스 내의 카카오톡 서비스, Daum 서비스 내의 카페, 메일 등 서비스, 멜론 서비스 내의 멜론티켓 서비스 등을 의미함',
  },

  warning: {
    join: '이 카르텔에서 활동하는 동안 원활한 카르텔 운영을 위하여 아이디, 별명, 활동내역이 카르텔의 운영진에게 공개되며 카르텔 정책에 위반된 활동을 할 경우 제재를 받을 수 있습니다. 본 동의를 거부하실 수 있으나, 카르텔 가입이 불가능합니다.'
  },

  staking: {
    txt_reward_withdrawal: '보상 인출'
    , txt_reward_withdrawal_history: '인출 내역'
    , txt_reward_withdrawal_date: '요청일'
    , txt_reward_withdrawal_date2: '완료일'
    , txt_reward_withdrawal_error: '인출 수량을 입력하세요'
    , txt_reward_withdrawal_error2: '인출 가능수량보다 많이 인출할 수 없습니다'
    , txt_reward_withdrawal_quantity: '수량'
    , txt_reward_withdrawal_possible_quantity: '인출 가능 수량'
    , txt_reward_withdrawal_complete_quantity: '인출 수량'
    , txt_reward_withdrawal_full_quantity: '전액'
    , txt_staking_history: '스테이킹 내역'
    , txt_staking_history_date: '일시'
    , txt_staking_history_quantity: '수량'
    , txt_staking_history_none: '내역이 없습니다.'
  }
  , live: {
    live_name: 'LIVE 방송 명'
    , live_description: 'LIVE 방송 공지'
    , live_notice_required: '방송 공지를 입력하세요.'
    , live_name_required: 'LIVE 방송 명을 입력하세요.'
    , live_camera_use: 'LIVE 카메라 사용'
    , live_camera_use_info: '방송 중에 카메라를 이용할 수 있습니다.'
    , live_request_title: 'LIVE 개설 요청'
    , live_request: 'LIVE 방송 개설'
    , live_request_modify_title: 'LIVE 정보 수정 요청'
    , live_request_modify: 'LIVE 정보 수정'
    , live_streaming_setting: 'LIVE 스트리밍 설정'
    , live_exit: 'LIVE 방송 종료'
    , live_exit_txt: 'LIVE 방송을 종료하시겠습니까?'
    , live_chat_off_txt: 'LIVE 채팅을 종료하시겠습니까?'
    , live_on: '스트리밍 송출'
    , live_off: '스트리밍 종료'
    , chat_room_off: '채팅방 종료'
    , live_off_txt: 'LIVE 채팅이 종료되었습니다.'
    , live_out: 'LIVE 방송 나가기'
    , live_out_btn: '나가기'
    , live_out_txt: '참여중인 LIVE 방송을 나가시겠습니까?'
    , upload_thumbnail: '썸네일을 등록하세요'
    , upload_thumbnail_btn: '썸네일 사진 등록'
    , thumbnail_size: '가로 750px 세로 500px 이상'
    , live_ready: '준비중 입니다.'
    , send_msg: '메세지를 보내세요.'
    , live_compulsory_exit_title: '라이브 채팅 참여 멤버 강퇴'
    , live_compulsory_exit: '욕설, 혐오, 음락성 또는 부적절한 내용으로 선택 멤버를 강퇴시키겠습니까?\n강퇴 시 재입장이 불가능합니다.'
    , live_compulsory_exit_title_user: '라이브 채팅 강퇴'
    , live_network_error: '네트워크 오류가 발생했습니다. 다시 시도하세요.'
    , live_network_error_title: '네트워크 오류'
    , live_compulsory_exit_user: '욕설, 혐오, 음락성 또는 부적절한 내용으로 해당 라이브 채팅에서 강퇴 처리 당했습니다.\n궁금한 사항은 해당 카르텔에 문의하세요.'
    , live_chat_participants: '라이브 채팅 참여 멤버'
  }

  , live_chat: {
    chat_in: '채팅에 참여하였습니다.'
    , disconnect: '연결이 종료되었습니다.'
    , chat_off: '퇴장하셨습니다.'
    , modify_chat_info: '채팅방 설정이 변경되었습니다.'
    , compulsory_exit_user: '강퇴당하셨습니다.'
    , chat_out: '채팅방을 나갔습니다.'
  }

  , subscribe: {
    btn_subscribe_add: '구독 게시판'
    , btn_subscribe_normal: '일반 게시판'
    , title_subscribe_add: '구독 게시판이란?'
    , txt_subscribe_add_main: '구독 플랜을 구입한 멤버에게 특별하거나 긴밀한 콘텐츠를 전달하고 특별한 혜택을 제공함으로써 카르텔은 수익 창출을 할 수 있습니다.'
    , txt_subscribe_add_sub: ''
    , title_subscribe_input_name: '구독 게시판 이름'
    , title_subscribe_permission_admin: '운영자 권한 설정'
    , txt_subscribe_permission_admin: '운영자에게 카르텔 구독 게시판(등록, 수정, 삭제)에 대한 권한을 줍니다.'
    , title_subscribe_permission_open: '구독 게시판 공개 설정'
    , txt_subscribe_permission_open: '메타마피아 전체 공개'
    , title_subscribe_total_history: '구독 통합 내역'
    , title_subscribe_plane_management: '구독 플랜 관리'
    , title_subscribe_use: '플랜 판매'
    , txt_subscribe_complete: '구독이 완료되었습니다.'
    , title_subscribe_plane: '구독 플랜'
    , txt_subscribe_period: '구독 기간'
    , txt_is_plan_sale: '플랜 판매여부를 설정합니다'
    , title_plan_icon: '구독 플랜 아이콘'
    , title_plan_name: '구독 플랜 이름'
    , title_plan_info: '구독 플랜 설명'
    , txt_plan_info: '구독 플랜 설명을 입력하세요'
    , title_plan_price: '플랜 가격 설정'
    , txt_plan_price: '플랜 가격을 입력하세요'
    , title_plan_fee: '플랜 수수료'
    , title_plan_calculate_price: '플랜 정산 예정 금액'
    , txt_plan_calculate_price: '플랜 정산 예정 금액을 입력하세요'
    , select_payment_type: '구독 플랜 구입 시 결제 수단을 선택하세요'
    , title_plane_agree: '(필수)판매 이용약관'
    , txt_plane_agree: '정산 출금 신청 시 부가세 및 출금수수료가 추가 부가됩니다. 플랜 수수료의 경우 정책에 다라 변경될 수 있으며 약관에 의거 변경을로부터 100일전에 공지해드립니다.'
    , btn_plan_save: '플랜 저장'
    , btn_plan_confirm: '플랜 확인'
    , title_subscribe_member: '구독 멤버'
    , title_subscribe_member_finish: '구독 종료'
    , title_subscribe_plan_list: '전체 구독 플랜'
    , btn_subscribe_list: '결제내역'
    , txt_subscribe_member_management: '구독 중인 멤버를 관리하세요'
    , title_subscribe_member_management: '구독자 관리'
    , title_subscribe_plan_choice: '적용 플랜 선택'
    , txt_subscribe_plan_choice: '선택된 플랜 등급이상 구독 멤버에 한해 이글을 볼 수 있습니다'
    , title_subscribe_advanced: '고급 설정'
    , title_subscribe_load_text: '글 불러오기'
    , title_subscribe_open: '본문 공개'
    , txt_subscribe_open: '이 게시물의 본문을 숨길 경우 권한이 없는 멤버는 본문을 볼 수가 없습니다'
    , title_subscribe_open_comment: '댓글 공개'
    , txt_subscribe_open_comment: '이 게시물의 댓글을 숨길 경우 권한이 없는 멤버는 댓글을 볼 수가 없습니다'
  }
  , cartel_new: {
    title_support: '후원'
    , title_support_history: '후원내역'
    , title_support_history_detail: '후원 상세 내역'
    , title_subscribe: '구독하기'
    , title_plane_management: '플랜관리'
    , title_subscribe_management: '구독관리'
    , title_subscribe_permission: '열람권한 확인'
    , txt_subscribe_permission_main: '열람권한이 없습니다.'
    , txt_subscribe_permission_sub: '해당 게시판의 열람권한을 위해 구독 플랜을 구입하세요.'
    , title_plan_choice: '구독 플랜 선택'
    , title_subscribe_history: '구독 내역'
    , btn_subscribe: '구독'
    , title_finish: '종료'
    , error_not_subscribe_plan: '이용중인 구독 플랜이 없습니다.'
    , txt_subscribe_history_ok: '구독 내역이 있습니다'
    , txt_subscribe_history_no: '구독 내역이 없습니다'
    , title_cartel_subscribe: '카르텔 구독'
    , title_subscribe_plan_info: '구독 플랜 설명'
    , select_payment: '결제수단을 선택하세요'
    , error_not_payment: '결제수단이 없습니다'
    , title_plane_now: '현재 구독 플랜'
    , etc_end: '까지'
    , title_not_subscribe_plan: '구독 플랜 없음'
    , title_plane_amount: '구독 플랜 금액'
    , title_plane_amount_upgrade: '업그레이드 금액'
    , title_plane_vat: '부가세'
    , title_plane_total_price: '총 결제 금액'
    , title_notice: '유의사항'
    , txt_notice1: '구독 업그레이드 상품의 경우 남은 구독 기간은 자동 취소되며 환불금을 제외한 차액을 결제합니다'
    , txt_notice2: '본 상품은 “서비스 이용약관"에 따른 구독 플랜 결제로 동의약관이 적용됩니다'
    , txt_notice3: '구독 상품 특성 상 구입 철회가 불가능한 상품입니다'
    , txt_notice4: '상기 구독 플랜 상품 유의사항에 동의합니다.(필수)'
    , txt_plan1: '다양한 구독 플랜을 구성하세요'
    , title_calculate: '구독 정산'
    , txt_calculate: '구독 정산을 받으세요.'
    , txt_finish_plan: '일 후 종료'
  }
  , point: {
    title_my_point: '보유 중인 불렛'
    , title_my_point_available: '사용가능한 불렛'
    , title_my_point_support: '후원받은 불렛'
    , title_my_quantity: '보유 수량'
    , txt_heart: '불렛'
    , txt_purchase: '구매하기'
    , error_not_point_purchase: '구매가능한 포인트 정보가 없습니다.'
    , error_not_credit: '등록된 신용카드가 없습니다.'
    , title_heart_payment: '불렛 구매'
    , txt_heart_payment_ask: '원)를 구매하시겠습니까?'
    , btn_charge: '충전'
    , btn_list: '내역'
  }
  , point_result: {
    title_result: '불렛 충전 완료'
    , title_payment_type: '결제 수단'
    , none_payment_type: '등록된 결제수단이 없습니다.'
    , btn_confirm: '확인'
    , title_payment_amount: '결제 금액'
    , title_payment_date: '결제 일시'
    , title_payment_number: '승인 번호'
    , title_payment_detail: '결제 상세내역'
    , title_information: '정보'
    , title_subscribe_product: '구독 상품'
    , title_payment_information: '결제 정보'
    , title_tid: '거래 번호'
    , title_payment_type_add: '결제 수단을 추가하세요'
    , btn_modify: '플랜 및 판매 수정 '
    , title_payment_quntity: '결제 수량'
    , btn_add_list: '적립 내역'
    , btn_use_list: '사용 내역'
    , title_div: '구분'
    , title_detail: '상세'
    , title_date: '일시'
    , title_payment_status: '결제 상태'
    , title_product: '상품'
    , title_credit: '카드'
    , title_payment_div: '거래 구분'
    , txt_not_enough_point: '불렛이 부족합니다'
  }

  , add: {
    title_sponsor: '후원자'
    , title_support_message: '응원 메시지'
    , title_remove_file: '첨부파일 삭제'
    , txt_remove_file_main: '해당 첨부파일을 삭제하시겠습니까?'
    , txt_remove_file_sub: '삭제된 파일은 복구되지 않습니다.'
    , txt_add_subscribe: '구독 게시판을 먼저 생성해주세요'
    , swap: 'NFT SWAP'
    , title_swap: '윤지성 NFT'
    , title_swap_notice: '유의사항'
    , txt_swap_notice1: '· 스왑한 NFT는 특성상 취소가 불가능합니다'
    , txt_swap_notice2: '　따라서 신중히 확인한 뒤 스왑해주시기 바랍니다'
    , txt_swap_notice3: '· 스왑한 NFT는 랜덤으로 지급처리됩니다'
    , txt_swap_notice4: '상기 상품 유의사항에 동의합니다.(필수)'
    , title_swap_result: 'SWAP 완료'
    , txt_swap_result: 'NFT 컬렉션에서 스왑하신 NFT CARD를 확인하실 수 있습니다'
    , txt_not_plan: '등록된 플랜정보가 없습니다.'
  }
  , launchpad: {
    title_launchpad: '런치패드'
    , select_payment: '결제 수단 선택'
    , select_payment_input: '결제수단을 선택하세요'
    , select_card: '신용카드 선택'
    , select_card_input: '결제카드를 선택하세요'
    , select_quantity: '수량 선택'
    , last_payment_quantity: '최종 결제 수량'
    , last_payment_price: '최종 결제 금액'
    , payment_quantity: '결제 수량'
    , payment_price: '결제 금액'
    , vat: '부가세'
    , basic_discount: '기본 할인'
    , purchase_guide_title: '구매 안내 및 권리 / 유의 사항'
    , purchase_guide_1: 'NFT 신용카드 구매 시 부가세가 발생합니다.'
    , purchase_guide_2: '구매가 완료된 NFT의 경우 환불이 불가능합니다.'
    , purchase_guide_3: 'NFT는 정해진 시간에만 구입 가능하며 이후에는 해당 카르텔을 통해 구입이 가능합니다.'
    , purchase_guide_4: '구매한 NFT는 컬렉션에서 확인이 가능합니다.'
    , visit_cartel: '카르텔 방문하기'
    , currently: '현재'
    , gae: '개'
    , have_been_sold: ' 판매되었습니다.'
    , txt_purchase: '구매하기'
    , txt_register: '등록'
    , txt_coming_soon: '예정'
    , txt_minting_ended: '종료되었습니다'
    , minting_ended: '판매종료'
    , view: '참여하기'
    , selling_price: '판매가'
    , txt_nft_result: 'NFT 컬랙션에서 구입하신 MAFIA CARD를 확인하실 수 있습니다'
  }

  , voting: {
    title_voting_board: 'VOTING 게시판 '
    , title_voting_board_add: '보팅 게시판 추가'
    , title_voting_board_manage: 'VOTING 게시판 관리'
    , title_voting_board_delete: 'VOTING 게시판 삭제'
    , txt_voting_board_delete: 'VOTING 게시판 삭제 시 등록된 글도 삭제가 됩니다.'
    , txt_voting_board_delete2: '게시판을 삭제하시겠습니까?'
    , txt_voting_board_delete3: '삭제를 원하시면 삭제버튼을 눌러주세요'
    , title_voting_board_title: 'VOTING 게시판 이름'
    , title_voting_board_manager_permission: '운영자 권한 설정'
    , txt_voting_board_manager_permission: '운영자에게 카르텔 보팅등록, 수정 권한을 줍니다'
    , title_voting_board_open: '보팅 게시판 공개 설정'
    , txt_voting_board_open: '카르텔에 가인됩 회원에게만 게시판을 공개합니다'
    , title_voting_alram: 'VOTING 알림'
    , txt_voting_alram_ing: '신규, 참여한 보팅 종료 알림'
    , title_voting_cancel: '신규 보팅 취소'
    , txt_voting_cancel: '신규 보팅 등록을 취소 하시겠습니까?'
    , txt_voting_cancel2: '취소를 원하시면 확인버튼을 눌러주세요'
    , title_voting_title: '보팅 제목'
    , txt_voting_title: '제목을 입력하세요'
    , title_voting_contents: '보팅 내용'
    , txt_voting_contents: '내용을 입력하세요'
    , title_voting_period: '보팅 기간'
    , title_voting_period_start: '시작일'
    , title_voting_period_ed: '종료일'
    , title_voting_permission: 'VOTING 참여 권한'
    , txt_voting_permission: '보팅에 참여할 수 있는 회원을 설정합니다'
    , txt_voting_permission2: '기본은 전체로 설정됩니다'
    , title_voting_multiple: '복수 선택'
    , txt_voting_multiple: '보팅시 복수선택이 가능합니다'
    , title_voting_comment_able: '댓글 작성'
    , txt_voting_comment_able: '보팅에 댓글이 작성 가능합니다'
    , title_voting_anonymous: '익명 투표'
    , txt_voting_anonymous: 'VOTING 참여자를 노출하지 않습니다.'
    , title_voting_rate: 'VOTING 참여율 표기'
    , txt_voting_rate1: '투표수료 표기'
    , txt_voting_rate2: '투표율로 표기'
    , txt_voting_rate3: '투표율 숨김'
    , title_voting_reword: 'VOTING 참여 보상'
    , txt_voting_reword: '보팅 참여자에 한해 보상을 제공합니다'
    , title_voting_list: 'VOTING 항목'
    , txt_voting_list_empty: '보팅 항목이 없습니다'
    , txt_voting_list_empty2: '보팅 항목은 2개이상 등록하세요'
    , title_voting_add: '보팅 항목 추가'
    , txt_voting_add: '보팅 항목 입력'
    , title_voting_add_contents: '상세 내용'
    , txt_voting_add_contents: '보팅 항목의 상세내용 및 동영상등을 첨부할 수 있습니다.'
    , title_voting_add_file: '첨부파일 상세'
    , txt_voting_add_file: '첨부파일은 하나만 등록가능합니다'
    , btn_voting_add_file_img: '사진 첨부'
    , btn_voting_add_link: '동영상 링크'
    , title_voting_add_link: '동영상 URL 입력'
    , txt_voting_add_link: '동영상 URL을 입력하세요'
    , txt_voting_add_file_preview: '미리보기'
    , title_voting_item_contents: 'VOTING 항목 설명'
    , txt_voting_item_contents: 'VOTING 항목 설명을 입력하세요'
    , title_voting_social_url: '소셜 URL'
    , txt_voting_social_url: 'URL을 입력하세요'
    , txt_voting_social_max: '소셜URL은 최대 10개까지만 등록가능합니다'
    , title_voting_social_select: '소셜 아이콘 선택'
    , error_voting_add_file: '첨부파일을 입력하세요'
    , error_voting_add_contents: '설명을 입력하세요'
    , title_voting_item_modify: 'VOTING 항목 수정'
    , sub_voting_item_modify: '보팅 항목 수정'
    , title_voting_item_delete: 'VOTING 항목 삭제'
    , sub_voting_item_delete: '보팅 항목 삭제'
    , txt_voting_item_delete: '보팅 항목을 삭제 하시겠습니까'
    , title_voting_item_add: 'VOTING 항목 등록'
    , txt_voting_item_add: 'VOTING을 등록하시겠습니까'
    , txt_voting_item_add2: '등록된 보팅은 수정시 제약을 받습니다'
    , txt_voting_item_add3: '확인 후 등록하세요'
    , txt_voting_period: '보팅이 종료되었습니다'
    , txt_voting_period2: 'VOTING 기간은 현재시간 이후로 설정하세요'
    , title_voting: 'VOTING'
    , title_voting_new: '신규 VOTING'
    , title_voting_new_setting: '신규 VOTING 설정'
    , txt_voting_new: '메인 커버사진을 등록하세요'
    , txt_voting_new2: '커버 사진은 VOTING 목록에서 노출됩니다'
    , title_voting_modify: 'VOTING 수정'
    , title_voting_finish: 'VOTING 종료'
    , sub_voting_finish: '보팅 종료'
    , title_voting_delete: 'VOTING 삭제'
    , sub_voting_delete: '보팅 삭제'
    , txt_voting_delete: '해당 보팅을 삭제하시겠습니까?'
    , txt_voting_delete1: '보팅 기간이 남았습니다'
    , txt_voting_delete2: '보팅을 종료하시겠습니까?'
    , tab_voting_status1: '진행중'
    , tab_voting_status2: '예약'
    , tab_voting_status3: '종료'
    , tab_voting_status4: '임시저장'
    , title_voting_hidden: 'VOTING 숨김'
    , txt_voting_hidden1: '투표를 멤버에게 숨김'
    , txt_voting_hidden2: '투표를 멤버에게 노출'
    , title_voting_count: '보팅 참여자'
    , txt_voting_order1: '시간순'
    , txt_voting_order2: '닉네임순'
    , title_voting_temp: '임시저장'
    , txt_voting_temp1: '임시저장된 VOTING 불러오기'
    , txt_voting_temp2: '임시저장된 VOTING 삭제'
    , title_voting_temp_delete: '임시저장 VOTING 삭제'
    , txt_voting_temp_delete: '임시저장된 VOTING을 삭제하시겠습니까?'
    , txt_voting_unable: 'VOTING에 참여할 수 없는 등급입니다'
    , notify_voting_complete: 'VOTING참여가 완료되었습니다'
    , notify_voting_already: '이미 참여한 투표입니다'
    , tab_voting_now: '진행중'
    , tab_voting_end: '종료'
    , txt_voting_info: '보팅 결과는 '
    , txt_voting_info2: '까지 게시됩니다'
    , txt_voting_cover_image: '보팅 메인 커버사진을 추가하세요'
    , txt_voting_cover_image2: '커버사진은 목록에서 노출됩니다'
    , title_vote_complete: '투표 완료'
    , title_vote_doing: 'VOTE'
    , title_voting_detail_contents: '상세보기'
    , title_voting_detail_members: '참여자'
  }

  , shorts: {
    title_shorts: '숏플'
    , title_shorts_item: '숏플 동영상 업로드'
    , txt_item_info: '1분 이내의 동영상을 업로드 하고 게시하세요'
    , btn_upload: '동영상 업로드'
    , title_shorts_contents: '숏플 내용'
    , error_shorts_contents: '숏플 내용을 입력하세요'
    , txt_shorts_success: '숏플이 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
    , txt_shorts_success2: '동영상이 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
    , txt_shorts_contents: '140자 내로 내용을 입력하세요'
    , title_shorts_cover: '커버수정'
    , title_shorts_option: '고급설정'
    , title_my_shorts: '등록 숏플'
    , title_my_shorts_detail: '등록 숏플 상세'
    , title_my_cartel: '가입 카르텔'
    , subject_ing: '게시중'
    , subject_fail: '게시 실패'
    , subject_shorts_search: '숏플 검색'
    , txt_not_list: '등록된 숏플이 없습니다.'
  }

  , tv:{
    title_tv: '마피아 TV'
    , title_tv_manage: '마피아 TV 관리'
    , subject_tv_list: '등록 마피아 TV'
    , txt_tv_list: '등록된 영상을 관리합니다'
    , subject_tv_permission: '마피아TV 권한 설정'
    , txt_tv_permission: '운영자 작성 권한 및 공개 여부를 설정합니다'
    , txt_tv_list_search: '마피아TV검색'
    , txt_tv_list_count: '등록된 '
    , txt_tv_list_movie: '동영상 '
    , title_tv_item: '마피아 TV 동영상 업로드'
    , subject_tv_upload: '동영상을 업로드하고 게시하세요'
    , btn_upload: '동영상 업로드'
    , title_tv_title: '마피아 TV 제목'
    , txt_tv_title: '제목을 입력하세요'
    , title_tv_contents: '마피아 TV 내용'
    , txt_tv_contents: '내용을 입력하세요'
    , txt_tv_success: '영상을 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
    , txt_tv_success2: '동영상이 업로드 중입니다. 용량 및 길이에 따라 몇 분이 소요될 수 있습니다'
    , title_tv_edit: '마피아 TV 수정'
    , title_tv_search: '마피아 TV 검색'
    , btn_series: '시리즈'
    , btn_alone: '단편'
    , title_tv_series: '마피아 TV 시리즈'
    , txt_tv_series_title: '시리즈 제목'
    , txt_tv_series_contents: '시리즈 내용'
    , title_tv_series_detail: '시리즈 상세'
    , title_tv_episode: '에피소드'
    , title_tv_episode_sort: '순서변경'
    , txt_tv_upload: '마피아TV에 어떤 동영상을 올리고 싶으신가요?'
    , title_tv_upload_alone: '한편의 영상만 올린다면'
    , txt_tv_upload_alone: '단편 동영상'
    , title_tv_series_delete: '시리즈 삭제'
    , txt_tv_upload_series: '여러개의 스토리 동영상을 올린다면'
    , title_tv_upload_series: '시리즈 동영상'
    , txt_tv_upload_series_select: '시리즈를 선택하세요'
    , txt_tv_series_delete: '선택한 시리즈를 삭제 하겠습니까?'
    , txt_tv_series_delete_sub: '삭제 시 포함된 동영상도 삭제 됩니다.'
    , txt_tv_upload_info: '신규 시리즈를 추가하게 되면 시리즈 별로 동영상을 올릴 수 있습니다. 시리즈로 등록된 영상은 마피아TV 내 시리즈, 단편 두 곳에서 확인이 가능합니다.'
    , title_tv_series_upload: '시리즈 영상 업로드'
    , txt_tv_series_upload: '추가하신 시리즈에 영상을 업로드 할 준비가 완료 되었습니다. 영상을 업로드 하시겠습니까?'
    , btn_tv_series_after: '나중에'
    , btn_tv_series: '업로드'
  }

  , calculate: {
    title_calculate: '카르텔 정산'
    ,
  }

  , home: {
    txt_after_login: '가입 카르텔에서 새로운 소식을 확인하세요'
  }

  , v2: {
    txt_recomm_cartel1: '메타마피아에서'
    , txt_recomm_cartel2: '추천카르텔을 만나보세요.'
  }


}