<template>
	<div class="header" :class="program.bg">
		<div class="top top_mafi f-left">
			<div class="util">
				<button class="arrow_white" @click=goBack>
					<i class="icon icon-arrow-left"></i>
				</button>
			</div>
			<h2 class="page_tit">{{ program.title }}</h2>
		</div>
		<div
			v-if="program.is_next"
			class="save"
		>
			<button
				class="btn_save"
				@click="$emit('callBack')"
			>{{  $language.common.next }}</button>
		</div>
	</div>
</template>


<script>
export default {
	name: 'HeaderCartel'
	, props: ['program']
	, data: function(){
		return {

		}
	}
	,methods: {
		goBack: function(){
			if(this.program.from){
				this.$bus.$emit('to', this.program.from)
			}else if(this.program.callBack) {
				this.$bus.$emit('callBack', this.program.callBack)
			}else{
				this.$router.back()
			}
		}
	}
}
</script>