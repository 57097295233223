<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="logo_top">

					<div class="text_guide size-px-20 color-white font-weight-bold">
						{{ $language.v2.txt_recomm_cartel1 }} <!-- 메타 마피아에서 -->
					</div>
					<div class="text_guide size-px-20 color-white font-weight-bold">
						{{ $language.v2.txt_recomm_cartel2 }} <!-- 추천 카르텔을 만나보세요 -->
					</div>
				</div>
				<ul
					class="mt-30  justify-start gap-10 flex-wrap">
					<li
						v-for="(item, index) in items"
						:key="'item_' + index"
						class="flex-1 position-relative"
						@click="setCartel(item, index)"
					>
						<div class="square-64 overflow-hidden radius-10">
							<img :src="item.cartl_img_url" class="object-cover" @error="$bus.$emit('onErrorImage', $event)"/>
						</div>
						<div class="mt-10 color-white">{{ item.cartl_name}}</div>

						<div v-show="item.is_check" class="checked square-64 z-index-1">
							<div class="position-absolute-fulll bg-layer"></div>
							<v-icon large class="color-white">mdi-check</v-icon>
						</div>
					</li>
				</ul>
				<div class="intro_btn_wrap mt-30 pb-30">
					<div class="btn_area">
						<button class="btn_l btn_fill_blue" @click="toNext">{{ $language.common.next }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Cartel'
		, props: ['user']
		, data: function(){
			return {
				items: []
				, item_cartel: []
				, item_search: {
					page_number: 1
					, pagerecnum: 10
				}
			}
		}
		, methods: {
			getData: async function(){

				try{

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_open_cartel_recomm_list
						, data: {
							page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items = result.data.recom_cartl_list
						this.$set(this.items[0], 'is_check', true)
						this.item_cartel.push(this.items[0])
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, toNext: async  function(){
				this.$emit('click', { type: 'pin', params: { entry_cartl_number: this.item_cartel }})
			}
			, setCartel: function(item, index){

				if(index == 0){
					this.$set(this.items[index], 'is_check', true)
				}else{
					this.$set(this.items[index], 'is_check', !this.items[index].is_check)
				}

				console.log(this.items[index].is_check)

			}
		}
		, created() {
			this.getData()
		}
	}
</script>

<style>
	.checked {
		position: absolute; left: 0; top: 0;
		width: 100%; height: 100%;
		z-index: 1;
		display: flex; justify-content: center; align-items: center;
		border: 3px solid var(--blue02);
		border-radius: 10px;
	}
</style>