<template>

	<popup_confirm
		v-if="is_on_chang_info"
		:not_cancel="true"

		@click="clear"
		@cancel="clear"
	>
		<template
			v-slot:title
		>{{ $language.cartel.notice_grade_change }}</template>
		<template
			v-slot:main-txt
		><span class="color-red size-px-18 font-weight-bold">{{ item_change_info.chg_cartl_member_grade_name }}</span>
		</template>
		<template
			v-slot:sub-txt
		>{{ $language.cartel.notice_grade_chagne_txt1 }}  <br/> {{ $language.cartel.notice_grade_chagne_txt2 }}
		</template>
	</popup_confirm>
</template>

<script>
	import popup_confirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'cartel_member_grade_monitor'
		,
		components: {popup_confirm},
		props: ['user']
		, data: function(){
			return {
				item_change_info: {
					chg_fg: ''
				}
				, is_on_chang_info: false
			}
		}
		, methods: {
			getData: async function(){
				if(this.$route.fullPath.indexOf('/cartel/') > -1 && this.$route.params.idx){
					let d = sessionStorage.getItem('cartl_member_grade_chg_info')
					if(d){
						d = JSON.parse(d)
						this.item_change_info = d
						this.is_on_chang_info = true
					}
				}
			}
			, clear: function(){
				this.is_on_chang_info = false
				this.item_change_info = {
					chg_fg: ''
				}
				sessionStorage.removeItem('cartl_member_grade_chg_info')
			}
		}
		, created() {
			this.getData()
		}
		, watch: {
			$route(){
				this.getData()
			}
		}
	}
</script>

<style>

</style>