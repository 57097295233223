
// import axios from 'axios'
export const common = {

    isMobile: function(){
        let user = navigator.userAgent;
        let w_type = 'web'
        w_type = 'CM00500002'
        if(user.toLowerCase().indexOf('iphone') > -1 || user.toLowerCase().indexOf('ipad') > -1 || user.toLowerCase().indexOf('ipod') > -1 || user.toLowerCase().indexOf('android') > -1){
            w_type = 'mobile'
            w_type = 'CM00500003'
        }

        return w_type
    }
    , isApp: function(){
        let user = navigator.userAgent;
        if(window.flutter_inappwebview || user.toLowerCase().indexOf('inapp') > -1){
            return true
        }else{
            return false
        }
    }
    , getMobile: function(){
        let user = navigator.userAgent;
        let w_type = 'web'
        w_type = 'CM00500002'
        if(user.toLowerCase().indexOf('iphone') > -1 || user.toLowerCase().indexOf('ipad') > -1 || user.toLowerCase().indexOf('ipod') > -1){
            w_type = 'ios'
        }else if(user.toLowerCase().indexOf('android') > -1){
            w_type = 'android'
        }

        return w_type
    }
    , getOsCode: function(){
        let t = ''
        try{
            this.$common.inAppWebviewCommunity('android', 'getOsCode')
            if(this.getMobile() == 'Fandroid'){
                t = 'AP00100001'
            }else{
                t = 'AP00100002'
            }
        }catch (e){
            t = ''
        }
        return t
    }
    , getPaymentCode: function(){
        let t = 'PM00300001'
        try{

            this.$common.inAppWebviewCommunity('android', 'getPaymentCode')

            if(this.getMobile() == 'android') {
                t = 'PM00300004'
            }else{
                t = 'PM00300001'
            }
        }catch(e){
            t = 'PM00300001'
        }

        return t
    }
    , getIp: async function(){
        return '1'
        /*
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            if(response){
                return response.data.ip
            }else{
                throw 'error'
            }
        } catch (error) {
            console.error(error);
            return ''
        }
        */
    }

    , getBrowser: async function(){
        let agent = window.navigator.userAgent.toLowerCase()

        let browser;

        switch (true){
            case agent.indexOf('edge') > -1:
                browser = 'MS Edge'
                break;

            case agent.indexOf('edg/') > -1:
                browser = 'Chrome Edge '
                break;

            case agent.indexOf('opr') > -1:
                browser = 'Opera'
                break;

            case agent.indexOf('chrome') > -1:
                browser = 'Chrome'
                break;

            case agent.indexOf('trident') > -1:
                browser = 'MS IE'
                break;

            case agent.indexOf('firefox') > -1:
                browser = 'Firefox'
                break;

            case agent.indexOf('safari') > -1:
                browser = 'Safari'
                break;
            default:
                browser = 'Other'
                break;
        }

        return browser
    }

    , inAppWebviewCommunity: function(callEvent, params){
        if(this.isApp()){
            console.log('inAppWebviewCommunity', callEvent, params)
            window.flutter_inappwebview.callHandler(callEvent, params)
        }else{
            window.android(callEvent, params)
        }
    }
}