
<template>
	<div
		class="notify-box"
		:class="notify ? 'on' : 'off'"
		:style="bottom"

		@click="clear"
	>
		<div
			class="notify-bg"
			:class="type"
			style=""
		></div>
		<div
			class="notify-content"
			style=""
		>
			<div
				class="notify-msg"
				style=""
				@click="clear"
			>
				{{ msg }}
				<v-alert
					v-if="false"
					:type="type ? type : 'error'"
					dense
				>

				</v-alert>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default{
		name: 'AlertMsg'
		,props: []
		,data: () => ({
			notify: false
			,msg: '!!'
			,type: ''
			,config: {

			}
		})
		,watch: {
			msg: {
				handler: function(call){
					if(call){
						this.notifyMsg()
						this.notify = true
					}
				}
			}
		}
		, computed: {
			bottom: function(){
				let t = 'bottom: 0'

				if(this.config){
					t = 'bottom: ' + this.config.bottom
				}
				return t
			}
		}
		,methods: {
			clear: function(){
				this.notify = false
				this.msg = ''
				this.$emit('clearMsg')
			}
			, notifyMsg: function(){
  
				let timer = this.notifyMsg.timer
				if (timer) {
					clearTimeout(timer)
				}
				
				this.notifyMsg.timer = setTimeout(() => {
					this.clear()
				}, 4000)

				this.elapse = 1 
				let t = this.notifyMsg.t
				if (t) {
					clearInterval(t)
				}

				this.notifyMsg.t = setInterval(() => {
					if (this.elapse === 3) {
						this.elapse = 0
						clearInterval(this.notifyMsg.t)
					}
					else {
						this.elapse++
					}
				})
			}
		}

		,created() {

			this.$bus.$on('notify', ({ type, message, config }) => {
				// console.log('config', config)
				this.msg = message
				this.type = type
				this.config = config

				try{
					this.$common.inAppWebviewCommunity('android', message)
				}catch(e){
					// console.log(e)
				}
			})
		}
	}
</script>


<style>

.slide-fade-enter-active {
	transition: all .3s ease;
}
.slide-fade-leave-active {
	transition: all .0s ease;
}
.slide-fade-enter{

	background-color: white !important;
	overflow: hidden;
}
.slide-fade-leave, .slide-fade-leave-to {
	transform: translateY(100%) ;
	opacity: 0;
	overflow: hidden;
}

	.notify-box { position: fixed; min-height: 80px; width: 100%; right: 0; bottom: 0; z-index: 9999999; }
	.notify-box.on {transform: translateY(0%); transition: transform .5s ease; z-index: 9999999}
	.notify-box.off {transform: translateY(100%); transition: transform 0.5s ease;}
	.notify-bg { position: absolute; width: 100%; height: 100%; opacity: 0.8 }
	.notify-content { position: relative; min-height: 80px; z-index: 9999998; padding: 10px; font-size: 14px; display: flex; flex-direction: column; justify-content: center; }
	.notify-msg {  padding: 10px; border-radius: 10px; color: #eee }

	.notify-bg.success { background-color: black; color: #eee !important;}
	.notify-bg.error { background-color: #fc4545; }
</style>