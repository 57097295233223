<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf004">
						<div class="intro_top character2">
							<div class="text_guide_desc pt-20">
								<p>{{ $language.agreement.explanation_01 }}</p>
							</div>
						</div>
						<div class="intro_bottom">
							<h4 class="hide">{{ $language.agreement.agreement_policy }}</h4>
							<div class="confirm_info pt-30">
								<div
									class="dark_checks"
									@click="doAll"
								>
									<input
										v-model="is_all"
										type="checkbox" id="all"
										readonly
									>
									<label >{{ $language.agreement.explanation_02 }}.</label>
								</div>
								<div class="intro_line"><span class="hide">{{ $language.agreement.dividingLine }}</span></div>
								<ul class="agree_list">
									<li>
										<div class="dark_checks checks_arrow">
											<input
												v-model="is_terms"
												type="checkbox" id="agree1"
											>
											<label @click="to('terms')">{{ $language.agreement.explanation_03 }}</label>
											<button type="button" class="btn" @click="to('terms')"><i class="icon-arrow-right"></i></button>
										</div>
									</li>
									<li>
										<div class="dark_checks checks_arrow">
											<input
												v-model="is_personal"
												type="checkbox" id="agree2"
											>
											<label @click="to('personal')">{{ $language.agreement.explanation_04 }}</label>
											<button type="button" class="btn" @click="to('personal')"><i class="icon-arrow-right"></i></button>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<!-- TODO : 동의 체크 완료후 class : disable 없애기 -->
							<button
								class="btn_l btn_fill_blue"
								:class="{ disable: !is_agree}"

								@click="toJoin"
							>{{ $language.common.next }}</button>
						</div>
						<p class="copyright">Copyright © MAFI Inc. </p>
					</div>
				</div>
			</div>
		</div>

		<mafia0041
			v-if="is_0041"
			:type="type"
			class="position-fixed-full bg-white"

			@cancel="is_0041 = false"
			@click="setAuth"
		></mafia0041>
	</div>
</template>

<script>
import mafia0041 from '@/view/Auth/mafia004-1'
export default {
	name: 'Agree'
	, props: ['agree_type']
	, components: { mafia0041 }
	,data: function(){
		return {
			program: {
				name: this.$language.common.signUp + this.$language.agreement.agreement_policy
				, not_header: true
				, not_footer: true
			}
			, is_all: false
			, is_terms: false
			, is_personal: false
			, is_0041: false
			, type: 'terms'
			, terms: ''
			, personal: ''
		}
	}
	, computed: {
		is_agree: function(){
			let is_agree = false

			if(this.is_terms && this.is_personal){
				is_agree = true
			}
			return is_agree
		}
		, terms_version: function(){
			let t = 'v1'
			if(process.env.VUE_APP_VERSION_TERMS != t){
				t = process.env.VUE_APP_VERSION_TERMS
			}

			return t
		}
		, personal_version: function(){
			let t = 'v1'
			if(process.env.VUE_APP_VERSION_PERSONAL != t){
				t = process.env.VUE_APP_VERSION_VUE_APP_VERSION_PERSONAL
			}

			return t
		}
	}
	,methods: {
		getData: async function(){
			try {

				const result = await this.$Request({
					method: 'POST'
					, url: this.$api_url.api_path.get_agreement
					, data: {
					}
					, type: true
				})
				console.log('result.success', result.data)
				if (result.success) {

					this.terms = result.data.stip_list[1]
					this.personal = result.data.stip_list[0]
					console.log('success', this.terms, this.personal)
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
			}
		}
		, to: function(type){

			if(type == 'terms'){
				if(this.is_terms){
					this.is_terms = false
					return
				}
			}else if(type == 'personal'){
				if(this.is_personal){
					this.is_personal = false
					return
				}
			}
			this.is_0041 = true
			this.type = type
			/*
			if(type == 'terms'){
				if(this.terms.stip_offer_code == 'CO00200002'){
					window.open(this.terms.stip_file_url, 'terms')
				}else{
					this.is_0041 = true
					this.type = type
				}
			}else{
				if(this.personal.stip_offer_code == 'CO00200002'){
					window.open(this.personal.stip_file_url, 'personal')
				}else{
					this.is_0041 = true
					this.type = type
				}
			}
			 */
		}
		, doAll: function(){
			if(!this.is_terms){
				this.to('terms')
			}else if(!this.is_personal){
				this.to('personal')
			}else{
				this.is_terms = false
				this.is_personal = false
			}
		}
		, toJoin: function(){
			if(!this.is_terms || !this.is_personal) {
				this.$bus.$emit('notify', {type: 'error', message: this.$language.agreement.explanation_01})
			}else if(this.agree_type == 'agree2'){

				let join_info = localStorage.getItem('join_info')
				if(!join_info){
					join_info = {
					}
				}else{
					join_info = JSON.parse(join_info)
				}

				if(!join_info.terms_version){
					this.to('terms')
				}else if(!join_info.personal_version){
					this.to('personal')
				}else{
					let params = {
						stip_list: [this.terms, this.personal]
					}

					this.$emit('click', { type: 'profile', step: 2, params: params})
				}
			}else{

				let join_info = localStorage.getItem('join_info')
				if(!join_info){
					join_info = {
					}
				}else{
					join_info = JSON.parse(join_info)
				}

				if(!join_info.terms_version){
					this.to('terms')
				}else if(!join_info.personal_version){
					this.to('personal')
				}else{
					let params = {
						stip_list: [this.terms, this.personal]
					}

					this.$emit('click', { type: 'join', step: 2, params: params})
				}
			}
		}
		, setAuth: function(type, data){
			if(type == 'terms'){
				this.is_terms = true
				this.terms = data
			}
			if(type == 'personal'){
				this.is_personal = true
				this.personal = data
			}
			this.is_0041 = false
		}
		, postDeviceToken: async function(token){
			try {

				const result = await this.$Request({
					method: 'POST'
					, url: this.$api_url.api_path.post_device_token
					, data: token
					, type: true
				})
				if (result.success) {

					this.item_app_info = result.data

					for(let [k, v] of Object.entries(this.item_app_info)){
						//alert(k + ':' + v)
						console.log(k, v)
					}
					try{
						this.$common.inAppWebviewCommunity('android', this.item_app_info.app_execu_code)

						if(this.item_app_info.app_execu_code && this.item_app_info.app_execu_code != 'AP00500001'){
							this.is_version = true
						}else{
							throw ''
						}
					}catch (e){
						this.certfc = result.data
						await this.$encodeStorage.setSessionCertfc(this.certfc)
						await this.$encodeStorage.setSessionVersion(token.app_version)
					}
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				await this.$router.push({ name: 'mafia009', params: { type: 'third', msg: e}})
			}
		}
	}
	,created() {
		console.log('v2 auth agree')
		localStorage.setItem('join_info', JSON.stringify({}))
		if(this.agree_type != 'agree2'){
			this.$bus.$emit('getDeviceToken')
		}

	}
	,watch: {
		is_terms: {
			handler: function(call){
				if(!call){
					this.is_all = false
				}else{
					if(this.is_personal){
						this.is_all = true
					}
				}
			}
		}
		, is_personal: {
			handler: function(call){
				if(!call){
					this.is_all = false
				}else{
					if(this.is_terms){
						this.is_all = true
					}
				}
			}
		}
	}
}
</script>