<template>
	<div class="color-white text-center full-height">

		<div class="container full-height">
			<div class="row  overflow-y-auto full-height pb-50" style="padding-bottom: 170px !important;" >

				<div class="mt-50 none_top character2"></div>
				<div class="mt-25 size-px-18">
					회원가입을 환영합니다. :)
					<br/>
					나만의 <span class="text-under-line-cartel">카르텔</span>을 만들까요?
				</div>

				<div class="mt-40">
					<div class="pa-20">
						<div class=" size-px-14 ">
							<button
								class="flex-1 square-100 color-white bg-blue-mafia radius-left-10" :class="is_cartel ? '' : 'opacity-02'"
								@click="is_cartel = true"
							>

								<img :src="require('@/assets/image/intro_character_2.png')" class="width-50"/>
								<div class="mt-10">카르텔 생성</div>
							</button>
							<button
								class="flex-1 square-100   color-white bg-blue-mafia radius-right-10" :class="is_cartel ? 'opacity-02' : ''"
								@click="is_cartel = false"
							>
								<img :src="require('@/assets/image/intro_character_3.png')" class="width-50"/>
								<div class="mt-10">다음에</div>
							</button>
						</div>
					</div>

					<div class="size-px-12 line-height-160">
						카르텔은 소통을 위한 커뮤니티를 칭하는 명칭입니다.
						나만의 커뮤니티를 만들어 회원들을 초대하고 소통해보세요.
						<div v-if="is_cartel" class="font-weight-700 "><span class="bg-blue"> 로그인 시 카르텔이 자동으로 생성됩니다.</span></div>
						<div v-else  class="font-weight-700"><span class="bg-red">카르텔 생성은 원하실때 언제나 생성이 가능합니다.</span></div>
					</div>
				</div>

				<div class="intro_btn_wrap size-px-12 bg-dark" style="margin: 0 !important; padding: 1rem 2rem; bottom: 0; padding-bottom: 3rem !important;">
					로그인 후 메타마피아를 이용하세요
					<div class="mt-20 btn_area">
						<button class="btn_l btn_fill_blue" @click="toNext">{{ $language.common.login }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Result'
		, props: ['member_number']
		, data: function(){
			return{
				is_cartel: true
			}
		}
		, methods: {

			toNext: async  function(){
				if(this.is_cartel){
					try{
						this.$bus.$emit('on', true)
						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.post_member_cartel
							, data: {
								member_number: this.member_number
							}
							, type: true
						})

						if(!result.success) {
							throw result.message
						}
					}catch (e) {
						console.log(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.$bus.$emit('on', false)
						this.$emit('click')
					}
				}else{
					this.$emit('click')
				}
			}
		}
	}
</script>

<style>
	.opacity-02 { opacity: 0.6}
	.radius-left-10 { border-radius: 10px 0 0 10px}
	.radius-right-10 { border-radius: 0 10px 10px 0}

	.text-under-line-cartel {  text-decoration: underline var(--blue02) }
	.bg-red { background-color: #ff3214}
</style>